import { TabHeader } from "components/TabHeader";
import React, { useEffect, useState } from "react";
import  tw, { styled, css } from 'twin.macro';
import { navigate } from "@reach/router";
import { Form, Formik } from "formik";
import { Flex, Spinner } from "@chakra-ui/react";
import { FieldForm } from "components/RFQ/Input/Input";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { FileUpload } from "components/RFQ/FileUpload";
import { sectorFilter } from "helpers/Enum/FilterProjectList";
import { Location } from "components/FormInputs/Location";
import { useDispatch } from "react-redux";
import { getProjectById, storeNewProject, updateProject } from "actions/RFQ/projectsAction";
import { toFormData } from "axios";
import { Submit } from "components/FormInputs/Submit";
import { useSelector } from "react-redux";
import * as yup from 'yup';
import { BackButton } from "components/RFQ/BackButton";
import TextButton from "components/RFQ/Button/TextButton";

const validationSchema = yup.object().shape({
  name: yup.string().required('This field is required'),
  sector: yup.array().required('This field is required'),
  project_lead: yup.string().required('This field is required'),
  description: yup.string().required('This field is required'),
  address: yup.object().shape({
    value: yup.string().required('This field is required').nullable(),
  }),
  logo_path: yup
    .mixed()
    .test('is-string-or-file', 'Must be a file', value => {
      return typeof value === 'string' || value?.[0] instanceof File;
    })
    .required('This field is required'),
  background_image_path: yup
    .mixed()
    .test('is-string-or-file', 'Must be a file', value => {
      return typeof value === 'string' || value?.[0] instanceof File;
    })
    .required('This field is required'),
})

const NewProjectContainer = styled.div(() => [
  tw`relative overflow-auto`,
  css` 
    .buttons-container {
      gap: 8px;
    }
    .container-content {
      padding-right: 24px;
      padding-left: 24px;
    }
  `
])

const ProjectForm = ({projectId,newProject,editProject,newProjectFromSupplierList,supplierListId}) => {
  const isLoading = useSelector((state) => state.rfq.projects.loading)
  const dispatch = useDispatch();
  const [project, setProject] = useState(useSelector((state) => state.rfq?.projects?.projects?.allProject?.data)?.find((project) => project.id === Number(projectId)) || null)
  const [loadProject, setLoadProject] = useState(false);

  const handleBackButtonClick = () => {
    newProject ? navigate('./') : (
      window.history.length > 1 ? window.history.back() : navigate('../')
    )
  }


  useEffect(() => {
    if(projectId && !newProject && !project){
      setLoadProject(true)
      getProjectById(projectId).then((res) => {
        setProject(res)
        setLoadProject(false)
      })
      .catch((err) => { setLoadProject(false) })
    }
  },[projectId,newProject,project])

  const initialValues = {
    name: '',
    sector: [],
    project_lead: '',
    description:'',
    address:{latitude: '', longitude: '', value: ''},
    location:'',
    location_latitude:'',
    location_longitude:'',
    logo_path:'',
    background_image_path: '',
  }

  const initialValuesProject = {
    name: project?.name || '',
    sector: project?.sector?.map((item) => item.id) || [],
    project_lead: project?.projectLead || '',
    description: project?.description || '',
    address:{latitude: project?.locationLatitude, longitude: project?.locationLongitude, value: project?.location} || {value:''},
    location:project?.location || '',
    location_latitude:project?.locationLatitude || '',
    location_longitude:project?.locationLongitude || '',
    logo_path:project?.logoPath || '',
    background_image_path: project?.backgroundImagePath || '' ,
  }

  const handleSubmit = async (values, {setSubmitting}) => {    
    const data = {
      isSubmitted: 0,
      name: values.name || null ,
      description: values.description || null,
      project_lead: values.project_lead || null,
      location: values.address?.value || null,
      location_latitude: values.address?.latitude || null,
      location_longitude: values.address?.longitude || null,
      state: values.state || null,
      sector: values.sector || [],
      logo_path: typeof values.logo_path === 'string' ? null : values.logo_path.length > 0 ? values.logo_path[0] : null,
      background_image_path: typeof values.background_image_path === 'string' ? null : values.background_image_path.length > 0 ? values.background_image_path[0] : null,
      supplier_list_ids: [supplierListId] || []
    };

    const formData = toFormData(data,undefined,{indexes: true});
    try {
      if(newProject || newProjectFromSupplierList) dispatch(storeNewProject(formData,newProjectFromSupplierList));
      if(editProject)dispatch(updateProject(projectId,formData));
    }catch (err) {
      console.log(err)
    }finally {
      setSubmitting(false);
    }
}


  return (
    <NewProjectContainer>
      <TabHeader
        icon={<BackButton handleBackButtonClick={handleBackButtonClick}/>}
        heading={newProject || newProjectFromSupplierList ? "New Project" : `Edit Project ${project?.name}`}
        isLoading={loadProject}
      >
      </TabHeader>
      <div className="container-content h-full relative min-h-[60vh]">
        {!loadProject ? <Formik
          initialValues={newProject ? initialValues : initialValuesProject}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setValues, resetForm, setErrors,setStatus,setFieldError,isValid}) => {

          return (
          <Form className="space-y-8">
            <Flex gap={8}>
              <FieldForm
                label='Project Name'
                placeholder="Add Project Name"
                required={true}
                name='name'
              />
              <FieldForm
                label='Sector (Add one or more)'
                placeholder="Add sectors"
                name='sector'
                type="select"
                required={true}
                isMultiple={true}
                options={sectorFilter}
                variant="basic-multi-select"
                isSearchable={true}
              />
            </Flex>
            <Flex gap={8} className="items-baseline">
                <Location
                  label="Project Location"
                  name="address"
                  required={true}
                  errorBottom={"-3px"}
                  rfq
                  className='relative'
                />
                
              <FieldForm
                label='Project Lead'
                required={true}
                placeholder="Enter full name"
                name='project_lead'
              />
            </Flex>
            <Flex>    
            <FormatedInput
                  label="Project Description"
                  name="description"
                  required={true}
                  characterLimit={1500}
                  rfq
                  className='w-full'
                  placeholder='Enter a description...'
                  setErrors={setErrors}
                  setFieldError={setFieldError}
                />
            </Flex>
            <Flex justifyContent='space-between' gap={8}>
                <FileUpload
                  label={'Client Logo'}
                  description={'We recommend uploading your client logo image with dimensions of 300x300 pixels'}
                  name='logo_path'
                  types={['jpg','png']}
                  maxFileSize={10}
                  required={true}
                  defaultFileName='Client Logo'
                  value={values.logo_path || ""}
                />
                <FileUpload
                  label={'Project Image'}
                  description={'We recommend using a project image with dimensions of 730x400 pixels'}
                  name='background_image_path'
                  types={['jpg','png']}
                  maxFileSize={10}
                  defaultFileName='Project Image'
                  required={true}
                  value={values.background_image_path || ""}
                />
            </Flex>
            <Flex justifyContent='space-between' className='py-12'>
              <TextButton onClick={() => {resetForm(); newProject ? navigate('./') : navigate('..')}} className="cursor-pointer">
                Cancel
              </TextButton>
            <div className="flex buttons-container" >
              <Submit
                style={{ height: "50px", textTransform:'capitalize' }}
                type='submit'
                isSubmitting={isLoading}
                spinner
                text={newProject ? "Add Project" : "Save Changes"}
                submittingText="Saving..."
                disabled={isLoading || !isValid}
              >

              </Submit>
              
              </div>
            </Flex>
          </Form>
        )}
        }     

        </Formik>
        :
        <Spinner className="absolute left-1/2 -translate-x-1/2 top-[30vh] " size='xl' />
      }
      </div>
    </NewProjectContainer>
  );
};

export default ProjectForm;
