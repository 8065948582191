import { Box, Flex } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import {
    getRFQId,
    getRFQType,
    getDocumentType
  } from "actions/RFQ/rfqsAction";
import PreviewRFQComponent from "components/PreviewRFQComponent/PreviewRFQComponent";
import { ReactComponent as BackIcon } from "assets/icons/flip-backward.svg";
import { Button } from "components/RFQ/Button";
import { TabHeader } from "components/TabHeader";
import { useEffect, useState } from "react";
import { mapFromRFQDetailToRFQData } from "utils/mapper";
import { SkeletonBase } from "components/Skeleton";

const BackButton = ({ isNew }) => {
  return (
    <Button
      btntype={"icon"}
      onClick={() => navigate("..")}
      className={isNew && "!pointer-events-none"}
    >
      <BackIcon
          style={{ width: "25px", height: "25px", marginRight: "0px" }}
        />
    </Button>
  );
};

const ViewRFQPage = ({rfqId}) =>{
    const [rfqData, setRFQData] = useState(null);
    const [rfqDetails, setRfqDetails] = useState(null);
    const [documentTypes, setDocumentType] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
      setIsLoading(true)
      const res = async () =>{
        return await Promise.all([getRFQId(rfqId),getRFQType(),getDocumentType()])
      }

     res().then((res)=>{
      setRFQData(res[0])
      setRfqDetails(mapFromRFQDetailToRFQData(res[0],res[1]))
      setDocumentType(res[2].map((doc) => ({ label: doc.name, value: doc.id })))
      setIsLoading(false)
     }).catch((_)=>setIsLoading(false))
    },[rfqId])

      
    return<>
     <TabHeader
          icon={<BackButton />}
          heading={rfqData?.name || 'RFx'}
        >
      </TabHeader>
    {isLoading?<>
      <Box className='min-h-80 w-full border rounded-md shadow-xl grid content-between px-6 py-10'>
      <Flex className="text-base flex-col gap-4 font-semibold">
        <SkeletonBase width="280px" />
        <SkeletonBase width="500px" />
        <SkeletonBase width="500px" />
        <SkeletonBase width="500px" />
      </Flex>
      
      </Box>
      <div>
      <Flex className="text-base flex-row gap-4 font-semibold py-8">
        <SkeletonBase />
        <SkeletonBase />
        <SkeletonBase />
        <SkeletonBase />
      </Flex>
      </div>
      <Box className='min-h-80 w-full border rounded-md shadow-xl grid content-between px-6 py-10'>
      <Flex className="text-base flex-col gap-4 font-semibold">
        <Flex gap={4}>
          <SkeletonBase />
          <SkeletonBase width="280px" />
        </Flex>
        <Flex gap={4}>
          <SkeletonBase />
          <SkeletonBase width="280px" />
        </Flex>
        <Flex gap={4}>
          <SkeletonBase />
          <SkeletonBase width="280px" />
        </Flex>
        <Flex gap={4}>
          <SkeletonBase />
          <SkeletonBase width="280px" />
        </Flex>
        <Flex gap={4}>
          <SkeletonBase />
          <SkeletonBase width="280px" />
        </Flex>
      </Flex>
      </Box>
    </>:
    <>
     
      <PreviewRFQComponent rfqData={rfqData} rfqDetail={rfqDetails} documentCategories={documentTypes}/>
    </>
    }
    </>
}

export default ViewRFQPage;