import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerFooter
} from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import React, { useState } from "react";
import tw, { styled, css } from "twin.macro";
import { Tags } from "components/Tags";
import { Form, Formik } from "formik";
import AccordionSectionRFQ from "./AccordionSectionRFQ";
import { useSelector } from "react-redux";
import { Button } from "components/RFQ/Button";

const FormikStyled = styled(Formik)(() => [tw`overflow-y-auto`]);

const DrawerHeaderStyled = styled(DrawerHeader)(() => [
  tw`text-black py-6 border-b`,
  css`
      .close-text {
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
      }
  
      .header-container {
        display: flex;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
      }
  
      .clear-container {
        display: flex;
        justify-items: center;
        place-items: center;
      }
    `,
]);

const AppliedFilters = styled.div(() => [
  tw`grid`,
  css`
      .title-filter {
        font-size: 14px;
        font-weight: 600;
        color: black;
        margin-bottom: 16px;
      }
      .chip-container {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }
    `,
]);

const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-0`]);

const FilterRFQ = ({ onClose, isOpen, setFilters, prevValues, setPrevValues, currentTab, ...props }) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const filterOptions = useSelector((state) => state.rfq.rfqs?.filterOptions?.[currentTab]) || [];

  const initialValues = {
    location: prevValues?.location || [],
    status: prevValues?.status || [],
    sector: prevValues?.sector || [],
  };

  const locationFilter = filterOptions.length > 0 ? filterOptions?.find((filter) => filter.name === "location")?.item : [];
  const projectStatus = filterOptions.length > 0 ? filterOptions?.find((filter) => filter.name === "status")?.item : [];
  const sectorFilter = filterOptions.length > 0 ? filterOptions?.find((filter) => filter.name === "sector")?.item : [];

  const handleSubmit = async (filters, actions) => {
    if (isSubmiting) {
      setFilters(filters);
      setIsSubmiting(false);
      setPrevValues(filters);
      onClose(true)
    } else {
      alert(JSON.stringify(filters))
      actions.resetForm()
    }
  };

  return (
    <div>
      <Drawer
        placement={"right"}
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        blockScrollOnMount={false}
        {...props}
      >
        <DrawerOverlay onClick={onClose} />
        <FormikStyled
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, setValues, resetForm }) => {
            return (
              <Form >
                <DrawerContent >
                  <DrawerHeaderStyled borderBottomWidth="1px">
                    <div className="header-container">
                      <p className="font-roboto text-base font-bold">Filter</p>
                      <div className="clear-container">
                        <Button btntype="plain" type={"button"} className="close-text font-roboto font-medium"
                          style={{ fontSize: '14px' }}
                          onClick={() => setValues({ location: [], status: [], sector: [] })}>
                          Clear all
                        </Button>
                        <Button btntype="plain-icon" type={"button"} onClick={onClose}>
                          <Icon icon={"close"} />
                        </Button>
                      </div>
                    </div>
                  </DrawerHeaderStyled>
                  <DrawerHeaderStyled>
                    <AppliedFilters>
                      <p className="title-filter">Applied filters</p>
                      <div className="chip-container">
                        {values?.location && locationFilter.length > 0 &&
                          locationFilter
                            .filter((data) =>
                              values?.location.includes(data.value)
                            )
                            .map((filteredData) => {
                              const handleDeleteLocation = (deletedValue) => {
                                setValues(prevValues => ({
                                  ...prevValues,
                                  location: prevValues.location.filter(newVal => newVal !== deletedValue)
                                }))
                              }
                              return (
                                <Tags
                                  key={filteredData.value}
                                  deletable
                                  handleDelete={() => handleDeleteLocation(filteredData.value)
                                  }
                                >
                                  {filteredData.label}
                                </Tags>
                              );
                            })}
                        {values?.status && projectStatus.length > 0 &&
                          projectStatus
                            .filter((data) =>
                              values?.status.includes(data.value)
                            )
                            .map((filteredData) => {
                              const handleDeleteStatuses = (deletedValue) => {
                                setValues(prevValues => ({
                                  ...prevValues,
                                  status: prevValues.status.filter(newVal => newVal !== deletedValue)
                                }))
                              }
                              return (
                                <Tags
                                  key={filteredData.value}
                                  deletable
                                  handleDelete={() => handleDeleteStatuses(filteredData.value)
                                  }
                                >
                                  {filteredData.label}
                                </Tags>
                              );
                            })}
                        {values?.sector && sectorFilter.length > 0 &&
                          sectorFilter
                            .filter((data) =>
                              values?.sector.includes(data.value?.toString())
                            )
                            .map((filteredData) => {
                              const handleDeleteSector = (deletedValue) => {
                                setValues(prevValues => ({
                                  ...prevValues,
                                  sector: prevValues.sector.filter(newVal => newVal !== deletedValue)
                                }))
                              }
                              return (
                                <Tags
                                  key={filteredData.value}
                                  deletable
                                  handleDelete={() => handleDeleteSector(filteredData.value?.toString())
                                  }
                                >
                                  {filteredData.label}
                                </Tags>
                              );
                            })}
                      </div>
                    </AppliedFilters>
                  </DrawerHeaderStyled>
                  <DrawerBodyStyled>
                    <AccordionSectionRFQ setIsSubmiting={setIsSubmiting} filterOptions={filterOptions} />
                  </DrawerBodyStyled>
                  <DrawerFooter>
                    <Button
                      size="full"
                      type="submit"
                      onClick={() => setIsSubmiting(true)}
                    >
                      Apply
                    </Button>
                  </DrawerFooter>
                </DrawerContent>
              </Form>
            )
          }}
        </FormikStyled>
      </Drawer>
    </div>
  );
};

export default FilterRFQ;
