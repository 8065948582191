import { navigate } from "@reach/router";
import { useLocation } from "@reach/router";
import apiV2 from "api-v2";
import { Icon } from "assets/icons/Icon";
import { DropdownItem, DropdownMenu, DropdownMenuDrawer, DropdownMenuTrigger, MenuDivider } from "components/RFQ/MenuDropdown";
import { CustomModal } from "components/RFQ/Modal/CustomModal";
import React, { useState } from "react";
import  { Button } from "components/RFQ/Button";
import { showToast } from "utils/helpers";

const EditDropdown = ({ rfqData, currentTab, noBg, refreshData, isEditable, isSubmissionDateExtendable, onExtendDateClick, slug }) => {
  const location = useLocation();
  const [isModalOpen,setIsModalOpen]=useState(false)
  const [isLoading,setIsLoading]=useState(false)
  const redirectChecker = location.pathname.includes('request-for-quote/') ? `edit-rfx/${rfqData.id}` : `request-for-quote/edit-rfx/${rfqData.id}`;
  const viewRedirectChecker = location.pathname.includes('request-for-quote/') ? `${rfqData?.id}/${slug}` : `request-for-quote/${rfqData?.id}/${slug}`;
  const isArchived = "archived"

  const dialogProperty = {
    icon: `${currentTab !== isArchived ? "archive" : "blue-circle-check"}`,
    bgColor: "bg-brand-secondary",
    iconColor: "text-utility-brand-700",
    title: `${currentTab !== isArchived ? "Archive" : "Retrieve"} ${rfqData?.name} ?`,
    description: `Are you sure to ${currentTab !== isArchived ? "Archive" : "Retrieve"} ${rfqData?.name}?`
  };

  const FooterComponent = () =>{
    return (
      <div className="flex justify-between gap-2 w-full">
        <Button
        btntype="plain-border"
        onClick={()=>setIsModalOpen(false)}
        className="!w-full"
        >
        Cancel
        </Button>
        <Button
        className="flex !w-full gap-3"
        onClick={actionFunc}
        disabled={isLoading}
        >
        {isLoading?currentTab !== isArchived ? "Archiving" : "Retrieving":'Yes'}
        </Button>
      </div>
      );
    }

  const actionFunc = () => {

    let action = ""
    if (currentTab === isArchived) {
      action = "unarchived"
    } else {
      action = "archived"
    }
    if (action !== "") {
      setIsLoading(true)
      apiV2.post(`rfq/${rfqData.id}/${action}`).then((res)=>{
        if (res.status === 200) {
          setIsModalOpen(false)
          showToast(res?.data?.message, res?.data?.title, true)
          setIsLoading(false)
          refreshData()
        }
      }
    ).catch((err)=>{setIsModalOpen(false)
      showToast(err?.response?.data?.message, 'Error')
      setIsLoading(false)})
    }
  }

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger icon noBg={noBg} mutedIcon></DropdownMenuTrigger>
        <DropdownMenuDrawer>
          {isEditable ? <> 
            <DropdownItem className="w-full" onClick={() => navigate(redirectChecker)}>
              <Icon icon='edit-icon' />
              <p className="font-roboto font-medium text-text-secondary-700" style={{fontSize:'14px'}}>Edit RFx</p>
            </DropdownItem>
            <MenuDivider />
            </>:<>
            <DropdownItem className="w-full" onClick={() => navigate(viewRedirectChecker)}>
              <Icon icon='eye-on' />
              <p className="font-roboto font-medium text-text-secondary-700" style={{fontSize:'14px'}}>View RFx</p>
            </DropdownItem>
            {isSubmissionDateExtendable?<DropdownItem className="w-full" onClick={() => onExtendDateClick(rfqData)}>
              <Icon icon='calendar' />
              <p className="font-roboto font-medium text-text-secondary-700" style={{fontSize:'14px'}}>Extend Submission Deadline</p>
            </DropdownItem>:<></>}
            <MenuDivider />
            </>}
          <DropdownItem onClick={() => setIsModalOpen(true)}>
            <Icon icon='archive' />
            <p className="font-roboto font-medium text-text-secondary-700" style={{fontSize:'14px'}}>{currentTab === isArchived ? 'Unarchive' : 'Archive'}</p>
          </DropdownItem>
        </DropdownMenuDrawer>
      </DropdownMenu>
      <CustomModal 
        header={dialogProperty}
        isOpen={isModalOpen}
        onClose={()=>setIsModalOpen(false)}
        footerComponent={<FooterComponent/>}
        />
    </div>
  );
};

export default EditDropdown;
