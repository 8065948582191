import React from "react";
import { Box } from "@chakra-ui/react";
import {
  renderQuestion,
  SectionComponent,
} from "components/RFQ/QuestionComponent";
import { fieldTypeQuestion } from "helpers/Enum/FieldTypeList";
import { useSelector } from "react-redux";

const ReturnableSchedulesComp = ({ questionnaire, touchedForm, isGuest }) => {
  const { documents, resources, pastProjects } = useSelector(
    (state) => state?.companyProfile
  );
  let hasDocuments = documents?.length > 0;
  let hasResources = resources?.length > 0;
  let hasPasProjects = pastProjects?.length > 0;

  const isSmartField = (type) =>
    type === "project_experience" ||
    type === "key_personnel" ||
    type === "corporate_documents";

  return (
    <div className="space-y-10">
      {questionnaire?.map((section, sectionID) => {
        return (
          <Box
            className="block min-h-80 w-full border rounded-xl shadow-xl p-10 pt-4 space-y-8"
            key={sectionID}
          >
            <SectionComponent
              sectionTitle={section?.name || " - "}
              description={section?.description}
              rfqSubmission={true}
            />
            {section.question?.map((question, index) => {
              let namefields = `question_answer.question-${question.id}`;
              if (
                isSmartField(question?.fields?.type) &&
                ((!hasDocuments &&
                  question?.fields?.type === "corporate_documents") ||
                  (!hasResources &&
                    question?.fields?.type === "key_personnel") ||
                  (!hasPasProjects &&
                    question?.fields?.type === "project_experience")) &&
                !isGuest
              ) {
                namefields = namefields + ".additional";
              }
              const questionProps = {
                type: fieldTypeQuestion[question.type].type,
                name: namefields,
                question: question.name,
                typeQuestion: fieldTypeQuestion[question.type].type,
                fieldValue: question?.fields?.option || [],
                description: fieldTypeQuestion[question.type].description,
                rfqSubmission: true,
                required: question.required,
                touchedField: touchedForm,
                isGuest: isGuest,
              };
              return renderQuestion(
                questionProps,
                index,
                hasDocuments,
                hasResources,
                hasPasProjects
              );
            })}
          </Box>
        );
      })}
    </div>
  );
};

export default ReturnableSchedulesComp;
