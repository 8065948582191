
import { Icon } from 'assets/icons/Icon'
import React from 'react'

const PreviewOnlyComp = ({ icon="eye", submissionStatus,label="Preview only", isRFxClosed }) => {
    return <div className='m-auto'>
        <div className='flex bg-utility-blue-light-50 border border-[#B0CEFF] border-solid rounded-full p-2 text-utility-blue-dark-100 font-medium text-center items-center justify-center' style={{ width: 'fit-content', fontSize: '14px' }}>
            <div className='flex bg-white pl-3 pr-3 border border-[#B0CEFF] border-solid rounded-full items-center justify-center pt-1 pb-1'>
                <div><Icon icon={icon} /></div>
                <div className='ml-2'>{label}</div>
            </div>
            {(!isRFxClosed && submissionStatus?.toLowerCase() !== "submitted") && <>
                <div className='ml-2 pr-2' style={{ marginTop: '-2px' }}>
                    Tap "Accept" to agree to the terms and get started
                </div>
                <div className='pr-2'><Icon icon="arrow-right" /></div>
            </>}
        </div>
    </div>
}

export default PreviewOnlyComp