import React, { useCallback, useEffect, useRef, useState } from "react";
import { navigate } from "@reach/router";
import { Flex, Spinner } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import TabsComp from "components/RFQ/TabsComp/TabsComp";
import JumbotronComp from "./JumbotronComp";
import { Button } from "components/RFQ/Button";
import DocumentationComp from "./DocumentationComp";
import PreviewOnlyComp from "components/RFQ/PreviewOnlyComp/PreviewOnlyComp";

import AddendumsComp from "./AddendumsComp";
import {
  downloadAllDocumentation,
  getSubmission,
  saveSubmissionRFQ,
} from "actions/RFQ/Supplier/rfqActions";
import ReturnableSchedulesComp from "./ReturnableSchedulesComp";
import {
  STATUS_ACCEPTED,
  STATUS_DECLINE,
  STATUS_IN_PROGRESS,
  STATUS_INVITED,
} from "utils/constants";
import { AcceptDeclineModal } from "./AcceptDeclineModal";
import { Input } from "components/RFQ/Input";
import { getDocumentType, getUnitMeasures } from "actions/RFQ/rfqsAction";
import { SkeletonBase } from "components/Skeleton";
import YourQuoteComp from "pages/RFQSubmission/RFQPreview/YourQuoteComp";
import {
  initialQuoteValue,
  initialReturnableValue,
  setAllFieldTouched,
  valuesPayload,
} from "./RFQUtils/RFQSubmissionUtils";
import { Form, Formik } from "formik";
import { SubmitModal } from "./SubmitModal";
import {
  downloadAllDocumentationGuest,
  getSubmissionGuest,
  saveSubmissionGuestRFQ,
} from "actions/guestflow/guestRfqActions";
import { getAddendumServices } from "services/supplier-flow/adendumServices";
import { getAddendumServices as getAddendumServicesGuest } from "services/guest-flow/addendumServices";
import { useSelector } from "react-redux";
import { NotFound } from "routes/NotFound";
import ButtonActions from "./ActionButtons";
import { allValidationSchema } from "./RFQUtils/ValidationSchemas";
import { isEmptyObj, isRFxClosed } from "utils/helpers";
import { getInitialErrors} from "./RFQUtils/RFQHelper";
import FormErrorComp from "./FormErrorComp";
import QuoteFile from "./QuoteFile";

const RFQPreview = ({ id, isGuest, currentTab }) => {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [listQuotes, setListQuotes] = useState([]);
  const [listReturnable, setListReturnable] = useState([]);
  const formRef = useRef(null);
  const [savedReturnableValue, setSavedReturnableValue] = useState(null);

  const [initialFormValue, setInitialFormValue] = useState(null);
  const [savedQuoteFile, setSavedQuoteFile] = useState(null);
  const [validForm, setValidForm] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isQuoteTypeFile, setIsQuoteTypeFile] = useState(false);
  const isInvited = submissionStatus === STATUS_INVITED;
  const isAccepted =
    submissionStatus === STATUS_ACCEPTED ||
    submissionStatus === STATUS_IN_PROGRESS;
  const isDecliend = submissionStatus === STATUS_DECLINE;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [unitMeasures, setUnitMeasures] = useState(null);
  const [documentCategories, setDocumentCategories] = useState([]);
  const [documentFilter, setDocumentFilter] = useState(null);
  const guestEmail = useSelector((state) => state.auth.guestUser.email) || null;
  const {
    documents,
    resources,
    pastProjects,
    isRequesting,
    requestingResources,
    loadingPastProjects,
  } = useSelector((state) => state?.companyProfile);
  const isLoadedCompanyProfile = isGuest ? true
    : !isRequesting && !requestingResources && !loadingPastProjects;

  const isStatusProgressOrAccepted = submissionStatus?.toUpperCase() === "INPROGRESS" || submissionStatus?.toUpperCase() === "ACCEPTED"

  const company = useSelector((state)=> state.auth?.current_company)

  let hasDocuments = documents?.length > 0;
  let hasResources = resources?.length > 0;
  let hasPasProjects = pastProjects?.length > 0;
  const companyId = localStorage.getItem("company_id");
  const rfqId = id;
  const [rfqCompanyId, setRFQCompanyId] = useState("");
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(null);
  const [data, setData] = useState();
  const [addendumData, setAddendumData] = useState([]);
  const [totalAddendum, setTotalAddendum] = useState(0);
  const [isNotFound, setIsNotFound] = useState(false);
  const [errors, setErrors] = useState(null);
  const tabHeaderRef = useRef(null)

  // tab contents are filtered based on tab names
  const [tabMenu, setTabMenu] = useState([
    {
      id: "documentation",
      name: "Documentation",
      showBadge: true,
      total: 0,
      isValid: true,
    },
    {
      id: "returnable-schedules",
      name: "Returnable Schedules",
      showBadge: true,
      total: 0,
      isValid: false,
    },
    {
      id: "quote",
      name: "Quote",
      showBadge: true,
      total: 0,
      isValid: false,
    },
    {
      id: "addendum",
      name: "Addendums",
      showBadge: true,
      total: 0,
      isValid: true,
    },
  ]);

  useEffect(()=>{
    tabHeaderRef?.current?.scrollIntoView();
  },[currentTab])

 

  const addendumService = () => {
    
    return isGuest ?[ getAddendumServicesGuest(rfqId, guestEmail),getSubmissionGuest(rfqId, guestEmail)]:
    [ getAddendumServices(rfqId), getSubmission(rfqId, companyId)]
  }

  useEffect(()=>{
    
    setIsLoading(true)
    const responses = Promise.all(addendumService())
      responses.then((res)=>{
        const addendumServiceRes = res[0]
        const submissionRes = res[1]
        if(!submissionRes){
          setIsNotFound(true);
        }

      setRfqStateDetailAndAddendum(submissionRes,addendumServiceRes?.data)

      getDocumentType().then((res) => {
        if (res) {
          setDocumentCategories([
            { value: null, label: "All Categories" },
            ...res.map((category) => ({
              value: category?.id,
              label: category?.name,
            })),
          ]);
        }
      });
      getUnitMeasures().then((res) => {
        if (res) {
          setUnitMeasures(
            res.map((unit) => ({ value: unit?.id, label: unit?.name }))
          );
        }
      });
      setIsLoading(false)
      }).catch((e)=>{
        setIsNotFound(true);
        setIsLoading(false)
      })
      //eslint-disable-next-line
  },[rfqId, guestEmail,isLoadedCompanyProfile])

  const setRfqStateDetailAndAddendum = (rfqStateResponse,addendumData) =>{
    const {
      submissionStatus,
      quoteData,
      returnableData,
      rfqDetail,
      rfqCompanyId,
      quoteFile
    } = rfqStateResponse;
    if (submissionStatus?.toLowerCase() === "decline") {
      isGuest
        ? navigate(".")
        : navigate(`/account/${companyId}/request-for-quote`);
    } else {

    if(isLoadedCompanyProfile){
      const initialReturnable = initialReturnableValue(
        returnableData,
        isGuest,
        hasDocuments,
        hasPasProjects,
        hasResources
      )
      const initialQuote = initialQuoteValue(quoteData);
      const initialFormValue = rfqDetail?.quoteType==="file"?{...initialReturnable,quote_file:null,savedQuoteFile:quoteFile} : {...initialReturnable,...initialQuote}
      
      setInitialFormValue(initialFormValue)
    }

      setRFQCompanyId(rfqCompanyId);
      setSubmissionStatus(submissionStatus);
      setData(rfqDetail);
      setIsQuoteTypeFile(rfqDetail?.quoteType==="file")
      
      setListQuotes(quoteData);
      setListReturnable(returnableData);
      setTotalAddendum(addendumData?.additional?.addendumCounter);
      setAddendumData(addendumData?.data);
      setSavedQuoteFile(quoteFile)
      
      let tempTab = [...tabMenu];
      tempTab[0].total = rfqDetail?.documentationDocument?.length || 0;
        tempTab[1].total = returnableData?.length || 0;
        tempTab[2].total =  rfqDetail?.quoteType==="file" ? 1:quoteData?.length;
      setTabMenu(tempTab);
     
    }
  }

  useEffect(() => {
    
    setTabMenu(prevTabMenu => {
      let tempTab = [...prevTabMenu];
      tempTab[3].total = totalAddendum;
      return tempTab;
    });
  }, [totalAddendum]);

  useEffect(()=>{
    // check for initial errors
    if (isStatusProgressOrAccepted){
      const initialErrors = getInitialErrors(listReturnable,listQuotes,isQuoteTypeFile,savedQuoteFile)
      setValidForm(isEmptyObj(initialErrors));
    }
  },[listReturnable,listQuotes,isQuoteTypeFile,savedQuoteFile,isStatusProgressOrAccepted])

  const downloadAllHandler = () => {
    setDownloadLoading(true);
    (isGuest
      ? downloadAllDocumentationGuest(rfqId, guestEmail)
      : downloadAllDocumentation(rfqId)
    ).then((res) => {
      if (res) {
        setDownloadLoading(false);
      } else {
        setDownloadLoading(false);
      }
    });
  };

  const saveHandler = useCallback(
    async () => {
      setLoadingSave(true);
      focusErrorField()
      return new Promise((resolve, reject) => {
        (isGuest
          ? saveSubmissionGuestRFQ(
              guestEmail,
              rfqId,
              valuesPayload(formRef?.current?.values, listReturnable, isGuest)
            )
          : saveSubmissionRFQ(
              rfqId,
              valuesPayload(formRef?.current?.values, listReturnable)
            )
        )
          .then((res) => {
            if (res) {
              setSavedReturnableValue(formRef?.current?.values);
              setLoadingSave(false);
              resolve(true);
            } else {
              setLoadingSave(false);
              reject(false);
            }
          })
          .catch((e) => {
            setLoadingSave(false);
            reject(false);
          });
      });
    },
    [formRef, rfqId, listReturnable, isGuest, guestEmail]
  );

  const submitHandler = useCallback(async () => {
    try{
     
      if (formRef?.current?.isValid) {
        await formRef?.current?.submitForm();
        setShowSubmitDialog(true);
      }

    }catch(e){      
    }
    //eslint-disable-next-line
  }, [formRef]);

  const continueTab = async () => {
    let tempTab = [...tabMenu]
    let current = tempTab.findIndex((tab) => tab.id === currentTab);
    if(current === 1 || current === 2){
      saveHandler()
    }
    navigate(`./${tabMenu[current+1].id}`)
  };

  const changeTab = (name,id) =>{
    navigate(`./${id}`)
  }

  const focusErrorField = () =>{
    
    formRef?.current?.validateForm().then((res)=>{
      setErrors(res)
      formRef?.current?.setTouched(setAllFieldTouched(res))
    })
  }

  return isNotFound ? (
    <NotFound />
  ) : (
    <div>
     <ButtonActions
        isLoading={isLoading}
        isAccepted={isAccepted}
        isDecliend={isDecliend}
        isInvited={isInvited}
        isRFxClosed = {isRFxClosed(data?.status)}
        setShowDialog={setShowDialog}
        saveHandler={saveHandler}
        currentTab={currentTab}
        validForm={validForm}
        loadingSave={loadingSave}
        continueTab={continueTab}
        submitHandler={submitHandler}
      />
      <div
        id="layout-dashboard"
        className="mt-8"
      >
        <JumbotronComp
          data={data}
          isLoading={isLoading}
          submissionStatus={submissionStatus}
        />
        <Flex ref={tabHeaderRef} id="RFx-container" className="mt-10 mb-10 justify-between items-center max-h-14 h-14">
          <TabsComp
            menus={tabMenu}
            activeTab={currentTab}
            changeActiveTab={changeTab}
            isLoading={isLoading}
          />
          <div ref={tabHeaderRef}></div>
         
          {!isLoading ? (
            currentTab === "documentation" && (
              <Flex align={"center"} style={{ gap: "8px" }}>
                {isAccepted && (
                  <Input
                    type="select"
                    name="category"
                    placeholder="Refine by Category"
                    onChange={(e) => setDocumentFilter(e.value)}
                    value={documentFilter}
                    options={documentCategories}
                    className="min-w-[280px]"
                    disabled={true}
                  />
                )}
                <Button
                  btntype="secondary"
                  className="!h-[56px] !min-w-[180px]"
                  disabled={!isAccepted || downloadLoading}
                  onClick={downloadAllHandler}
                >
                  {!downloadLoading ? (
                    <Flex align={"center"} gap={2}>
                      <Icon icon="download" color="#0031DD" />
                      Download All
                    </Flex>
                  ) : (
                    <Spinner size="md" />
                  )}
                </Button>
              </Flex>
            )
          ) : (
            <Flex gap={2}>
              <SkeletonBase width="240px" height="56px" />
              <SkeletonBase width="160px" height="56px" />
            </Flex>
          )}
        </Flex>
       {((errors && !validForm) && currentTab!=='documentation') && 
        <FormErrorComp 
          isReturnableError={errors?.question_answer}  
          isQuoteError={errors?.quote_answer || errors?.quote_file}
          error={errors}/>
       }
        <div className="relative">
          {currentTab === "documentation" && (
            <>
              {!isLoading ? (
                data && (
                  <DocumentationComp
                    data={data?.documentationDocument}
                    rfqId={rfqId}
                    documentFilter={documentFilter}
                  />
                )
              ) : (
                <>
                  <SkeletonBase
                    startColor="lightgray"
                    width="100%"
                    height="72px"
                    className="my-4 !rounded-lg"
                  />
                  <SkeletonBase
                    startColor="lightgray"
                    width="100%"
                    height="72px"
                    className="!rounded-lg"
                  />
                </>
              )}
            </>
          )}
          
          
          {initialFormValue && (
              <Formik
                initialValues={initialFormValue}
                innerRef={formRef}
                initialErrors={errors}
                validationSchema={allValidationSchema(
                  listReturnable,
                  listQuotes,
                  isGuest,
                  hasDocuments,
                  hasPasProjects,
                  hasResources,
                  isQuoteTypeFile,
                  savedQuoteFile
                )}
                validateOnBlur
                validateOnMount = {false}
                onSubmit={()=>true}
              >
                {({ isValid, touched,errors}) => {
                  if ((!isEmptyObj(touched))) {
                    setValidForm(isValid);
                    setErrors(errors)
                  }
                  
                  return (
                    <Form>
                      {currentTab === "returnable-schedules" && (
                        <ReturnableSchedulesComp
                          questionnaire={listReturnable}
                          rfqId={rfqId}
                          savedReturnableValue={savedReturnableValue}
                          isGuest={isGuest}
                        />
                      )}

                      {currentTab === "quote" && (
                        isQuoteTypeFile
                        ?  <QuoteFile 
                        companyName={data?.companyOwner?.name}
                        quoteFilepath={data?.quoteFilePath}
                        savedQuoteFile={savedQuoteFile}/>
                       : <YourQuoteComp
                            listQuotes={listQuotes}
                            unitMeasures={unitMeasures}
                          />
                       
                      )}
                    </Form>
                  );
                }}
              </Formik>
            )}
          {currentTab === "addendum" && addendumData && (
            <AddendumsComp
              rfqId={rfqId}
              rfqCompanyId={rfqCompanyId}
              company = {company}
              addendumData={addendumData}
              isGuest={isGuest}
              guestEmail={guestEmail}
              setTotalAddendum = {setTotalAddendum}
            />
          )}
          {}
          {(!isAccepted || isRFxClosed(data?.status)) && !isLoading && currentTab!=="documentation" && (
            <>
              <div
                style={{
                  background: `rgba(255,255,255,0.6)`,
                  zIndex: "333",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <div className="w-max sticky top-[80%] left-1/2 -translate-x-1/2 py-[48px]">
                  <PreviewOnlyComp submissionStatus={submissionStatus} isRFxClosed={isRFxClosed(data?.status)} />
                </div>
              </div>
            </>
          )}
        </div>

        <ButtonActions
          isLoading={isLoading}
          isAccepted={isAccepted}
          isDecliend={isDecliend}
          setShowDialog={setShowDialog}
          saveHandler={saveHandler}
          currentTab={currentTab}
          loadingSave={loadingSave}
          isBottom
          isRFxClosed = {isRFxClosed(data?.status)}
          isInvited={isInvited}
          continueTab={continueTab}
          submitHandler={submitHandler}
          validForm={validForm}/>

        <AcceptDeclineModal
          showDialog={showDialog}
          onClose={() => setShowDialog(null)}
          rfqId={rfqId}
          setSubmissionStatus={setSubmissionStatus}
          guestEmail={guestEmail}
          isGuest={isGuest}
          companyId={companyId}
        />

        <SubmitModal
          showDialog={showSubmitDialog}
          onClose={() => setShowSubmitDialog(false)}
          rfqId={rfqId}
          companyId={companyId}
          saveSubmissionHandler={saveHandler}
          isGuest={isGuest}
          guestEmail={guestEmail}
        />
      </div>
    </div>
  );
};

export default RFQPreview;
