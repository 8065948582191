import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import tw, { styled, css } from "twin.macro";

import { ReactComponent as BackIcon } from "../../../../assets/icons/close.svg";
import { TabHeader } from "components/TabHeader";
import { Button } from "components/RFQ/Button";
import { Input } from "components/RFQ/Input/Input";
import { useSelector } from "react-redux";
import { getProjectAll } from "actions/RFQ/projectsAction";
import { assignToProject } from "actions/RFQ/supplierListsActions";
import { Link, navigate } from "@reach/router";


const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-6 max-h-[calc(100vh - 100px)]`]);


const DrawerHeaderStyled = styled(DrawerHeader)(() => [
  tw`text-black py-6 px-0`,
  css`
      .close-text {
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
      }
  
      .header-container {
        display: flex;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
      }
  
      .clear-container {
        display: flex;
        gap: 24px;
        justify-items: center;
        place-items: center;
      }
    `,
]);



const AddtoProject = ({ isOpen, onClose, listId, ...props }) => {
  const [selectedProject, setSelectedProject] = useState(null)
  const [projects, setProjects] = useState(useSelector((state) => state.rfq.projects?.projects?.allProject?.data) || [])
  const projectsOptions = useMemo(() => projects?.map((project) => ({ id: project.id, value: project.id, label: project.name })), [projects])
  const companyId = useSelector((state) => state.auth.user.company_id);
  useEffect(() => {
    if (!projects?.length > 0) {
      getProjectAll()
        .then((res) => {
          setProjects(res?.allProject?.data)
        })
    }
    // eslint-disable-next-line
  },[])

  const addingToProject = () => {
    assignToProject(listId, [selectedProject.id])
      .then((res) => {
        const projectSelected = selectedProject
        
        setSelectedProject(null)
        onClose()

        if (res?.data?.status) {
          navigate(`/account/${companyId}/projects/${projectSelected.id}/${projectSelected.label?.toLowerCase().replace(/ /g, "-")}`)
        }
      })
      .catch((err) => console.log(err))
  }

  const CloseButton = () => {
    return (
      <Button btntype={"icon"} onClick={() => {
        setSelectedProject(null)
        onClose()
      }}>
        <BackIcon style={{ width: "25px", height: "25px", marginRight: "0px" }} />
      </Button>
    );
  };

  return (
    <div>
      <Drawer
        placement={"right"}
        isOpen={isOpen}
        onClose={() => {
          setSelectedProject(null)
          onClose()
        }}
        size="xl"
        {...props}
      >
        <DrawerOverlay onClick={() => {
          setSelectedProject(null)
          onClose()
        }} />
        <DrawerContent>
          <DrawerHeaderStyled borderBottomWidth="1px" className="mx-6">
            <TabHeader
              noDivider={true}
              heading={'Add SUPPLIER List To project'}
              description={'Select the relevant project for the Supplier List.'}
            >
              <CloseButton />
            </TabHeader>
          </DrawerHeaderStyled>
          <DrawerBodyStyled>
            <div className="mb-8">
              <Input
                onChange={setSelectedProject}
                type="select"
                label="Select Project"
                placeholder="Select Project"
                options={projectsOptions}
              />
            </div>
            <div className="mb-8">
              <Button width='100%' onClick={addingToProject} disabled={selectedProject?.id ? false : true}>
                Add to project
              </Button>
            </div>
            <div className="text-center">
              <p>
                Haven’t created the project yet?
                <Link to={`../../../projects/new-project/suppliers-list/${listId}`}> Start here</Link>
              </p>
            </div>
          </DrawerBodyStyled>
        </DrawerContent>
      </Drawer>
    </div>
  )
}

export default AddtoProject
