import { Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel } from "@chakra-ui/react";


import React from "react";
import tw, { styled } from 'twin.macro';
import { DateInput } from "components/DateInput";
import { css } from "@emotion/react";
import { useField } from "formik";
import moment from "moment";

const AccordionButtonStyled = styled(AccordionButton)(() => [
  tw`px-0 py-4 text-black font-semibold hover:bg-transparent`
])

const AccordionIconStyled = styled(AccordionIcon)(() => [
  tw`w-5 h-5 text-fq-quinary-400`
]);

const AccordionItemStyled = styled(AccordionItem)(() => [
  tw`px-5 border-b`,
  css`
      .chakra-collapse{
        overflow: visible !important;
      }
    `
]);

const AccordionPanelStyled = styled(AccordionPanel)(() => [
  tw`pb-5 p-0`,

]);

const AccordionSectionSupplier = () => {
  const [,meta,] = useField('start_date')
  
  return (
    <div>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItemStyled>
          <AccordionButtonStyled>
            <Box as="span" flex="1" textAlign="left">
              Date
            </Box>
            <AccordionIconStyled />
          </AccordionButtonStyled>
          <AccordionPanelStyled className='!h-32'>
            <div className="flex justify-start items-start gap-4 font-semibold">
              <DateInput name='start_date' label='Start Date' />
              <span className="font-bold inline-flex items-center h-[108px]">
                -
              </span>
              <DateInput name='end_date' disabled={!meta.value} label='End Date' minDate={moment(meta.value).toDate()} />
            </div>
          </AccordionPanelStyled>
        </AccordionItemStyled>
      </Accordion>
    </div>
  );
};

export default AccordionSectionSupplier;
