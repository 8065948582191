import { navigate, useParams } from "@reach/router";
import { Button } from "components/RFQ/Button";
import React, { useEffect, useState } from "react";
import tw, { styled, css } from "twin.macro";
import { TabHeader } from "components/TabHeader";
import { Flex, useDisclosure } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import SearchBar from "components/RFQ/SearchBar/SearchBar";
import { CompanyList } from "../CompanyList";
import AddtoProject from "./AddtoProject";
import { useDispatch } from "react-redux";
import {
  getSupplierListById,
  getSuppliers,
  removingSupplier,
} from "actions/RFQ/supplierListsActions";
import ButtonViewGroup from "components/ButtonViewGroup/ButtonViewGroup";
import { CompanyGridView } from "../CompanyGridView";
import SortComp from "components/RFQ/SortComp/SortComp";
import { BackButton } from "components/RFQ/BackButton";
import {
  createSupplierList,
  removeAllToListDirect,
} from "actions/searchActions";
import { NotFound } from "routes/NotFound";
import {
  DropdownMenu,
  DropdownMenuDrawer,
  DropdownMenuTrigger,
  DropdownItem,
} from "components/RFQ/MenuDropdown";
import { SkeletonBase } from "components/Skeleton";

const SupplierDetailContainer = styled.div(() => [
  tw`relative overflow-hidden`,
  css`
    .buttons-container {
      gap: 8px;
    }
    .container-content {
      padding-right: 24px;
      padding-left: 24px;
    }
    .img-card {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  `,
]);

const sortedData = [
  {
    name: "Sort Alphabetically (A to Z)",
    value: "asc",
    sort: "Sort (A to Z)",
  },
  {
    name: "Sort Alphabetically (Z to A)",
    value: "desc",
    sort: "Sort (Z to A)",
  },
  {
    name: "Sort by Last Activity",
    value: "last_activity",
    sort: "Sort Last Activity",
  },
  {
    name: "Sort by Date Created",
    value: "created",
    sort: "Sort Date Created",
  },
];

const sortingSupplier = (suppliers) => {
  if (!suppliers) return null;

  return suppliers.sort((a, b) => {
    return a.name.toLowerCase() < b.name.toLowerCase()
      ? -1
      : a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : 0;
  });
};

const SupplierDetail = ({ id }) => {
  let params = useParams();
  let searchParams = new URLSearchParams(window.location.search);
  const projectId = searchParams.get("project-id");
  const supplierListId = params.id;
  const supllierListName = params.slug;
  const dispatch = useDispatch();
  const [view, setView] = useState("list");
  const [sorted, setSorted] = useState(null);
  const [openPopUp, setOpenPopUp] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [search, setSearch] = useState(null);
  const [supplierList, setSupplierList] = useState(null);
  const [suppliers, setSuppliers] = useState(
    sortingSupplier(supplierList?.suppliers) || []
  );
  const [suppliersStatus, setSuppliersStatus] = useState("idle");
  const [loadList, setLoadList] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [checkedRow, setCheckedRow] = useState([]);

  const getSortingCompany = () => {
    setOpenPopUp(false);
  };

  const clickMenuHandler = (companyId) => {
    removingSupplier(id, companyId).then((res) => {
      if (res) setSuppliers(suppliers.filter((item) => item.id !== companyId));
    });
  };

  // update contract object in supplier list 
  const onContractAddOrRemove = (suppliers) => {
    setSuppliers(sortingSupplier(suppliers)) 
  }

  const MenuDropdown = ({ companyId }) => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger icon></DropdownMenuTrigger>
        <DropdownMenuDrawer>
          <DropdownItem onClick={() => clickMenuHandler(companyId)}>
            <Icon icon="trash-03" />
            <p
              className="font-roboto font-medium text-text-secondary-700 text-[14px]"
              style={{ lineHeight: "20px" }}
            >
              Remove Supplier
            </p>
          </DropdownItem>
        </DropdownMenuDrawer>
      </DropdownMenu>
    );
  };

  useEffect(() => {
    if (id && !supplierList) {
      setLoadList(true);
      getSupplierListById(id)
        .then((res) => {
          if (res.data) {
            setSupplierList(res.data);
            setLoadList(false);
            setSuppliers(res.data.suppliers);
          } else {
            setIsNotFound(true);
          }
        })
        .catch((err) => {
          setLoadList(false);
          setIsNotFound(true);
        });
    }
  }, [id, supplierList]);

  useEffect(() => {
    if (search !== null || sorted !== null) {
      setSuppliersStatus("loading");
      getSuppliers(id, { search, sort: sorted?.value || "asc" }).then(
        (suppliers) => {
          setSuppliers(suppliers);
          setSuppliersStatus("idle");
        }
      );
    }
  }, [search, sorted, id]);

  const inviteNewSupplier = () => {
    if (!loadList) {
      let payload = {
        id: supplierList.id,
        name: supplierList.name,
        active: suppliers,
      };
      dispatch(removeAllToListDirect());
      dispatch(createSupplierList(payload));
      setTimeout(() => {
        navigate(
          `/search?supplier=${supplierListId}&supplierlist=${supllierListName}`
        );
      }, 100);
    }
  };

  return isNotFound ? (
    <NotFound />
  ) : (
    <SupplierDetailContainer id="element-scroll-supplier-detail">
      <TabHeader
        heading={supplierList?.name}
        isLoading={loadList}
        description={supplierList?.description || ""}
        icon={<BackButton handleBackButtonClick={() => navigate("../")} />}
      >
        <Flex gap={4} align="center">
          {!projectId && <Button
            btntype="secondary"
            height="48px"
            onClick={onOpen}
            className="!w-max"
            disabled={loadList}
          >
            <Flex gap={2} className="items-center">
              <Icon icon="plus" />
              Add To Project
            </Flex>
          </Button>}
          <Button
            width={200}
            height="48px"
            onClick={inviteNewSupplier}
          >
            Search Suppliers
          </Button>
        </Flex>
      </TabHeader>
      <div id="supplier-detail-layout" className="px-6">
        <div>
          <Flex style={{ justifyContent: "space-between" }}>
            <div>
              {loadList ? <SkeletonBase />
              :<p className="font-roboto text-lg font-semibold mt-4 text-text-tertiary-900">
                {supplierList?.is_company_approved_list ? 'Panel' : 'My Suppliers'}
              </p>}
            </div>
            <Flex style={{ gap: "8px" }}>
              <SearchBar setState={setSearch} />
              <SortComp
                data={sortedData}
                sorted={sorted || sortedData[0]}
                onClickCB={(item) => {
                  getSortingCompany(item.value);
                  setSorted(item);
                }}
                openPopUpCB={openPopUp}
                setOpenPopUpCB={setOpenPopUp}
              ></SortComp>
              <ButtonViewGroup setView={setView} view={view} />
            </Flex>
          </Flex>
        </div>
        <div>
          {view === "grid" ? (
            <CompanyGridView
              suppliersStatus={suppliersStatus}
              loadList={loadList}
              suppliers={suppliers}
              MenuDropdown={MenuDropdown}
              onContractAddOrRemove = {onContractAddOrRemove}
            />
          ) : (
            <CompanyList
              suppliersStatus={suppliersStatus}
              loadList={loadList}
              suppliers={suppliers}
              MenuDropdown={MenuDropdown}
              checkedRow={checkedRow}
              setCheckedRow={setCheckedRow}
              onContractAddOrRemove = {onContractAddOrRemove}
            />
          )}
          {view === "list" && (
            <Button
              btntype="secondary"
              className="!text-xs mb-5"
              onClick={() => inviteNewSupplier()}
            >
              <Flex align={"center"} gap={1}>
                <Icon icon="plus-square" color="#0031DD" />
                <div>Search Suppliers</div>
              </Flex>
            </Button>
          )}
        </div>
        <AddtoProject listId={id} isOpen={isOpen} onClose={onClose} />
      </div>
    </SupplierDetailContainer>
  );
};

export default SupplierDetail;
