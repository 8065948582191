import styled from 'styled-components';
import { Form } from 'formik';

import { TextInput, TextInputStyled } from '../FormInputs/TextInput';
import { Sectors } from '../FormInputs/Sectors';
import tw from 'twin.macro';

export const SearchBoxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-grow: 1;
`;

export const Results = styled.p`
    font-weight: 500;
    color: ${(props) => props.theme.colors.gray};
    font-size: ${(props) => props.theme.fontSizes.sm};
    margin: 0;
`;

export const FormStyled = styled(Form)`
    position: relative;
    margin-left: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    flex-grow: 2;
    padding-left: ${(props) => props.theme.space.sm};
    align-items: stretch;
`;

export const SearchBoxInputContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 48px !important;
    border-radius: 6px;
`;

export const SearchIconContainer = styled.div`
    position: absolute;
    top: 0;
    transform: translateY(12px);
    left: 10px;
`;

export const SearchBoxInputRFQContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 400px;
    max-height: 48px;
`;

export const SectorsStyled = styled(Sectors)`
    min-width: 290px;
    margin: 0;

    > div {
        margin: 0 0 0 ${(props) => props.theme.space.xs};
        height: 100%;

        ${'' /* hacky styling - need to fix with props or class prefix */}
        > div {
            margin: 0;
        }
    }
`;

export const SearchBoxSubmit = styled.button`
    background: none;
    border: none;

    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: ${(props) => props.theme.transitions.default};

    &::hover {
        opacity: 0.75;
    }

    svg {
        width: 36px;
        height: 36px;
        color: white;
        background-color: ${(props) => props.theme.colors.royalBlue};
        border-radius: 50%;
        padding: 10px;
    }
`;

export const SearchBoxInputStyled = styled(TextInput)`
    position: relative;
    ${TextInputStyled} {
        background: ${(props) => (props.white ? 'white' : props.theme.colors.snow)};
        padding: ${(props) => (props.isNoPadding ? '0 6rem 0 1rem !important' : '0 2rem 0 2.5rem !important')} ;
        margin: 0;
        height: 48px !important;
        outline: none;
        border-radius: 6px;
        border-color: #D0D5DD;
    }
    ${tw`bg-white focus-within:border-utility-brand-300 focus-within:ring-4 ring-ring-blue-primary
      placeholder:text-text-primary-500 placeholder:font-roboto placeholder:font-normal placeholder:text-16px shadow-sm
    `}
`;
export const SearchBoxInputRFQStyled = styled(TextInput)`
    position: relative;

    ${TextInputStyled} {
        background: 'white';
        padding: 1.25rem 2.5rem;
        max-height: 40px;
        border-color: #D0D5DD;
        margin: 0;
    }
`;

export const SearchBoxButtons = styled.div`
    position: absolute;
    right: ${(props) => props.theme.space.xxs};
    top: 50%;
    transform: translateY(-50%);
    display: flex;

    > button:not(:last-of-type) {
        margin-right: ${(props) => props.theme.space.xxs};
    }
`;

export const CancelButton = styled(SearchBoxSubmit)`
    svg {
        background-color: ${(props) => props.theme.colors.red};

        line{
            stroke:#fff
        }
    }
`;
