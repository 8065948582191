import React from "react";
import { navigate } from "@reach/router";
import { TabHeader } from "components/TabHeader";
import { Flex, Spinner } from "@chakra-ui/react";
import { Button } from "components/RFQ/Button";
import MenuBarComp from "./MenuBarComp";
import { BackButton } from "components/RFQ/BackButton";

import NoBorderButton from "components/RFQ/NoBorderButton/NoBorderButton";
import { cn } from "utils/helpers";
import { SkeletonBase } from "components/Skeleton";

const ButtonActions = (
    { isBottom,
        isLoading,
        isDecliend,
        isAccepted,
        loadingSave,
        isInvited,
        setShowDialog,
        currentTab,
        continueTab,
        saveHandler,
        isRFxClosed,
        submitHandler,
        validForm }) => {

    return isLoading ? (
      <div className="flex justify-end gap-3 mt-4">
        <SkeletonBase width="128px" height="46" />
        <SkeletonBase width="128px" height="46" />
      </div>
    ) : (!isDecliend && !isRFxClosed)? (
      <TabHeader
        noDivider={true}
        icon={
          !isBottom && (
            <BackButton handleBackButtonClick={() => navigate("..")} />
          )
        }
      >
        {isAccepted ? (
          <Flex gap={3} className={cn('items-center',isBottom && "py-8") }>
            {loadingSave ? (
              <Spinner />
            ) : (
              <NoBorderButton
                onClick={() => saveHandler()} //
                text="Save"
                className="bg-white text-text-tertiary-600 text-[14px] font-roboto font-medium"
              />
            )}
            {currentTab !== "Addendums" && (
              <Button
                type="button"
                btntype="secondary"
                disabled={loadingSave} 
                onClick={() => {continueTab()}} //
              >
                Continue
              </Button>
            )}
            <Button
              type="button"
              onClick={() => {submitHandler(currentTab)}} 
              disabled={loadingSave || (!validForm)}
            >
              Submit Response
            </Button>
          </Flex>
        ) : (
          (isInvited) && (
            <MenuBarComp
              setShowDialog={setShowDialog}
              className={isBottom && "py-8"}
            ></MenuBarComp>
          )
        )}
      </TabHeader>
    ) : (
      !isBottom && (
        <TabHeader
          noDivider={true}
          icon={<BackButton handleBackButtonClick={() => navigate("..")} />}
        />
      )
    );
  };

  export default ButtonActions;