import React from "react";
import { Heading } from "components/Heading";
import RFQStatusComp from "components/RFQ/RFQStatusComp/RFQStatusComp";
import RfQResponseComp from "components/RFQ/RfQResponseComp/RfQResponseComp";
import moment from "moment";
import { SkeletonCircle } from "@chakra-ui/react";
import { Avatar } from "components/RFQ/Avatar";
import { TextBoxHTML } from "components/TextBoxHTML";
import { SkeletonBase } from "components/Skeleton";
import PreviewOnlyComp from "components/RFQ/PreviewOnlyComp/PreviewOnlyComp";
import { Divider } from "components/Divider";
import { Icon } from "assets/icons/Icon";

const ListHorizontalItem = ({title,value}) => {
  return <>
    <p style={{ width: "200px" }} className="text-gray-text-400">
      {title}
    </p>
    <p
      className="font-roboto font-medium text-text-primary-900"
      style={{ fontSize: "16px" }}
    >
      {value}
    </p>
</>
}

const JumbodivonComp = ({ data, isLoading, submissionStatus }) => {
  
  return (
    <div className="rounded-lg shadow-lg p-10 bg-white border border-[#EAECF0] border-solid min-h-[430px]">
      <div
        className="flex w-full items-center mb-2 justify-start"
      >
        <div>
            {isLoading ? (
              <SkeletonCircle width={12} height={12} startColor="lightgray" />
            ) : (
              <Avatar
                avatar={data?.project?.logoPath}
                defaultImage="company"
                size="lg"
              />
            )}
          </div>
        <div className="flex items-center flex-grow flex-wrap justify-between !leading-[50px] p-4">
          
          <div className="">
            {!isLoading ? (
              <Heading fontSize="3rem">
                <div
                  className="text-royal-blue"
                >
                  {data?.name}
                </div>
              </Heading>
            ) : (
              <SkeletonBase width="300px" height="36px" />
            )}
          </div>
          <div>
          {!isLoading ? (
            <div style={{ float: "right" }}>
              <RfQResponseComp
                response={
                  submissionStatus
                    ? submissionStatus?.toUpperCase()
                    : ""
                }
                size="p-4"
              ></RfQResponseComp>
            </div>
          ) : (
            <SkeletonBase
              width="100px"
              height="32px"
              className="!rounded-full"
            />
          )}
        </div>
        </div>
        
      </div>
      <div className="flex mt-4 mb-10 text-text-secondary-700 pt-8">
        {!isLoading ? (
          <TextBoxHTML html={data?.description} className="text-lg" />
        ) : (
          <SkeletonBase width="100%" height="48px" />
        )}
      </div>
      <div className="flex flex-col gap-5">
         <div className="border-t border-[#EAECF0] border-solid pt-3">
         {!isLoading ? (
              <>
                <p className="font-roboto font-[16px] pb-2 text-gray-text-400">
                 Scope of Service
                </p>
                <TextBoxHTML
                  html={data?.escopeOfService}
                  className="text-base text-text-primary-900 font-medium"
                />
              </>
            ) : (
              <>
                <SkeletonBase width="160px" className='mr-8' />
                <SkeletonBase width="100%" height="100px" />
              </>
            )}
        </div>
        <div className="flex flex-wrap justify-between flex-1 border-t border-[#EAECF0] border-solid pt-3">
          <div className="lg:grid-cols-2 grid md:grid-cols-1 gap-y-3">
            {!isLoading ? (
              <>
                
                  <p style={{ width: "200px" }} className="text-gray-text-400">
                    Project Status
                  </p>
                  <p>
                    <RFQStatusComp
                      status={data?.project?.status}
                      rounded="rounded-full"
                    />
                  </p>
                
                <ListHorizontalItem title="RFx ID" value={data?.rfqIdNumber}/>
                <ListHorizontalItem title="Request Type" value={data?.type || "-"}/>
                <ListHorizontalItem title="Client" value={data?.client}/>
                <ListHorizontalItem title="Contact Name" value={data?.companyOwner?.contact_name || ' - '}/>
                <ListHorizontalItem title="Project Location" value={data?.location}/>
                <ListHorizontalItem title="RFx Published" value={moment(data?.rfxPublished)
                      .format("dddd, D MMMM YYYY")}/>
                <ListHorizontalItem title="Submission Deadline" value={`${moment(data?.submissionDeadline)
                      .format("dddd, D MMMM YYYY hh:mm A ")} ${data?.submissionTimezone}`}/>
              </>
            ) : (
              Array(5)
                .fill(0)
                .map((_, i) => (
                  <div className="flex gap-3">
                    <SkeletonBase key={i} width="120px" className="mr-10" />
                    <SkeletonBase key={i} width="200px" />
                  </div>
                ))
            )}
          </div>
          {isLoading?(
              Array(5)
                .fill(0)
                .map((_, i) => (
                  <div className="flex gap-3">
                    <SkeletonBase key={i} width="120px" className="mr-10" />
                    <SkeletonBase key={i} width="200px" />
                  </div>
                ))
            ):(
              <div>
              <div className="flex gap-3 pt-4">
                <Icon icon="user3" /> <p>Contact Person</p>
              </div>
              <div className="py-4 lg:grid-cols-2 grid md:grid-cols-1   gap-y-3">
                <ListHorizontalItem title="Name" value={data?.leadContactName || '-'}/>
                <ListHorizontalItem title="Email" value={data?.email || '-'}/>
                <ListHorizontalItem title="Phone Number" value={data?.phoneNumber || '-'}/>
              </div>
      </div>
            )}
          {!isLoading && <div className="w-full">
                  <Divider />
                  {/* id RFx-container is defined in RFQPreview.js file */}
                  <div className="mt-4">
                    <a href="#RFx-container" className="mt-5">
                      <PreviewOnlyComp submissionStatus='submitted' label="Preview RFx below" icon="arrow-down" />
                    </a>
                  </div>
                </div>}
        </div>
      </div>
    </div>
  );
};

export default JumbodivonComp;
