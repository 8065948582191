import apiV2 from "api-v2";
import * as constants from "./actionTypes";
import { toFormData } from "axios";
import { grabFirstError, processFormData, showToast } from "utils/helpers";

export const getRfqPerPage = ({ limit = 12, page = 1, search = '', sort = 'last_activity', filters, reset = false }) => {
  return async (dispatch, getState) => {
    dispatch({ type: constants.GET_RFQS_REQUEST });
    if (reset) dispatch({ type: constants.RESET_RFQ, payload: true });
    (async () => {
      try {
        const params = { limit, page, search, sort, filters }
        const { data: { data: rfqsData, filters: filterOptions } } = await apiV2.get(`rfq`, { params });

        let oldData = await getState().rfq.rfqs.rfqs;
        let newOldData = oldData

        if (page > 1) {
          Object.keys(rfqsData).forEach((rfq) => {
            if (oldData[rfq].meta.last_page >= rfqsData[rfq].meta.current_page) {
              newOldData[rfq].data = [...oldData[rfq]?.data, ...rfqsData[rfq]?.data]
              newOldData[rfq].meta = rfqsData[rfq].meta;
            }
          })
        }

        let countData = await getState().rfq.rfqs.rfqCount;
        Object.keys(rfqsData).forEach((rfq) => (
          countData[rfq] = rfqsData[rfq].meta.total
        ))

        if (reset) {
          await dispatch({
            type: constants.GET_RFQS_SUCCESS,
            payload: rfqsData
          });
          await (dispatch({
            type: constants.GET_RFQ_FILTER_OPTIONS,
            payload: filterOptions
          }))
        } else {
          await dispatch({
            type: constants.GET_RFQS_SUCCESS,
            payload: page > 1 ? newOldData : rfqsData,
          });
          await dispatch({
            type: constants.SET_RFQ_BADGES_COUNT,
            payload: countData,
          });
          await (dispatch({
            type: constants.GET_RFQ_FILTER_OPTIONS,
            payload: filterOptions
          }))
        }
      } catch (err) {

      }
      //for future use
      // await dispatch({ type: constants.IS_SEARCHING, payload: false });
    })();
  };
};

export const createRfq = async (data) => {
  const formData = toFormData(data);
  try {
    const { data: newRfq } = await apiV2.post(`rfq/store`, formData);
    if (newRfq.status) {
      showToast(newRfq?.message, newRfq?.title, true)
      return newRfq.data
    } else {
      showToast(newRfq?.message, newRfq?.title)
      return null
    }
  } catch (err) {
    showToast(err?.response?.data?.message, 'Error')
  }
}

export const getRFQId = async (id) => {
  try {
    const { data: rfq } = await apiV2.get(`rfq/${id}`);
    if (rfq.status) {
      return rfq.data
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

export const getRFQType = async () => {
  try {
    const { data: rfq } = await apiV2.get(`rfq/type`);
    if (rfq.status) {
      return rfq.data
    } else {
      return null
    }
  } catch (err) {
    throw new Error(err?.response?.data?.message)
  }
}

export const getDocumentType = async () => {
  try {
    const { data: categories } = await apiV2.get(`rfq/categories`);
    if (categories.status) {
      return categories.data
    } else {
      return null
    }
  } catch (err) {
    throw new Error(err?.response?.data?.message)
  }
}

export const getUnitMeasures = async () => {
  try {
    const { data: units } = await apiV2.get(`quote/unit-of-measurement`);
    if (units.status) {
      return units.data
    } else {
      return null
    }
  } catch (err) {
    throw new Error(err?.response?.data?.message)
  }
}

export const saveDraftRFQ = async (rfqId, data, step, isSubmit, actions) => {
  const formData = toFormData({ ...data, step }, undefined, { indexes: true });
  try {
    const { data: saved } = await apiV2.post(`rfq/${rfqId}/${isSubmit ? 'submit' : 'save'}`, formData);
    if (saved.status) {
      showToast(saved?.message, saved?.title, true)
      return saved.data
    } else {
      showToast(isSubmit ? 'Failed to submit request for quote.' : 'Draft RFx saved failed', saved?.title)
      return null
    }
  } catch ({ response }) {
    (response?.data?.errors && actions) && actions.setErrors(response?.data?.errors);
    showToast(
      (isSubmit ? 'Failed to submit request for quote. ' : 'Draft RFx saved failed. ') + (grabFirstError(response?.data?.errors) || ''),
      response?.data?.title
    )
  }
}

export const saveQuestionnareTemplate = async (id, name) => {
  const formData = {
    rfq_id: id,
    name,
  }
  try {
    const { data: saved } = await apiV2.post(`template/store`, formData);
    if (saved.status) {
      showToast(saved?.message, saved?.title, true)
      return saved.data
    } else {
      showToast(saved?.message, saved?.title)
      return null
    }
  } catch (err) {
    showToast("Template saved failed", "Error")
  }
}

export const setAwardedSupplier = async (id, data) => {
  let payload = {
    company_id: !data?.email ? data?.company : null,
    isGuest: data?.email ? 1 : 0,
    status: data?.status,
    document: data?.file?.length > 0 ? data?.file[0] : null,
    message: data?.message,
    email: data?.email || data?.company,
  }
  const formData = processFormData(payload);
  try {
    const { data: send } = await apiV2.post(`rfq/${id}/awarded`, formData);
    if (send.status) {
      showToast(send?.message, send?.title, true)
      return send.data
    }
  } catch (err) {
    showToast(err?.response?.data?.message, "Error")
  }
}

export const getRFQFilteringOptions = async () => {
  try {
    const { data: options } = await apiV2.get(`rfq/rfq-filter-attribute`);
    if (options.status) {
      return options.data
    } else {
      return null
    }
  } catch (err) {
    throw new Error(err?.response?.data?.message)
  }
}

export const updateSubmissionDeadLine = async (rfqID,data) =>{
  try {
    const response = await apiV2.post(`rfq/${rfqID}/extend-deadline`,data)
    return response
  } catch (error) {
    throw error
  }
}