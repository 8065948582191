import { removeCarriage } from "./helpers";

export const getSavedProjectField = (project,tradeValues) => {
    const values = project.data
    const checkTrade = () => {
      const optionIndex = tradeValues?.findIndex((option) => (
          values?.trade_value === option.name
      ))
      return optionIndex === -1? 1 :tradeValues&&tradeValues[optionIndex]?.id;
    }
    const savedField = {
        name: values?.name || "",
        location: values?.location || "",
        summary: removeCarriage(values?.summary) || "",
        value: values?.value?.toString() || "0",
        trade_value: checkTrade() || 1,
        year_completed: values?.year_completed?.toString() || "",
        funding_type: values?.funding_type || "",
        site_area: values?.site_area || "",
        gross_floor_area: values?.gross_floor_area || "",
        scope: values?.scope || [],
        client_name: values?.client_name || "",
        head_contractor: values?.head_contractor || "",
        sector_id: values?.sector ? values?.sector.id : "",
        images: values?.images || [],
        innovation_value_summary: removeCarriage(values?.innovation_value_summary) || "",
        awards: removeCarriage(values?.awards) || "",
        testimonials: values?.testimonials || "",
        status: values?.status,
        procurement_method_ids: Array.isArray(values?.procurement_methods)
          ? values?.procurement_methods.map((method) => method.id)
          : [],
        trade_ids: Array.isArray(values?.trades)
          ? values?.trades.map((trade) => trade.id)
          : [],
      }
      return savedField

}