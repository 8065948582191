import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "axios";
import { Heading } from "../../components/Heading";
import { CompanyCard } from "../../components/CompanyCard";
import { getCompanies } from "../../actions/companyActions";
import { Button } from "components/Button";
import "styles/loading.scss";
import { TabContent } from "../../components/Tabs/styled";
import { Icon } from "assets/icons/Icon";
import CreateSupplierDrawer from "./drawer/CreateSupplierDrawer";
import AddSupplierDrawer from "./drawer/AddSupplierDrawer";
import { isAdmin } from "utils/helpers";
import { Flex, SkeletonCircle } from '@chakra-ui/react';
import { SearchBox } from "components/SearchBox";
import { Toolbar } from "components/Toolbar";
import { Input } from "components/RFQ/Input";
import { removeFromList } from '../../actions/searchActions';
import { SkeletonBase } from "components/Skeleton";

const ShortlistStyled = styled.div`
  padding: ${(props) =>
    props.padded ? `${props.theme.space.lg} ${props.theme.space.md}` : 0};

  ${(props) =>
    props.searchShortlist &&
    `
        margin: 0;
    `}

  .shortlisted-text {
    font-size: ${(props) => props.theme.fontSizes.xxl};
    line-height: 32px;
    margin-bottom: 0px;
  }
`;

const ShortlistContainer = ({
  getCompanies,
  items,
  noHeading,
  padded,
  searchShortlist,
  handleRemoveButtonClick,
  linkNewTab,
  user,
  removeFromList,
  ...props
}) => {
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idSupplierList, setIdSupplierList] = useState("");
  const sortList = [
    {
      value: 'name',
      label: 'Sort Alphabetically (A-Z)'
    },
    {
      value: '-name',
      label: 'Sort Alphabetically (Z-A)'
    }
  ];

  const [sortBy, setSortBy] = useState("name")
  const [keyword, setKeyword] = useState("")

  useEffect(() => {
    if (!items.length && !searchShortlist) {
      getCompanies("", "");
    }
  }, [items, getCompanies, searchShortlist]);

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let supplier = params.get("supplier");
    if (supplier) {
      setIdSupplierList(supplier);
    }
  }, []);

  const companies = items.length ? items : props.searchResult;
  let listIDCompanies = [];
  let listCompanies = [];
  for (let a = 0; a < companies.length; a++) {
    listIDCompanies.push(companies[a].id);
    listCompanies.push({
      label: companies[a]?.name,
      value: companies[a]?.id,
    });
  }
  const groupId = props.groupId;
  const companyId = props.companyId;

  const download = () => {
    setIsLoading(true);
    axios({
      url:
        process.env.REACT_APP_API_URL +
        `/groups/print-pdf/${groupId}?companyId=${companyId}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "shortlist.pdf");
      document.body.appendChild(link);
      link.click();
      setIsLoading(false);
    });
  };

  const createSupplierList = () => {
    setShowCreateDrawer(true);
  };

  const addSupplierToList = () => {
    setShowAddDrawer(true);
  };

  const onCloseCreateDrawer = () => {
    setShowCreateDrawer(false);
  };

  const onCloseAddDrawer = () => {
    setShowAddDrawer(false);
  }



  const onSearchSubmit = async(values) => {
    setKeyword(values?.keyword)
    await getCompanies(values?.keyword, sortBy);
  }

  const changeSortBy = (select) => {
    setSortBy(select?.value)
    getCompanies(keyword, select?.value);
  }


  const handleRemove = (companyId) => {
    removeFromList(companyId, 'shortlist', groupId);
  };

  return (
    <div className="w-full">
      <TabContent>
        {!noHeading && (
          <Heading size="xl">
            {props.admin ? "Companies Admin" : "Companies Listings"}
          </Heading>
        )}
        <Flex className="justify-end !w-full">
          <div>
            <Toolbar>
              <SearchBox
                placeholder="Search resources"
                handleFormSubmit={onSearchSubmit}
                keywordFilter
                whiteInput
                isCompanyList
              />
              <div style={{ marginLeft: '20px' }}>
                <Input
                  options={sortList}
                  onChange={changeSortBy}
                  placeholder={sortBy}
                  value={sortList[0].value}
                  type="select"
                  className="!w-[250px] !max-h-[50px]"
                  size="base"
                />
              </div>
            </Toolbar>
          </div>
        </Flex>
        <ShortlistStyled
          className="row"
          padded={padded}
          searchShortlist={searchShortlist}
        >
       
          {props?.isSearching ? (
            <div className="flex gap-5 flex-wrap">
              {
              [...Array(5)].map((_)=>{
              return  <div className="h-96 w-96 bg-white relative rounded shadow">
                        <SkeletonBase width='100%' startColor="#c8ccd4" height="180px" className='border border-white'  />
                        <SkeletonCircle size='160px' startColor="#c8ccd4" className='absolute top-[25%] left-[26%] z-1 border border-white' />
                        <SkeletonBase width='50%' startColor="#c8ccd4" height="16px" className='mx-20 mt-[100px]'  />
                        <SkeletonBase width='50%' startColor="#c8ccd4" height="10px" className='mx-20 mt-2'  />
                      </div> })
            }
            </div>
             
          ) : (
            <>
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 justify-between">
                {companies.length ? (
                  Object.values(companies).map((item) => {
                    return (
                      <div>
                        <CompanyCard
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          size={item.size}
                          logo={item.logo_path}
                          banner={item.banner_path}
                          className={`col md-6 ${searchShortlist ? "lg-3" : "lg-4"}`}
                          admin={props.admin}
                          location={item.location}
                          profileType={item.profile_type}
                          handleRemoveButtonClick={handleRemove}
                          linkNewTab={linkNewTab}
                          company_profile_status={item.company_profile_status}
                          isAdmin={props.admin}
                        />
                      </div>
                    );
                  })
                ):<>No Companies Found</>}
              </div>
              {companies.length > 0 &&
                window.location.pathname !== "/accounts" &&
                window.location.pathname !== "/companies" ? (
                <>
                  <div className="flex">
                    <Button
                      btntype="no-background-border"
                      height="46px"
                      className="mr-3"
                      onClick={download}
                      disabled={isLoading}
                    >
                      {!isLoading?<><span className="iconInline">
                        <Icon icon="download" />
                      </span>
                      <div className="ml-2">Download Shortlist</div></>:<>Downloading</>}
                    </Button>
                    <Button
                      btntype="blueBorder"
                      height="46px"
                      className="mr-3"
                      onClick={() => addSupplierToList()}
                    >
                      Add to Existing Supplier list
                    </Button>
                    <Button
                      btntype="primary"
                      height="46px"
                      onClick={() => createSupplierList()}
                    >
                      Add to New Supplier list
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </ShortlistStyled>
      </TabContent>
      <CreateSupplierDrawer
        isOpen={showCreateDrawer}
        onClose={onCloseCreateDrawer}
        listIDCompanies={listIDCompanies}
      ></CreateSupplierDrawer>
      <AddSupplierDrawer
        isOpen={showAddDrawer}
        onClose={onCloseAddDrawer}
        listCompanies={listCompanies}
        activeSupplierList={idSupplierList}
        isAdmin={isAdmin(user)}
      ></AddSupplierDrawer>
    </div>
  );
};

ShortlistContainer.defaultProps = {
  items: [],
};

const mapStateToProps = (state) => {
  return {
    searchResult: state.search.searchResult,
    isSearching: state.search.isLoading,
    groupId: state.search.groupId,
    companyId: state.auth.current_company.id,
    user: state.auth.user,
  };
};

const mapDispatchToProps = {
  getCompanies,
  removeFromList,
};

export const Shortlist = connect(mapStateToProps, mapDispatchToProps)(ShortlistContainer);