import React from 'react';

import { CompanyHeaderMediaStyled, CompanyBannerImage, CompanyHeaderLogo } from './styled';

const onImageLoadError = (currentTarget,) =>{
    currentTarget.onerror = null; // prevents looping
    currentTarget.src= "/img/placeholder-lg.jpg";
}

export const CompanyHeaderMedia = (props) => {
    return (
        <CompanyHeaderMediaStyled>
            <CompanyBannerImage>
                <source media='(min-width: 1000px)' srcSet={props.bg} />
                <source media='(max-width: 799px)' srcSet={props.bg} />
                <img src={props.bg} onError={({currentTarget})=>onImageLoadError(currentTarget)} alt='' />
            </CompanyBannerImage>
            <CompanyHeaderLogo onError={({currentTarget})=>onImageLoadError(currentTarget)} src={props.logo} alt='' />
        </CompanyHeaderMediaStyled>
    );
};
