import { CardQuestioner } from "components/RFQ/CardQuestioner";
import React from "react";
import { QuoteContent } from "./QuoteContent";

const QuoteCard = ({
  cardTitle,
  initialDataItem,
  handleChangeInput,
  cardDescription,
  value,
  dragHandle,
  tableVariable,
  index,
  quotesTable,
  setQuotesTable,
  unitMeasures,
  isPreview,
  disable,
  type = "template",
}) => {

  const isQuoteDeletable = () =>{
    const deletedQuoteTables = quotesTable?.filter((quote)=>quote?.isRemove === 1)
    return (deletedQuoteTables?.length < (quotesTable?.length-1))
  }
  const deleteQuotesHandler = (index) => {
    let newQuotes = [...quotesTable];
    if (newQuotes[index]?.id) {
      newQuotes[index].isRemove = 1;
    } else {
      newQuotes.splice(index, 1);
    }
    setQuotesTable(newQuotes);
  };

  const copyQuotesHandler = (index) => {
    let newQuotes = [...quotesTable];
    newQuotes.push({
      ...newQuotes[index],
      id: null,
      isRemove: 0,
      sort_order: newQuotes.length + 1,
    });
    setQuotesTable(newQuotes);
  };

  return (
    <div className="w-full my-6">
      <CardQuestioner
        dragHandle={dragHandle}
        divider={false}
        isQuote
        sectionIndex={index}
        deleteQuotesHandler={() => deleteQuotesHandler(index)}
        copyQuotesHandler={() => copyQuotesHandler(index)}
        isPreview={isPreview}
        isDeletable={isQuoteDeletable()}
      >
        <QuoteContent
          cardTitle={cardTitle}
          initialDataItem={initialDataItem}
          handleChangeInput={handleChangeInput}
          cardDescription={cardDescription}
          value={value}
          tableVariable={tableVariable}
          index={index}
          unitMeasures={unitMeasures}
          isPreview={isPreview}
          type={type}
          disable={disable}
        />
      </CardQuestioner>
    </div>
  );
};

export default QuoteCard;
