import { checkIsNotEmpty,checkIsValidNumber } from "utils/validation";

 // Helper functions
 export const validateReturnable = (returnable) => {
    return returnable.every(section => 
      section.question.every(question => !question.required || checkIsNotEmpty(question.rfqAnswer?.answer?.value))
    );
  };
  
 export const validateQuotes = (quotes) => {
    return quotes.every(quote => 
      quote.items.every(item => {
        const {quantity, rate, unit} = checkIsNotEmpty(item.rqfQuoteAnswer)?item.rqfQuoteAnswer?.answer?.value:{quantity:0,rate:0,unit:0};
        return checkIsValidNumber(quantity) && 
               checkIsValidNumber(rate) && 
               checkIsValidNumber(unit);
      })
    );
  };


  export const getInitialErrors = (listReturnable,listQuotes,isQuoteTypeFile,savedQuoteFile) =>{
    
    const isReturnableValid = validateReturnable(listReturnable);
    const isQuoteValid = validateQuotes(listQuotes,);

    const initialReturnableError = !isReturnableValid ? {question_answer:{}}:null;
    const quoteFileError = (!savedQuoteFile) ? {quote_file:{}} : null
    const quoteTableError = !isQuoteValid ? {quote_answer:{}} : null
    
    const initialQuoteError = isQuoteTypeFile ? quoteFileError : quoteTableError

    return {...initialReturnableError, ...initialQuoteError}
  }