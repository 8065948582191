// import { FileCard } from "components/RFQ/FileCard"

import { DEFAULT_IMAGE_BANNER } from "utils/constants";

const CardListItem = ({title,value}) =>{
    return <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid gap-8' style={{ height: '40px' }}>
    <h2 className='font-roboto text-text-primary-500 text-[14px] font-normal'>{title}</h2>
    <h2 className='font-roboto text-text-primary-900 text-[14px] font-semibold'>{value}</h2>
</div>
}

const ResourceDetailCard = ({data,sectors}) =>{

    console.log(sectors);
    

    return <div className='bg-white h-max overflow-hidden m-auto' style={{ minWidth: '435px' }}>
        
        <div className="px-6 space-y-8 py-8">
            <div className='flex flex-col justify-start items-center gap-8'>
                <div className=''>
                    <div className="rounded-full" style={{ width: '120px', height: '120px', backgroundImage: `url(${data?.avatar_location || '/img/user-01.svg'})`, backgroundSize: 'cover' }}></div>
                </div>
                <div className='font-roboto text-text-tertiary-900 capitalize font-semibold ml-4 text-center' style={{ fontSize: '20px', lineHeight: '30px' }}>
                    <p>{(data?.first_name || '' )+ '  ' + (data?.last_name || '')}</p>
                    <p>{(data?.email || '' )}</p>
                </div>
            </div>
            <div className='pt-1 pb-3'>

                <h2 className="font-bold text-primary-900 pt-8">Professional Detail</h2>
                <CardListItem title="Position" value={data?.position || "-"}/>
                <CardListItem title="Industry Experience" value={`${data?.experience || 0} years`}/>
                <CardListItem title="Total Projects" value={data?.projects_completed.length || 0}/>
                <h2 className="font-bold text-primary-900 pt-8">Primary Sectors</h2>
                <div className="flex gap-2 text-text-primary-900">
                    {data?.sectors?.map((sector)=>{
                        return <span className="bg-[#EAECF0] rounded-xl p-2">{sector.name}</span>
                    })}
                </div>

                {data?.projects_completed.length >0 && <>
                    <h2 className="font-bold text-primary-900 pt-8">Recent Project</h2>
                    <div className="flex gap-4">
                        {
                            data?.projects_completed?.map((project)=>{
                                
                    return <div className="shadow rounded">
                                <img src={DEFAULT_IMAGE_BANNER} className="rounded h-[80px] w-full" alt=""/>
                                <div className="p-4">
                                    <CardListItem title="Project Name" value={project?.name}/>
                                    <CardListItem title="Role" value={project?.role}/>
                                    <CardListItem title="Value" value={project?.value_humanized}/>
                                </div>
                            </div>
                            })
                        }
                    </div>
                </>}
                
                {/* {data?.resume?.length>0 && <>
                    <h2 className="font-bold text-primary-900 pt-8">Resume</h2>
                    <FileCard file={{name:data?.resume[1],path:data?.resume[0]}} isPreview/>
                </>} */}
            </div>
            
        </div>
    </div>
}

export default ResourceDetailCard