import { navigate, useLocation } from "@reach/router";
import { getSupplierLists, setSupplierArchived, setSupplierUnArchived } from "actions/RFQ/supplierListsActions";
import { Icon } from "assets/icons/Icon";
import { DropdownItem, DropdownMenu, DropdownMenuDrawer, DropdownMenuTrigger, MenuDivider } from "components/RFQ/MenuDropdown";
import React from "react";
import { useDispatch } from "react-redux";

const DropDownItem = ({ id, archived, noBg, currentTab }) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const redirectChecker = location.pathname.includes('supplier-list/') ? `edit-supplier-list/${id}` : `supplier-list/edit-supplier-list/${id}`;

  const archivedHandler = async () => {
    await dispatch(setSupplierArchived([id]))
      .then((data) => {
        if (data?.status) {
          dispatch(getSupplierLists({ reset: true }))
        }
      })
  }

  const unarchivedHandler = async () => {
    await dispatch(setSupplierUnArchived([id]))
      .then((data) => {
        if (data?.status) {
          dispatch(getSupplierLists({ reset: true }))
        }
      })
  }

  return (
    <div>
      <DropdownMenu >
        <DropdownMenuTrigger icon noBg={noBg} mutedIcon ></DropdownMenuTrigger>
        <DropdownMenuDrawer>
          <DropdownItem className="w-full" onClick={() => navigate(redirectChecker)}>
            <Icon icon='edit-icon' color="#667085" />
            <p className="font-roboto font-medium text-text-secondary-700" style={{ fontSize: '14px' }}>Edit{currentTab==='companyApprovedList'?" Panel List":" Supplier List"}</p>
          </DropdownItem>
          <MenuDivider />
          <DropdownItem onClick={archived ? unarchivedHandler : archivedHandler} >
            <Icon icon='archive' color="#667085" />
            <p className="font-roboto font-medium text-text-secondary-700" style={{ fontSize: '14px' }}>{archived ? 'Unarchive' : 'Archive'}</p>
          </DropdownItem >
        </DropdownMenuDrawer>
      </DropdownMenu>
    </div>
  );
};

export default DropDownItem;
