import React, { useEffect } from 'react'
import tw, { styled, css } from "twin.macro"
import { Link, useLocation } from "@reach/router"
import { Flex } from '@chakra-ui/core'
import { Button } from 'components/Button'
import { Icon } from 'assets/icons/Icon'
import { useSelector } from 'react-redux'
import { cn } from 'utils/helpers'

const hideBreadcrumbLists = [
  'edit-rfq',
  'create-rfq',
]

const BreadcrumbsContainer = styled.div(() => [
  tw`p-0 items-center `,
  css`
    .icon-container {
       ${tw`w-[16px] h-[16px] items-center content-center relative ml-[1px]`} 
    }
  `
])

export const Breadcrumbs = ({ className, ignoreCompany }) => {
  const maxCharachters = 80
  const location = useLocation();
  const companyId = useSelector((state) => state.auth.user.company_id);
  const role = localStorage.getItem('role') ? localStorage.getItem('role') : null;
  let pathName = location.pathname
  let rootUrl = `/account/${companyId}`
  if (ignoreCompany) {
    rootUrl = `/account/`
  }

  pathName = pathName.replace(rootUrl, '')
  const crumbs = pathName.split("/").filter(path => path !== "" && !Number(parseInt(path)));

  const rfqDetailPath = /request-for-quote\/[^/]+\/[^/]+$/.test(location.pathname);
  const scrollElement = window.document.getElementById('element-scroll');

  const isHidden = hideBreadcrumbLists.some((path) => location.pathname.includes(path))

  const pathFunc = (i) => {
    let lastChar = rootUrl.charAt(rootUrl.length - 1);
    if (lastChar === "/") {
      rootUrl = rootUrl.slice(0, -1)
    }
    let url = `${rootUrl}`
    for (let a = 0; a <= i; a++) {
      if(crumbs[a] === "company-profile" && i===0){
        url = `${url}/${crumbs[a]}/company-information`
      }else{
        url = url + "/" + crumbs[a]
      }
    }
    return url
  }

  const pathNameFunc = (name) => {
    let slug = name.replace(/%/g, ' ').replace(/-/g, ' ');

    if(slug.includes(['create-rfx','edit-rfx'])){
    
      return slug.split(' ')[0]+' RFx';
    }
    
    if (slug === 'request for quote') {
      return 'RFx'
    }
    if (rfqDetailPath) {
      return slug.replace(/&/g, ' | ')
    }
    else if (slug.length > maxCharachters) {
      return slug.substring(0, maxCharachters) + "..."
    } else {
      return slug
    }
  }

  useEffect(() => {
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }
  }, [location.pathname, scrollElement])

  const DisplayPath = ({ c, i }) => {
    const showLink = crumbs.length - 1 !== i;

    return <>
      <div className='text-fg-senary-300 item-center w-[16px] h-[16px] content-center relative' style={{ textAlign: '-webkit-center' }}>
        <Icon icon='chevron-right' className='icon-container' />
      </div>
      {showLink ? (
        <div className="px-2">
          <Button btntype='link' as={Link} to={pathFunc(i)} size="sm" className="!py-0 !px-0 capitalize font-roboto" active={crumbs.length - 1 === i}>
            <span style={{ color: crumbs.length - 1 === i ? '#0031DD' : 'inherit', fontSize: '14px' }} className='font-roboto font-semibold capitalize py-2 inline-flex'>{pathNameFunc(c)}</span>
          </Button>
        </div>
      ) : (
        <div className="px-2">
          <span style={{ color: 'inherit', fontSize: '14px' }} className='font-roboto font-semibold capitalize py-2 px-1 inline-flex'>{pathNameFunc(c)}</span>
        </div>
      )}
    </>
  }


  const directHome = () => {
    if (role === "supplier" || role === "guest") {
      return 'request-for-quote'
    } else {
      return 'projects'
    }
  }

  return (
    <BreadcrumbsContainer className={cn(isHidden && 'hidden', className)}>
      <Flex align={'center'} className='gap-3' >
        <Link to={directHome()} className='text-breadcrumb-icon-fg'>
          <Icon icon='dashboard' size="iconSm" style={{ width: "20px", height: "20px" }} />
        </Link>
        <Flex className="items-center justify-start h-[32px]">
          {crumbs.map((c, i) => {
            return <DisplayPath c={c} i={i}></DisplayPath>
          })}
        </Flex>
      </Flex>
    </BreadcrumbsContainer>
  )
}
