import {createContext} from 'react'
import { isProcurerNBidder } from 'utils/helpers'

export const ProfileContext = createContext('client')

const getUserProfileType = (role) =>{
    return {currentRole:role === "client"?"Procurer":"Bidder",isClientProfileActive:role === "client"}
}

export const ProfileContextProvider = ({children}) =>{


    const role = localStorage.getItem('role')
    const currentProfile=getUserProfileType(role).currentRole
    const isClientProfileActive = getUserProfileType(role).isClientProfileActive
    
    const isAccountSwitchable =isProcurerNBidder( JSON.parse(localStorage.getItem('company')))

    const changeProfileType = (role,profileType) =>{

        localStorage.setItem('role',role)
        localStorage.setItem('profile_type',profileType)
        // Replace the current history state
        window.history.replaceState(null, '', window.location.href);

        // Redirect to the new URL
        window.location.replace('/');
    }

    return <ProfileContext.Provider value={{isClientProfileActive,currentProfile,changeProfileType,isAccountSwitchable}}>
        {children}
    </ProfileContext.Provider>

}