import { Card, Flex, CircularProgress } from '@chakra-ui/react';
import { Icon } from 'assets/icons/Icon';
import React from 'react';
import { formatBytes } from 'utils/formatBytes';
import { cn, downloadFileObject, filenameSpliter } from 'utils/helpers';
import { Badge } from '../Badge';

export const FileCard = ({ file, setFiles, isPreview = false, uploadProgress, categoryTag, className }) => {
    const filename =  file instanceof File ? file?.path : file?.document || file?.name || file?.path || file?.file_name || file?.title || '-';
    const description =  file?.description
    const filesize =  file instanceof File ? formatBytes(file?.size) : !file?.file_size ? null : formatBytes(file.file_size)
    const ext = filenameSpliter(filename).ext;

    // Calculate the background color based on the upload progress
    const bgColor = uploadProgress < 50 ? 'red.50' : uploadProgress < 75 ? 'yellow.50' : 'green.50';

    return (
        <Card className={cn(`border border-gray-outline py-3 px-4 my-4 group hover:bg-utility-brand-100 hover:border-utility-brand-100 hover:text-purple group ${bgColor} min-h-[72px] justify-center`,className)} radius="sm">
            <Flex className="relative items-center gap-4 justify-between">
                <Flex className="relative items-center gap-4 justify-between">
                    {!isPreview && <Icon icon={'sixDots'} size="md" />}
                    <Icon icon={ext} size="md" />
                    <div>
                        <p className="font-semibold text-sm">{filename}</p>
                        {(filesize) && <p className="font-thin text-sm">{filesize}</p>}
                        {(description) && <p className="pt-1 text-sm">{description}</p>}
                    </div>
                </Flex>
                {categoryTag ?
                    <div className='flex items-center gap-6'>
                        <Badge size='lg' className='!text-sm !font-medium'>{categoryTag}</Badge>
                        <a  href={file?.path || ''} target='_blank' rel="noreferrer" download={filename ? 'download' : null} className={cn('flex bg-gray-200 p-2 rounded-full hover:bg-red-200', isPreview && 'hover:bg-utility-brand-200')}>
                            <Icon icon={isPreview ? "download" : "trash"} style={{ width: '18px', height: '18px',cursor:'pointer', color:'black' }} />
                        </a> 
                    </div>
                    :
                    <div>
                    {!isPreview && (
                        <div className='flex group-hover:hidden'>
                            <Icon icon="check" />
                        </div>
                    )}
                    {file?.path && !(file instanceof File) && isPreview ? <a  href={file?.path || ''} target='_blank' rel="noreferrer" download={filename ? 'download' : null} className={cn('hidden group-hover:flex bg-gray-200 p-2 rounded-full hover:bg-red-200', isPreview && 'hover:bg-utility-brand-200')}>
                            <Icon icon={isPreview ? "download" : "trash"} style={{ width: '18px', height: '18px',cursor:'pointer', color:'black' }} />
                        </a> 
                    : 
                        <div className={cn('hidden group-hover:flex bg-gray-200 p-2 rounded-full hover:bg-red-200', isPreview && 'hover:bg-utility-brand-200')} onClick={()=>isPreview ?  downloadFileObject(file) : setFiles()}>
                            <Icon icon={isPreview ? "download" : "trash"} style={{ width: '18px', height: '18px',cursor:'pointer', color:'black' }} />
                        </div>
                    }
                    {uploadProgress !== undefined && (
                        <CircularProgress value={uploadProgress} color="green.400" />
                    )}
                </div>
                }
            </Flex>
        </Card>
    );
};