import React from 'react'
import RFQStatusComp from 'components/RFQ/RFQStatusComp/RFQStatusComp'
import RfQResponseComp from 'components/RFQ/RfQResponseComp/RfQResponseComp'
import { Button } from 'components/RFQ/Button';
import moment from "moment";
import { Flex } from '@chakra-ui/core'
import { STATUS_DECLINE } from "utils/constants";
import { timezoneChecker } from 'utils/helpers';
import { Avatar } from 'components/RFQ/Avatar';
import { AsyncImage } from 'loadable-image'
import { DEFAULT_IMAGE_BANNER } from "utils/constants";
const RfqCard = ({ data, navigatePreview }) => {

    const renderButtonRFQView = (response,status) => {
        const statusName = typeof response === 'string' ? response.toUpperCase() : response;

        if (statusName === "INPROGRESS" && status.toUpperCase() !== "EVALUATION") {
            return "Edit RFx"
        } else if (statusName === "INVITED" && status.toUpperCase() !== "EVALUATION") {
            return "Accept/Decline RFx"
        } else {
            return "View RFx"
        }
    }
    
    return <div className='bg-white rounded-md card-shadow h-[405px] mt-3 mb-3 overflow-hidden mr-5 w-full' style={{ minWidth: '320px' }}>
        <div className='bg-royal-blue2 w-full h-[6px]'></div>
        <div className='pl-5 pr-5 pt-3 pb-3'>
            <div className='relative' style={{ height: '60px', }}>
                <div className="absolute w-full">
                    <AsyncImage
                        src={`${data?.rfq?.project?.backgroundImagePath}`}
                        alt="banner-card"
                        className=' h-[60px]'
                        loader={<img
                            src={DEFAULT_IMAGE_BANNER}
                            alt="banner-card"
                            style={{ filter: 'blur(10px)' }}
                        />} />
                </div>
                <div className='pt-2 px-2'>
                    <Avatar avatar={data?.rfq?.project?.logoPath} size="lg" />
                </div>
            </div>
            <div>
                <h3 className='font-roboto font-semibold text-text-primary-900 capitalize line-clamp-1 overflow-hidden text-[20px] mt-3'>{data?.rfq?.name || 'Project'}</h3>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal  mb-4'>
                    {data?.rfq?.rfxPublished
                        ? `Issued on ${moment(data?.rfq?.rfxPublished)
                            .tz(timezoneChecker(data?.rfq?.rfxPublished || 'AWST'))
                            .format("DD MMM YYYY")} `
                        : data?.rfq?.supplierList?.name || "-"}
                </div>
            </div>
            <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Client</div>
                <div className='font-roboto text-text-primary-900 text-[14px] font-semibold'>{data?.rfq?.client}</div>
            </div>
            <div className='flex justify-between items-center space-x-0  border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>RFx status</div>
                <RFQStatusComp status={data?.rfq?.status}></RFQStatusComp>
            </div>

            <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Submission Deadline</div>
                <div className='font-roboto text-text-primary-900 text-[14px] font-semibold text-end'>
                    {moment(data?.rfq?.submissionDeadline)
                        .tz(timezoneChecker(data?.rfq?.submissionTimezone || 'AWST'))
                        .format('dddd, D MMMM YYYY')
                    }
                </div>
            </div>
            <div className='flex justify-between items-center space-x-0' style={{ height: '40px' }}>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Response</div>
                <RfQResponseComp response={data?.status}></RfQResponseComp>
            </div>
            {data?.status !== STATUS_DECLINE && <Button btntype='secondary' width="100%" className="mt-5" onClick={() => navigatePreview(`${data?.rfq?.id}/documentation`)}>
                <Flex align={'center'} >
                    <p className='font-roboto font-semibold text-utility-blue-dark-100'>
                        {renderButtonRFQView(data?.status,data?.rfq?.status)}
                    </p>
                </Flex>
            </Button>}


        </div>
    </div>
}
export default RfqCard