import { removePrefixFromKeys } from "utils/helpers";
import {isArray,isFile,isSmartField} from "utils/validation"


export const initialQuoteValue = (quoteData) => {
  let quoteValues = {};
  quoteData.forEach((quote) => {
    quote.items.forEach((item) => {
      const { rqfQuoteAnswer } = item;
      quoteValues[`quote-${item.id}`] = {
        quantity: rqfQuoteAnswer?.answer?.value?.quantity || 0,
        rate: rqfQuoteAnswer?.answer?.value?.rate || 0,
        unit: item.unit?.id || null,
      };
    });
  });
  return { quote_answer: { ...quoteValues } };
};


export const initialReturnableValue = (
  returnableList,
  isGuest,
  hasDocuments,
  hasPasProjects,
  hasResources
) => {
  let questionValues = {};
  returnableList?.forEach((section) => {
    section.question?.forEach((question) => {
      if (isSmartField(question?.fields?.type)) {
        if (isGuest) {
          if (question?.fields?.type === "corporate_documents") {
            questionValues[`question-${question.id}`] = question?.rfqAnswer?.answer
              ?.value
              ? question?.rfqAnswer?.answer?.value?.map((file) => ({
                  file_name: file?.fileName || " - ",
                  file_size: file?.fileSize || null,
                  file_path: file?.filePath || null,
                }))
              : [];
          } else {
            questionValues[`question-${question.id}`] =
              question?.rfqAnswer?.answer?.value[0] || [];
          }
        } else {
          /**
           * if supplier but no documents show upload field
           */
          if (
            question?.fields?.type === "corporate_documents" &&
            !hasDocuments
          ) {
            let additional = question?.rfqAnswer?.answer?.value
              ? question?.rfqAnswer?.answer?.value?.map((file) => ({
                  file_name: file?.fileName || " - ",
                  file_size: file?.fileSize || null,
                  file_path: file?.filePath || null,
                }))
              : [];
            questionValues[`question-${question.id}`] = {
              chosen_type: "other",
              additional: additional,
            };
          } else if (
            (question?.fields?.type === "project_experience" &&
              !hasPasProjects) ||
            (question?.fields?.type === "key_personnel" && !hasResources)
          ) {
            /**
             * if supplier but no projects or resources show textarea
             */
            let additional = question?.rfqAnswer?.answer?.value || [];
            questionValues[`question-${question.id}`] = {
              chosen_type: "other",
              additional: additional,
            };
          } else {
            questionValues[`question-${question.id}`] = question?.rfqAnswer?.answer
              ?.value
              ? typeof question?.rfqAnswer?.answer?.value === "string"
                ? []
                : question?.rfqAnswer?.answer?.value.map((ans) => ans?.id).filter(ans =>  ans !== undefined)
              : [];
          }
        }
      } else if (isFile(question?.fields?.type)) {
        questionValues[`question-${question.id}`] = question?.rfqAnswer
          ? question?.rfqAnswer?.answer?.value?.map((file) => ({
              file_name: file?.fileName || " - ",
              file_size: file?.fileSize || null,
              file_path: file?.filePath || null,
            }))
          : [];
      } else if (isArray(question?.fields?.type)) {
        questionValues[`question-${question.id}`] = question?.rfqAnswer?.answer?.value
          ? question?.rfqAnswer?.answer?.value.map((ans) => ans.toString())
          : [];
      } else {
        questionValues[`question-${question.id}`] =
          question?.rfqAnswer?.answer?.value?.toString()
            ? question?.rfqAnswer?.answer?.value.toString()
            : null;
      }
    });
  });
  return {
    question_answer: { ...questionValues },
  };
};

export const valuesPayload = (values, returnableList, isGuest) => {
  let returnablePayload = removePrefixFromKeys({ ...values?.question_answer },"question-");
  let quotePayload = removePrefixFromKeys({ ...values?.quote_answer },'quote-');
  const quote_file = values?.quote_file
  
  returnableList?.forEach((section) => {
    section.question?.forEach((question) => {
      const value = returnablePayload[question.id];
      if (
        value === "" &&
        (question?.fields?.type === "text" ||
          question?.fields?.type === "textarea")
      ) {
        returnablePayload[question.id] = "<p></p>";
      }
      if (Array.isArray(value)) {
        value.forEach((val, i) => {
          if (
            typeof val === "object" &&
            !Array.isArray(val) &&
            val !== null &&
            !(val instanceof File)
          ) {
            returnablePayload[question.id][i] = val?.file_name;
          }
        });
      }
      if (Array.isArray(value) && value?.length === 0) {
        returnablePayload[question.id] = { isRemove: 1 };
      }
      if (
        isGuest &&
        isSmartField(question?.fields?.type) &&
        question?.fields?.type !== "corporate_documents"
      ) {
        returnablePayload[question.id] = [value];
      }
    });
  });

  for (const quote in quotePayload) {
    const quoteValue = quotePayload[quote];
    quotePayload[quote].rate = quoteValue.rate || 0;
    quotePayload[quote].quantity = quoteValue.quantity || 0;
    quotePayload[quote].unit = quoteValue.unit || 1;
  }


  const quote_response = quote_file?{quote_file:quote_file[0]}:{'quote_answer':quotePayload}

  return{
    question_answer:returnablePayload,
    ...quote_response
  }
  
};

export const setAllFieldTouched = (errors) =>{


  for(const key in errors){
    if(typeof errors[key] !== 'object'){
      errors[key] = true
    }else{
      for(const k in errors[key]){
        if(key !== "quote_answer" && key !=='sections'){
          setValueOfObjectToDefault(errors[key],true)
        }else{
          setValueOfObjectToDefault(errors[key][k],true)
        }
        
      }
    }
  }
  
  
  return errors
}


export const touchFieldReturnable = (returnableList) => {
  const returnableSchedules = {};
  returnableList?.forEach((section) => {
    section.question?.forEach((question) => {
      returnableSchedules[`question-${question.id}`] = true;
    });
  });
  return returnableSchedules;
};

const setValueOfObjectToDefault = (object,defaultValue) =>{
  for(let key in object){
    object[key] = defaultValue
  }

  return object;
}