import { Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { STATUS_DRAFT, STATUS_EVALUATION } from "utils/constants";

const { navigate } = require("@reach/router");
const { createRfq } = require("actions/RFQ/rfqsAction");
const { Button } = require("components/RFQ/Button");

export const RFQButtonActions = ({ project, supplierList }) => {
    const [loadCreatingRfq, setLoadCreatingRfq] = useState(false);
    const rfqId = supplierList?.relateRfq?.rfqId || null;
    const isHaveRFQ = rfqId !== null;
    const statusName = typeof supplierList?.relateRfq?.rfqStatus === 'string' ? supplierList?.relateRfq?.rfqStatus.toUpperCase() : supplierList?.relateRfq?.rfqStatus;
    const isDraft = statusName === STATUS_DRAFT;
    const isPastDeadline = statusName === STATUS_EVALUATION;

    const fullNameRFQWithSlug = project?.name
    const slug = fullNameRFQWithSlug?.toLowerCase().replace(/ /g, "-") + "";

    const renderButtonText = () => {
        if (!isHaveRFQ) return 'Create RFx';
        if (!isPastDeadline && !isDraft) return 'View RFx';
        if (isPastDeadline) return 'View responses';
        if (isDraft) return 'View Draft';
    }

    const handleClick = () => {
        if (!isHaveRFQ) {
            setLoadCreatingRfq(true);
            const redirectUrl = (id) => `../../request-for-quote/create-rfx/${id}`
            const data = { project_id: project.id, supplier_list_id: supplierList.id }
            createRfq(data).then((res) => {
                if (res) {
                    navigate(redirectUrl(res.id), { state: { rfqData: res } });
                    window.scrollTo(0, 0);
                    setLoadCreatingRfq(false);
                }
            }).catch((err) => {
                setLoadCreatingRfq(false);
                console.log(err);
            });
        } else if (isDraft) {
            navigate(`../../request-for-quote/edit-rfx/${rfqId}`)
            window.scrollTo(0, 0);
        } else {
            navigate(`../../request-for-quote/${rfqId}/${slug}`)
            window.scrollTo(0, 0);
        }

    }

    return (
        <Button btntype={isHaveRFQ && 'secondary'} onClick={handleClick} disabled={loadCreatingRfq} style={{ width: "141px !important" }} className="mx-auto">
            {
                loadCreatingRfq ? <Spinner /> : <div style={{ fontSize: '14px', lineHeight: "20px" }} className="font-roboto font-semibold">
                    {renderButtonText()}
                </div>
            }
        </Button>
    )
};