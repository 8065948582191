import { Box, Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import ReturnableSchedulesCard from "components/Pages/CreateRFQ/ReturnableSchedulesCard/ReturnableSchedulesCard";
import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AddSectionModal } from "components/Pages/CreateRFQ/AddSectionModal";
import { reorderList } from "utils/reorderList";
import { useField } from "formik";
import { Heading } from "components/Heading";
import { Button } from "components/Button";
import { getSortedReturnables } from "./ReturnableSchedulesHelpers";

const ProjectDetailContainer = styled.div(() => [tw`flex flex-col w-full`]);

const ButtonAddContent = ({
  onOpen,
  setModalType,
  addSectionHandler,
  disableAddQuestion,
}) => {
  const onClickAddHandler = (type) => {
    setModalType(type);
    onOpen();
  };

  const onKeyDown = (event, menu) => {
    if (event.key === "Enter") {
      if (menu) {
        event.preventDefault();
        onClickAddHandler(menu);
      } else {
        addSectionHandler();
      }
    }
  };

  return (
    <Box className="flex-col z-40 card-shadow bg-white rounded-md p-2">
      <Button
        type="button"
        btntype="link"
        className={`!w-full !flex !justify-start relative gap-2 items-center hover:bg-utility-blue-light-50 cursor-pointer p-3 hover:rounded-md transition-all [transition-duration: 0.18s] ${disableAddQuestion && "pointer-events-none opacity-50"}`}
        onClick={() => onClickAddHandler("question")}
        onKeyDown={(event) => onKeyDown(event, "question")}
      >
        <Icon icon="plus-circle" style={{ color: "#475467" }} />
        <p
          className="font-roboto font-medium text-text-secondary-700"
          style={{ fontSize: "14px", marginTop: "1px" }}
        >
          Add Question
        </p>
      </Button>
      <Button
        type="button"
        btntype="link"
        className="!w-full !flex !justify-start relative gap-2 items-center hover:bg-utility-blue-light-50 cursor-pointer p-3 hover:rounded-md transition-all [transition-duration: 0.18s]"
        onClick={() => addSectionHandler()}
        onKeyDown={(event) => onKeyDown(event)}
      >
        <Icon icon="section" style={{ color: "#475467" }} />
        <p
          className="font-roboto font-medium text-text-secondary-700"
          style={{ fontSize: "14px", marginTop: "1px" }}
        >
          Add Section
        </p>
      </Button>
    </Box>
  );
};

const QuestionerContainer = styled.div(({ isDisabled }) => [
  !isDisabled ? tw`opacity-100` : tw`opacity-50 pointer-events-none`,
]);

const ReturnableSchedules = ({ titlePage, subTitlePage }) => {
  const isLoading = false;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState("section");

  const [, meta, helper] = useField(`sections`);
  const sectionsValue = meta.value;

  const addSectionHandler = () => {
    const newSection = {
      id: null,
      name: "",
      description: "",
      questionnaire: [
        {
          id: null,
          question: "",
          rfq_question_type_id: 2,
          required: 1,
          fields: [""],
          name: "",
          sort_order: 1,
        },
      ],
      sort_order: meta.value?.length + 1,
    };

    helper.setValue(getSortedReturnables([...meta.value, newSection]));
  };

  const deleteSectionsHandler = (index) => {
    const formValue = meta.value[index];
    if (formValue.id) {
      let newSections = [...meta.value];
      let deletedQuestion = newSections[index].questionnaire.map(
        (question) => ({ ...question, name: "deleted", isRemove: 1 }),
      );
      newSections[index] = {
        ...newSections[index],
        name: "deleted",
        description: "deleted",
        questionnaire: deletedQuestion,
        isRemove: 1,
      };
      helper.setValue(getSortedReturnables(newSections));
      typeof meta.error !== "string" && helper.setError(undefined);
    } else {
      helper.setValue(meta.value.filter((_, i) => i !== index));
      typeof meta.error !== "string" && helper.setError(undefined);
    }
  };

  const copySectionsHandler = (index) => {
    const formSection = { ...meta.value[index], id: null };
    helper.setValue(getSortedReturnables([...meta.value, formSection]));
    const sectionLastIndex = meta.value.length || 0;
    setTimeout(() => {
      const cardElement = document.getElementById(
        `section-container-${sectionLastIndex}`,
      );
      cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 200);
  };

  const addQuestionHandler = (sectionIndex) => {
    const newQuestion = {
      id: null,
      question: "",
      rfq_question_type_id: 2,
      required: 1,
      fields: [],
      name: "",
      sort_order: sectionsValue[sectionIndex]?.questionnaire
        ? sectionsValue[sectionIndex]?.questionnaire?.length + 1
        : 1,
      uuid: Math.floor(1000 + Math.random() * 9000),
    };

    let oldValue = [...meta.value];
    oldValue[sectionIndex].questionnaire.push(newQuestion);
    helper.setValue(getSortedReturnables(oldValue));
  };

  const sectionTotalChecker = () => {
    let total = 0;
    for (const section of sectionsValue) {
      if (!section?.isRemove) {
        total++;
      }
    }
    return total;
  };
  const dragSectionHandler = (result) => {
    let value = sectionsValue;
    let valueSetter = (newValue) => (value = newValue);
    reorderList(value, result, valueSetter);
    const reordered = value.map((item, index) => ({
      ...item,
      sort_order: index + 1,
    }));
    helper.setValue(reordered);
  };

  return (
    <ProjectDetailContainer>
      <div id="subheader-page" className="py-8">
        <div
          id="subheader-page-title"
          className="text-xl font-semibold text-text-primary-900"
        >
          <Heading fontSize={"36px"} color="#003CE9">
            {" "}
            {titlePage}
          </Heading>
        </div>
        <div
          id="subheader-page-subtitle"
          className="font-roboto text-sm text-text-tertiary-600 mt-1"
        >
          {subTitlePage}
        </div>
      </div>
      <div id="project-detail-content" className="relative">
        {!isLoading ? (
          <>
            <QuestionerContainer>
              <DragDropContext onDragEnd={dragSectionHandler} className="my-10">
                <Droppable
                  droppableId="questioner-container"
                  direction="vertical"
                >
                  {(provided) => (
                    <div
                      id="questioner-container"
                      className="mb-8"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {sectionsValue?.map((section, index) =>
                        !section.isRemove ? (
                          <>
                            <Draggable
                              key={`section-${index}`}
                              draggableId={`section-${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <ReturnableSchedulesCard
                                    index={index}
                                    dragHandle={provided.dragHandleProps}
                                    section={section}
                                    deleteSectionsHandler={
                                      deleteSectionsHandler
                                    }
                                    copySectionsHandler={copySectionsHandler}
                                    sectionList={meta.value}
                                  />
                                </div>
                              )}
                            </Draggable>
                            {sectionsValue.length !== index + 1 ? (
                              <Flex className="items-center my-10">
                                <div className="border-gray-200 border-b text-gray-200 w-full" />
                                <div className="text-lg font-bold text-text-primary-900 w-[300px] mx-2 text-center font-roboto">
                                  Section break
                                </div>
                                <div className="border-gray-200 border-b text-gray-200 w-full" />
                              </Flex>
                            ) : null}
                          </>
                        ) : null,
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Flex className="-mt-1">
                <ButtonAddContent
                  onOpen={onOpen}
                  setModalType={setModalType}
                  addSectionHandler={addSectionHandler}
                  disableAddQuestion={!sectionTotalChecker()}
                />
              </Flex>
              <AddSectionModal
                modalType={modalType}
                sections={sectionsValue}
                type={modalType}
                isOpen={isOpen}
                onClose={onClose}
                addSectionHandler={addSectionHandler}
                addQuestionHandler={addQuestionHandler}
              />
            </QuestionerContainer>
          </>
        ) : (
          <Spinner
            className="absolute left-1/2 -translate-x-1/2 top-[30vh] "
            size="xl"
          />
        )}
      </div>
    </ProjectDetailContainer>
  );
};

export default ReturnableSchedules;
