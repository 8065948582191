import { Flex, useDisclosure } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import React from "react";
import tw, { styled } from "twin.macro";
import { ToggleButton } from "../ToggleButton";
import ModalDeleteSection from "components/Pages/CreateRFQ/ModalDeleteSection/ModalDeleteSection";
import { useField } from "formik";
import { cn } from "utils/helpers";

const CardQuestionerStyled = styled.div(
  ({ className, isSection, isPreview, isQuote }) => [
    tw`w-full flex flex-col bg-white pt-12 pb-0.5 px-8 h-full min-h-20 rounded-md border border-gray-200 relative mb-8 focus-within:ring-4 focus-within:ring-royal-blue focus-within:ring-opacity-30`,
    isSection ? tw`border-t-8 border-t-royal-blue` : null,
    (isPreview || isQuote) && tw`pt-3`,
    className,
  ]
);

const CardQuestioner = ({
  children,
  isSection = false,
  isSmartField,
  dragHandle,
  divider = true,
  className,
  index,
  sectionIndex,
  copyQuestionHandler,
  deleteQuestionHandler,
  copySectionHandler,
  deleteSectionHandler,
  copyQuotesHandler,
  deleteQuotesHandler,
  isQuote,
  label,
  isPreview = false,
  isDeletable,
  ...props
}) => {
  const { isOpen, onClose } = useDisclosure();

  const fieldname = () =>
    isQuote
      ? `quote[${sectionIndex}]`
      : isSection
        ? `sections[${sectionIndex}]`
        : `sections[${sectionIndex}]questionnaire[${index}]`;
  const [, meta, helper] = useField(fieldname());

  const setRequiredHandler = () => {
    helper.setValue({
      ...meta.value,
      required: isQuote ? 1 : meta.value.required ? 0 : 1,
    });
  };

  return (
    <>
      <CardQuestionerStyled
        className={`${className || ""} card-shadow`}
        isQuote={isQuote}
        isSection={isSection}
        isPreview={isPreview}
        {...props}
      >
        {label && (
          <h4 className="flex gap-1 text-xl font-bold text-text-primary-700 absolute left-8 top-4 font-roboto">
            {label} <div className="text-required font-roboto font-medium">
            {" "}
            <span>*</span>
          </div>
          </h4>
        )}
        {!isPreview && (
          <div
            className="absolute left-1/2 -translate-x-1/2 top-4"
            {...dragHandle}
          >
            <Icon
              icon="drag-icon"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                cursor: "grab",
              }}
            />
          </div>
        )}
        <div className="my-3">{children}</div>
        {divider && <div className="border-gray-200 border-b text-gray-200 w-full" />}
        {(!isPreview) ?
                <div className={cn("flex-grow min-h-16", isSection && 'min-h-[52px]')}>
                <Flex gap={5} align={"center"} className={`relative float-right ${isSection ? 'my-4' : 'my-2'}`}>
                  <Icon
                    icon="copy-icon"
                    style={{ cursor: "pointer" }}
                    onClick={isQuote ? copyQuotesHandler : isSection ? copySectionHandler : copyQuestionHandler}
                  />
                  {isDeletable && <Icon
                    icon="trash-stroke"
                    style={{ cursor: "pointer" }}
                    onClick={isQuote? deleteQuotesHandler :isSection ? deleteSectionHandler : deleteQuestionHandler}
                  />}
                  {!isQuote && <>
                    {(!isSection || isSmartField) && (
                    <div className="p-3 border-l pr-0">
                      {" "}
                      <ToggleButton
                        label={"Required"}
                        onChange={setRequiredHandler}
                        checked={meta?.value?.required}
                      />{" "}
                    </div>
                  )}
                  </>}
                </Flex>
              </div>:<></>
        }

      </CardQuestionerStyled>

      <ModalDeleteSection isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default CardQuestioner;
