import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

import { ProjectForm } from './ProjectForm';
import { addPastProject } from '../../actions/companyActions';
import { getTradeValues } from "actions/companyActions";
import { showToast } from 'utils/helpers';

export const AddProjectComponent = ({ profileTypeId, pastProjects, ...props }) => {
    const initialValues = props.initialValues;
    const [errorStatus, setErrorStatus] = useState(false);

    useEffect(() => {
        getTradeValues();
    },[])
    const handleSubmit = async (values, { setErrors }) => {
        try {
            await props.addPastProject(values);
            navigate("../projects")
            showToast('Project added successfully.', 'Success', true);
        } catch (e) {
            const error = await e.response.json();
            setErrors(error.errors);
            setErrorStatus(true);
            showToast(error.message, 'Error');
        }
    };

    return <ProjectForm initialValues={initialValues} handleSubmit={handleSubmit} profileTypeId={profileTypeId} errorStatus={errorStatus} setErrorStatus={setErrorStatus}/>;
};

const mapStateToProps = (state, profileTypeId) => {
    const { activeCompany } = state.search;

    return {
        companyId: activeCompany.id,
        initialValues: {
            company_id: activeCompany.id,
            name: '',
            location: '',
            summary: '',
            value: activeCompany.profile_type.id === 3?'0':'',
            year_completed: '',
            funding_type: '',
            site_area: '',
            gross_floor_area: '',
            client_name: '',
            head_contractor: '',
            sector_id: '',
            scope: [],
            images: [],
            innovation_value_summary: '',
            awards: '',
            testimonials: '',
            status: '',
            procurement_method_ids: [],
            trade_ids: [],
            trade_value:''

        }
    };
};

export const AddProject = connect(mapStateToProps, { addPastProject, getTradeValues })(AddProjectComponent);
