import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
} from "@chakra-ui/react";
import { CloseButton } from 'components/RFQ/CloseButton';
import { useFormikContext } from 'formik';
import PreviewRFQComponent from 'components/PreviewRFQComponent/PreviewRFQComponent';
import { mapFromRFQFormToRFQData } from 'utils/mapper';


const PreviewRFQ = ({ isOpen, rfqData, onClose,unitMeasures,documentCategories, ...props }) => {
    const {values:formValues} = useFormikContext();

    return (
        <div>
            <Drawer
                placement={"right"}
                isOpen={isOpen}
                onClose={onClose}
                size="xl"
                {...props}
                className="bg-white p-11"
            >
                <DrawerOverlay onClick={onClose} />
                <DrawerContent maxWidth={'1280px'}>
                    <DrawerHeader>
                        <Flex justifyContent="space-between" align={'center'}>
                            <div>
                                <p className="text-lg text-text-primary-900 font-semibold">RFx Preview</p>
                            </div>
                            <CloseButton onClose={onClose} />
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody >
                        <PreviewRFQComponent rfqData={rfqData} rfqDetail={mapFromRFQFormToRFQData(formValues,rfqData,props?.rfqTypes)} documentCategories={documentCategories} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    )
}

export default PreviewRFQ
