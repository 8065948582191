import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { ContentContainer } from "../components/Layout";
import { ContextMenu } from "../components/ContextMenu";
import { getCompany, getDocuments, getPastProjects, getProcurementMethods, getResources } from "actions/companyActions";
//Routes
import { MyAccount } from "../routes/account/MyAccount";
import { Breadcrumbs } from "components/Breadcrumb";
import RFQPreview from "pages/RFQSubmission/RFQPreview/RFQPreview";
import RfqOverview from "pages/SupplierFlow/RfqOverviews/RfqOverviews";
import { NotFound } from "./NotFound";
import CompanyProfileV2 from "./account/companyProfile/CompanyProfileV2";
import AccountSettings from "./accountsettings/AccountSettings";
import { AddResource } from "components/ResourceForm/AddResource";
import { EditResource } from "components/ResourceForm/EditResource";
import { AddProject } from "components/ProjectForm/AddProject";
import { EditProject } from "components/ProjectForm/EditProject";
import { DocumentRequest } from "components/DocumentRequest";
import { EditUserProfile } from "components/EditUserProfile";
import { UpdateCreditCard } from "components/CreditCardDetail/CreditCardDetail";



const AccountPageWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  ${ContentContainer} {
    /* margin-left: 320px; */
  }
`;

export const SupplierComponent = ({
  company,
  getCompany,
  getPastProjects,
  getResources,
  getDocuments,
  loading,
  companyId,
  getProcurementMethods,
  getCompanySizeOptions,
  ...props
}) => {
  useEffect(() => {
    //Set this company ID from the route variable - this allows anyone to access/edit all companies
    //When auth system is implemented, the getCompany action will be dispatched during login action,
    //and this data will be ready in the store
    if(companyId && !company?.id){
      getCompany(companyId);
      getPastProjects(companyId);
      getResources(companyId);
      getDocuments(companyId);
      getProcurementMethods();
    }
  }, [getCompany, getPastProjects, getDocuments, companyId,getResources,company,getProcurementMethods]);

  return (
    <>
      {!loading ? (
        <AccountPageWrap>
          <ContextMenu name={company.name} logo={company.logo_path} />
          <ContentContainer id='element-scroll' className="px-1.5">
            <Breadcrumbs ignoreCompany={false}/>
            <Router primary={false}>
              <MyAccount path="my-account" />
              <RfqOverview path="/request-for-quote" />
              {/* <RFQPreview path="/request-for-quote/:id" /> */}
              <RFQPreview path="/request-for-quote/:id/:currentTab" />
              {/* <CompanyProfile path="company-profile/*" company={company} /> */}
              {/* Existing Route */}
              <EditUserProfile path="company-profile/edit-user-profile" company={"My Profile"} />
              <CompanyProfileV2 path="company-profile/:activeTab" company={company} />
              <AccountSettings path="account-settings" company={company} />
              <UpdateCreditCard path="account-settings/update-card-details" />
              <AddResource path="company-profile/:activeTab/new-resource" />
              <EditResource
                path="company-profile/:activeTab/edit-resource/:resourceId"
              />
              <AddProject path="company-profile/:activeTab/create-project" profileTypeId={company?.profile_type?.id} />
              <EditProject
                path="company-profile/:activeTab/edit-project/:projectId"
                profileTypeId={company?.profile_type?.id}
              />
              <DocumentRequest path="company-profile/:activeTab/request-view" />

              <NotFound default />
            </Router>
          </ContentContainer>
        </AccountPageWrap>
      ) : (
        <div style={{ padding: 50, flexGrow: 1 }}>
          <Skeleton duration={0.5} count={10} width="100%" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.search.activeCompany,
    loading: state.search.isLoading,
  };
};

export const Supplier = connect(mapStateToProps, {
  getCompany,
  getPastProjects,
  getResources,
  getDocuments,
  getProcurementMethods
})(SupplierComponent);
