import tw, { styled, css } from "twin.macro";
import { cn } from "utils/helpers";
import { useField, useFormikContext } from "formik";
import { useState } from "react";
import InputDropdown from "../InputDropdown/InputDropdown";
import { Checkbox } from "../Checkbox";
import { Icon } from "assets/icons/Icon";
import { RadioInput } from "../RadioInput";
import LabelForm from "../LabelForm/LabelForm";

const sizes = {
  sm: tw`px-2.5 py-1 max-h-10`,
  default: tw`p-4`,
  md: tw`px-[14px] py-[10px] min-h-[44px]`,
  lg: tw`px-3.5 py-2.5`,
};

const colors = {
  default: tw`bg-white focus-within:border-utility-brand-300 focus-within:ring-4 ring-ring-blue-primary`,
  error: tw`bg-white focus-within:border-utility-error-300 focus-within:ring-4 !ring-ring-red-primary`,
};

const InputContainerStyled = styled.div(({ className }) => [
  tw`disabled:bg-gray-200 disabled:bg-opacity-25 disabled:pointer-events-none`,
  css`
    .font-medium {
      font-weight: 600;
      font-size: 14px;
    }
  `,
  className,
]);

export const InputBox = styled.div(
  ({
    size = "default",
    color = "default",
    visible,
    value,
    type,
    className,
    disabled,
  }) => [
      sizes[size],
      colors[color],
      tw`w-full
        overflow-hidden relative flex gap-1.5 items-center
        border border-gray-300 rounded-md`,
      css`
      input {
        outline: none;
        border: none !important;
      }
      svg {
        ${value ? tw`text-gray-500` : tw`text-gray-400`};
      }
    `,
      visible && tw`overflow-visible`,
      type === "textarea" && tw`h-auto`,
      color === "error" && tw`border border-utility-error-500`,
      disabled && tw`bg-gray-300 bg-opacity-25 pointer-events-none`,
      className,
    ]
);

export const InputStyled = styled.input(({ tooltip, className }) => [
  tw`outline-none w-full pr-6 !border-none placeholder:text-text-primary-300 disabled:bg-transparent`,
  !tooltip && tw`pr-2`,
  className,
]);

export const TextAreaStyled = styled.textarea(() => [
  tw`outline-none placeholder:text-text-primary-300 disabled:bg-transparent`,
]);

const InputField = ({
  icon,
  size = "default",
  color = "default",
  classNameBoxInput,
  ...props
}) => {
  const isTextarea = props?.type === "textarea";
  return (
    <InputBox
      disabled={props?.disabled}
      className={classNameBoxInput}
      size={size}
      type={props?.type}
      color={props?.error && props?.touched ? "error" : color}
      value={props?.value}
    >
      {icon && <Icon icon={icon} />}
      {isTextarea ? (
        <TextAreaStyled
          {...props}
          className="w-full min-h-20 focusable"
        />
      ) : (
        <InputStyled
          tooltip={props.tooltip}
          {...props}
        />
      )}
      {props?.tooltip && (
        <Icon
          color={color === "error" && "#F04438"}
          icon="help"
          sx={{
            position: "absolute",
            right: "12px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
      )}
    </InputBox>
  );
};

const InputPasswordField = ({
  icon,
  size = "default",
  color = "default",
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputBox
      size={size}
      color={color}
    >
      {icon && <Icon icon={icon} />}
      <InputStyled
        tooltip={true}
        type={showPassword ? "text" : "password"}
        className="focusable"
        {...props}
      />
      {
        <Icon
          icon={showPassword ? "eye-close" : "eye"}
          onClick={() => setShowPassword(!showPassword)}
          className="cursor-pointer text-gray-500"
        />
      }
      {props.tooltip && (
        <Icon
          color={color === "error" && "#F04438"}
          icon="help"
          sx={{
            position: "absolute",
            right: "12px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
      )}
    </InputBox>
  );
};

const renderField = (type, props, noborder, setFieldTouched) => {
  switch (type) {
    case "text":
      return (
        <InputField
          type="text"
          {...props}
        />
      );

    case "number":
      return (
        <InputField
          type="number"
          {...props}
        />
      );

    case "email":
      return (
        <InputField
          type="email"
          {...props}
        />
      );

    case "textArea":
      return (
        <InputField
          type="textarea"
          {...props}
        />
      );

    case "cardnumber":
      return (
        <InputField
          icon="visa"
          {...props}
        />
      );

    case "password":
      return <InputPasswordField {...props} />;
    case "select":
      return (
        <InputDropdown
          setFieldTouched={setFieldTouched}
          type="default"
          {...props}
          isDisabled={props?.disabled ?? false}
          noborder={noborder !== undefined ? noborder : false}
        />
      );
    case "checkbox":
      return <Checkbox {...props} />;
    case "radio":
      return <RadioInput {...props} />;
    default:
      return null;
  }
};

export const Input = ({
  hint,
  label,
  required,
  type = "text",
  className,
  error,
  ...props
}) => {
  return (
    <InputContainerStyled className={cn("w-full space-y-1.5", className)}>
      {label && (
        <LabelForm
          label={label}
          required={required}
        ></LabelForm>
      )}
      {renderField(type, { ...props, label })}
      {error && (
        <p className="text-sm text-utility-error-500 mt-1 font-roboto">
          {error}
        </p>
      )}
      {hint && (
        <p
          className={cn(
            props?.color === "error" && "text-utility-error-500",
            "text-sm"
          )}
        >
          {hint}
        </p>
      )}
    </InputContainerStyled>
  );
};

export const FieldForm = ({
  hint,
  label,
  required,
  type = "text",
  className,
  noborder,
  onClick,
  ...props
}) => {
  const [field, { error, touched, meta }, { setValue }] = useField(props);
  const allProps = { ...field, ...props, error, touched, setValue };
  const { setFieldTouched } = useFormikContext();
  if (noborder === undefined) {
    noborder = false;
  }

  return (
    <div
      className={cn("w-full", className)}
      onClick={onClick}
    >
      {type !== "checkbox" && label && (
        <LabelForm
          label={label}
          required={required}
        ></LabelForm>
      )}
      {type !== "checkbox"
        ? renderField(type, allProps, noborder, setFieldTouched)
        : renderField(type, { ...allProps, label }, false, setFieldTouched)}
      {error && touched && !props?.hideError && (
        <p className="text-sm text-utility-error-500 mt-1 font-roboto">
          {error}
        </p>
      )}
      {hint && (
        <p
          className={cn(
            props?.color === "error" && "text-utility-error-500",
            "text-sm my-2 font-roboto"
          )}
        >
          {hint}
        </p>
      )}
      {type === "select" && (
        <>
          {meta?.touched && meta?.error && (
            <p
              className={cn(
                props?.color === "error" && "text-utility-error-500",
                "text-sm my-2 font-roboto"
              )}
            >
              {hint}
            </p>
          )}
        </>
      )}
    </div>
  );
};
