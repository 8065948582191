import { Card, Flex,SkeletonCircle } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";

import { Button } from "components/RFQ/Button";
import { SkeletonBase } from "components/Skeleton";
import { SubmissionSmartFieldPreviewContext } from "context/RFQSubmittedResponseContext";
import { useContext } from "react";
import { basePathAuth, cn, filenameSpliter } from "utils/helpers";

export const ProjectCardSkeleton = ({rfqSubmission}) => {
    return <div className='bg-gray-100 animate-pulse rounded-md mt-3 mb-3 mr-5 overflow-hidden pb-6' style={{ minWidth: '401px' }}>
    <SkeletonBase width="100%" height="145px" />
    <div className="py-2 px-6 space-y-6">
        
        <div className='flex gap-3 justify-start items-center mt-10 mb-3'>
            <SkeletonBase width="160px" />
        </div>
        <div className='pt-3 pb-2 space-y-4'>
            <SkeletonBase width="100%" />
            <SkeletonBase width="100%" />
            <SkeletonBase width="100%" />
            <SkeletonBase width="100%" />
        </div>
        {rfqSubmission && <SkeletonBase width="100%" height="46px" />}
    </div>
</div>
}

export const ProjectCard = ({ values, rfqSubmission,selected ,selectHandler, companyId, isPreview }) => {

    const submissionSmartFieldContext = useContext(SubmissionSmartFieldPreviewContext)
    function formatCurrency(value) {
        if (value >= 1e6) {
            return (value / 1e6).toFixed(1) + 'M';
        } else if (value >= 1e3) {
            return (value / 1e3).toFixed(1) + 'K';
        } else if (value > 0){
            return value.toFixed(1);
        }else{
            return " - "
        }
    }

    return <div className='bg-white rounded-md shadow-md h-max mt-3 mb-3 mr-5 pb-3 overflow-hidden' style={{ minWidth: '401px' }}>
        <div className='flex justify-between items-center relative' style={{ height: '145px' }}>
            <div className='bg-royal-blue2 w-full h-[6px] absolute top-0 left-0'></div>
            <div className="bg-cover" style={{ width: '100%', height: '145px', backgroundImage: `url(${values?.images?.length > 0 && values?.images[0]})`}}></div>
        </div>
        <div className='pl-5 pr-5 pt-3 pb-3 space-y-6'>
            <div>
                <h3 className='font-roboto font-semibold text-text-primary-900 capitalize line-clamp-1 overflow-hidden text-[20px] mt-8 '>{values?.name || " - "}</h3>
            </div>
            <div>
                <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                    <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Construction Value</div>
                    <div className='font-roboto text-text-primary-900 text-[14px] font-semibold'>${formatCurrency(values?.value)}</div>
                </div>
                <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                    <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Completion</div>
                    <div className='font-roboto text-text-primary-900 text-[14px] font-semibold'>{values?.year_completed || ' - '}</div>
                </div>
                <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                    <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Client Name</div>
                    <div className='font-roboto text-text-primary-900 text-[14px] font-semibold'>{values?.client_name || ' - '}</div>
                </div>
                <div className='flex justify-between items-center space-x-0 ' style={{ height: '40px' }}>
                    <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Contract Type</div>
                    <div className='font-roboto text-text-primary-900 text-[14px] font-semibold'>{values?.procurement_or_contract_type || ' - '}</div>
                </div>
            </div>
            <div className="flex gap-3">
                {rfqSubmission && <Button type='button' btntype={selected ? 'primary' : 'secondary'} className='!w-full gap-1.5 items-center' onClick={() => selectHandler(values?.id)}>
                    {selected? 'Selected' : 'Select'}{selected && <Icon icon='check-only' width={20} height={20} />}
                </Button>}
                {rfqSubmission && <Button 
                    as='a' 
                    btntype='secondary' 
                    className='!w-full' 
                    href={`${basePathAuth(companyId)}company-profile/projects/edit-project/${values?.id}`} 
                    target='_blank'
                >
                    Open Project
                </Button>}

                {isPreview && <Button 
                    btntype='secondary' 
                    className='!w-full' 
                    onClick={()=>{
                        submissionSmartFieldContext?.setModalDetail({data:{...values},type:"project"})
                        submissionSmartFieldContext?.setIsModalOpen(true)
                    }}
                >
                    View Project
                </Button>}
            </div>
        </div>
    </div>
}

export const KeyPersonnelSkeleton = ({rfqSubmission}) => {
    return <div className='bg-gray-100 animate-pulse rounded-md mt-3 mb-3 mr-5 overflow-hidden pb-6' style={{ minWidth: '401px' }}>
    <div className="py-2 px-6 space-y-6">
        <div className='flex gap-3 justify-start items-center mt-5 mb-3'>
            <SkeletonCircle size='56px' startColor="lightgray" />
            <SkeletonBase width="160px" />
        </div>
        <div className='pt-3 pb-3 space-y-4'>
            <SkeletonBase width="100%" />
            <SkeletonBase width="100%" />
            <SkeletonBase width="100%" />
        </div>
        {rfqSubmission && <SkeletonBase width="100%" height="46px" />}
    </div>
</div>
}

export const KeyPersonnel = ({values,rfqSubmission,selected,selectHandler,companyId, isPreview}) => {
    
    const submissionSmartFieldContext = useContext(SubmissionSmartFieldPreviewContext)

    return <div className='bg-white rounded-md shadow-md h-max mt-3 mb-3 mr-5 overflow-hidden pb-6' style={{ minWidth: '435px' }}>
        <div className='flex justify-between items-center relative' style={{ height: '6px' }}>
                <div className='bg-royal-blue2 w-full h-[6px] absolute top-0 left-0'></div>
            </div>
        <div className="px-6 space-y-6">
            <div className='flex justify-start items-center mt-5 mb-3'>
                <div className=''>
                    <div className="rounded-full" style={{ width: '56px', height: '56px', backgroundImage: `url(${values?.avatar_location || '/img/user-01.svg'})`, backgroundSize: 'cover' }}></div>
                </div>
                <div className='font-roboto text-text-tertiary-900 capitalize font-semibold ml-4' style={{ fontSize: '20px', lineHeight: '30px' }}>
                    {(values?.first_name || '' )+ '  ' + (values?.last_name || '')}
                </div>
            </div>
            <div className='pt-1 pb-3'>
                <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                    <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Position</div>
                    <div className='font-roboto text-text-primary-900 text-[14px] font-semibold'>{values?.position || ' - '}</div>
                </div>
                <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                    <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Industry experience</div>
                    <div className='font-roboto text-text-primary-900 text-[14px] font-semibold'>{values?.experience || 0} years</div>
                </div>
                <div className='flex justify-between items-center space-x-0 ' style={{ height: '40px' }}>
                    <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Most Recent Project</div>
                    <div className='font-roboto text-text-primary-900 text-[14px] font-semibold line-clamp-1 max-w-[50%]'>{values?.projects_completed.length > 0 ? values?.projects_completed[0]?.name : " - " }</div>
                </div>
            </div>
            <div className="flex gap-3">
                {rfqSubmission && <Button type='button' btntype={selected ? 'primary' : 'secondary'} className='!w-full gap-1.5 items-center' onClick={() => selectHandler(values?.id)}>
                        {selected? 'Selected' : 'Select'}{selected && <Icon icon='check-only' width={20} height={20} />}
                </Button>}
                {rfqSubmission && <Button 
                    as='a' 
                    btntype='secondary' 
                    className='!w-full' 
                    href={`${basePathAuth(companyId)}company-profile/resources/edit-resource/${values?.id}`}
                    target='_blank'
                    
                >
                    Open Key Personnel
                </Button>}
                {isPreview && <Button 
                    btntype='secondary' 
                    className='!w-full' 
                    onClick={()=>{
                        submissionSmartFieldContext.setModalDetail({data:values,type:"resource"})
                        submissionSmartFieldContext.setIsModalOpen(true)
                    }}
                >
                    View Key Personnel
                </Button>}
            </div>
            
        </div>
    </div>
}

export const CorporateDocument = ({values,isPreview,selected,selectHandler,isLoading,companyId}) => {

    const DocumentCard = ({file,isPreview,selected,selectHandler}) => {
        return (
            <Card 
                className={cn(`border border-gray-outline py-3 px-4 my-4 group hover:bg-utility-brand-100 hover:border-utility-brand-100 hover:text-purple group cursor-pointer`)} 
                radius="sm"
                onClick={() => selectHandler ? selectHandler(file.id) : null}
                >
                <Flex className="relative items-center gap-2 justify-between">
                    <Flex className="relative items-center gap-4 justify-between">
                        <Icon icon={file.ext} size="md" />
                        <div className="relative">
                            <p className="text-sm font-medium line-clamp-1 max-w-[240px]">{file.name}</p>
                            <p className="text-sm line-clamp-1 max-w-[240px]">{file.categoryName}</p>
                        </div>
                    </Flex>
                    <Flex className="gap-2">
                    {!isPreview && (
                            <div className={`${selected ? 'flex' :'hidden'} group-hover:flex cursor-pointer`} >
                                <Icon icon="check" />
                            </div>
                    )}
                    {file?.storage_path && isPreview && <a href={file?.storage_path || ''} target='_blank' rel="noreferrer" download={file.name ? 'download' : null} className={cn('hidden group-hover:flex bg-gray-200 p-2 rounded-full hover:bg-red-200', isPreview && 'hover:bg-utility-brand-200')}>
                                <Icon icon={isPreview ? "download" : "trash"} style={{ width: '18px', height: '18px',cursor:'pointer', color:'black' }} />
                            </a> 
                    }
                    {!isPreview && <a href={`${basePathAuth(companyId)}company-profile/documents`}  target='_blank' rel="noreferrer" 
                       className={cn('group-hover:flex bg-gray-200 p-2 rounded-full hover:bg-utility-brand-200 opacity-0 -mr-8 transition-all group-hover:opacity-100 group-hover:mr-0', isPreview && 'hover:bg-utility-brand-200')}>
                        <Icon icon={'arrow-right'} style={{ width: '18px', height: '18px',cursor:'pointer', color:'black' }} />
                    </a> }
                    </Flex>
                </Flex>
        </Card>
        )
    }

    const DocumentCardSkeleton = () => {
       return ( 
       <div className="flex items-center bg-gray-100 animate-pulse w-full h-16 gap-3 px-6 rounded-lg">
            <SkeletonBase width="36px" height="36px" />
            <div className="space-y-2">
                <SkeletonBase height="16px" width="240px" />
                <SkeletonBase height="16px" width="180px" />
            </div>
        </div>)
    }

    const valuesLength = values?.length || 0;
    return <div className={cn("grid w-full gap-x-3 gap-y-0", valuesLength > 9 ? 'grid-rows-3 grid-flow-col' : 'grid-cols-3')} >
        {isLoading ? 
            [...Array(3)].map((_, index) => <DocumentCardSkeleton key={index} />)
            :
            Array.isArray(values) && values.length > 0 ? values.map((value) => {
                const {filename,ext} = filenameSpliter(value?.file_name);
                const file = {
                    name: filename || " - ",
                    categoryName: value?.category?.name || " -",
                    ext: ext,
                    checked: false,
                    storage_path: value?.storage_path || null,
                    id: value?.id
                }
                return <DocumentCard file={file} isPreview={isPreview} selectHandler={selectHandler} selected={selected ? selected(value?.id) : null} />
            }) : ' - '
        }
    </div>
}   