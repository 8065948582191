import apiV2 from "api-v2";

export const getSubmission = async (rfqId,value,isGuest) => {
  try {
    const { data: submission } = isGuest 
    ? await apiV2.post(`guest/show-submission/${rfqId}`, {guest_user_id: value})
    : await apiV2.get(`rfq/${rfqId}/submission?companyId=${value}`,)
    if (submission.status) {
      return submission.data;
    } else {
      return null;
    }
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}