import React from "react";
import styled from "styled-components";

export const FormLabel = styled.label`
  font-size: 14px;
  color: #344054;
  font-weight: 600;
  margin-bottom: 0px;
  letter-spacing: 0px;
  padding-bottom: 0px !important;
  > span {
    color: #dc2538;
    margin-left: 3px;
  }
  > svg {
    margin-left: 4px;
    cursor: pointer;
  }
  .guideline {
    svg {
      position: inherit;
      margin-left: 5px;
    }
  }
  .guide {
    border: 0.5px solid #8492a6;
    border-radius: 4px;
    letter-spacing: 0.1px;
    font-family: "roboto";
    font-size: 13px;
    text-transform: none;
    padding: 4px 8px;
    font-weight: normal;
    max-width: 400px;
  }
`;
const LabelForm = ({
  htmlFor,
  required,
  label,
  whiteLabel,
  noMargin,
  ...props
}) => {


  return (
    <FormLabel htmlFor={htmlFor}>
      <div
        className="flex gap-1 justify-start items-center"
        style={{
          letterSpacing: "normal",
          paddingBottom: "0px",
          marginBottom: noMargin ? "0px" : "6px",
        }}
      >
        {label?.trim() === "" ? (
          <div
            className="font-roboto font-medium text-white"
            style={{ marginBottom: "5.9px", lineHeight: "20px" }}
          >
            {label}
          </div>
        ) : (
          <div
            className="font-roboto font-medium text-secondary-700"
            style={{ fontSize: "14px", lineHeight: "20px" }}
          >
            {label}
          </div>
        )}
        {required &&
          <div className="text-required font-roboto font-medium">
            {" "}
            <span>*</span>
          </div>
        }
        {props.children}
      </div>
    </FormLabel>
  );
};

export default LabelForm;
