import { Box, IconButton } from '@chakra-ui/react'
import { Icon } from 'assets/icons/Icon'
import { Avatar } from 'components/RFQ/Avatar'
import { ProfileContext } from 'context/ProfileContext'
import React, { useContext } from 'react'
import { ReactComponent as ActiveProfileCheck } from "./../../icons/proflie-check.svg";

export const SidebarHeader = ({name,logo, isCollapseNav}) => {

    const {changeProfileType,currentProfile,isAccountSwitchable} = useContext(ProfileContext)

    const handleSwitchProfile = () =>{
      const role = currentProfile === "Procurer"?"supplier":"client"
      const profileType = currentProfile === "Procurer"?"supplier":"client"
      changeProfileType(role,profileType)
    }
  
    return (
      <div
      style={{
        display: "flex",
        gap:16,
        alignItems: "center",
        justifyContent:"start",
        margin: isCollapseNav
          ? "40px 0px 20px 0px"
          : "40px 8px 20px 12px",
      }}
    >
      <div>
        <Avatar
          avatar={logo}
          alt={name}
          className="!p-0"
          noHover
        />
      </div>
      <div
          id="profile"
          className="overflow-hidden text-ellipsis"
        >
          <h2
            className="font-roboto font-bold capitalize overflow-hidden text-ellipsis text-[30px] text-[#101828] max-w-xs truncate"
          >
            {name}
          </h2>
          <div className='flex items-center gap-2'>
            <p className='uppercase text-[#0031DD]'>{currentProfile}</p>
            <ActiveProfileCheck/>
          </div>
      </div>
      {isAccountSwitchable && <Box>
        <IconButton onClick={handleSwitchProfile} icon={<Icon icon="refresh"/>}/>
      </Box>}
    </div>
  )
}

export default SidebarHeader