import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";

import React from "react";
import tw, { styled } from "twin.macro";
import { MultipleCheckbox } from "components/RFQ/MultipleCheckbox";

const AccordionButtonStyled = styled(AccordionButton)(() => [
  tw`px-0 py-4 text-black font-semibold hover:bg-transparent`,
]);

const AccordionIconStyled = styled(AccordionIcon)(() => [
  tw`w-5 h-5 text-fq-quinary-400`,
]);

const AccordionItemStyled = styled(AccordionItem)(() => [tw`px-5 border-b`]);

const AccordionPanelStyled = styled(AccordionPanel)(() => [tw`pb-5 p-0`]);

const AccordionSectionRFQ = ({
  filterOptions,
}) => {
  return (
    <div>
      <Accordion defaultIndex={[0]} allowMultiple className="relative">
        {filterOptions.length > 0 && filterOptions?.map((filter, index) => (
          <AccordionItemStyled key={`filter-${index}`}>
            <h2>
              <AccordionButtonStyled>
                <Box as="span" flex="1" textAlign="left">
                  {filter.title}
                </Box>
                <AccordionIconStyled />
              </AccordionButtonStyled>
            </h2>
            <AccordionPanelStyled>
              <MultipleCheckbox checkboxes={filter.item} name={filter.name} />
            </AccordionPanelStyled>
          </AccordionItemStyled>
        ))
        }
      </Accordion>
    </div>
  );
};

export default AccordionSectionRFQ;
