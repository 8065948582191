import apiV2 from "api-v2";
import * as constant from './actionTypes';
import * as constants from "../actionTypes";
import { toFormData } from "axios";
import moment from "moment";
import { navigate } from "@reach/router";
import { showToast } from "utils/helpers";

export const getSupplierLists = ({limit=12,page=1,search='',sort='last_activity',filters,reset = false}) => {
  return async (dispatch,getState) => {
    if(reset) await dispatch ({type: constant.RESET_SUPPLIER_LISTS, payload: true});
    dispatch({
      type: constant.GET_SUPPLIERS_REQUEST
    });
    try {
      const params = {limit,page,search,sort,filters}
      const {data: {supplier : suppliers}} = await apiV2.get(`supplier`, { params });

      let oldData = await getState().rfq.supplierLists.supplier;
        let newOldData = oldData
        if(page>1){Object.keys(suppliers).forEach((supplier) => {
          if(oldData[supplier].meta.last_page >= suppliers[supplier].meta.current_page){
            newOldData[supplier].data = [...oldData[supplier]?.data, ...suppliers[supplier]?.data]
            newOldData[supplier].meta = suppliers[supplier].meta;
          }
        })}

      let countData = await getState().rfq.supplierLists.supplierCount;
      Object.keys(suppliers).forEach((supplier) => (
          countData[supplier] = suppliers[supplier].data.length
        ))


      if(reset){
        dispatch({
          type: constant.GET_SUPPLIERS_SUCCESS,
          payload: suppliers,
        });
      }else{
        await dispatch({
          type: constant.GET_SUPPLIERS_SUCCESS,
          payload: page > 1 ? newOldData : suppliers,
        });
        await dispatch({
          type: constant.SET_SUPPLIER_BADGES_COUNT,
          payload: countData,
        });
      }

    } catch (err) {
      dispatch({
        type: constant.GET_SUPPLIERS_FAILED,
        payload: err.response?.data || err.message, 
      });
    }
  };
};

export const getSuppliers = async (supplierListsId,params) => {
  try{
    const suppliers = await apiV2.get(`supplier/${supplierListsId}`, { params });
    if(suppliers.status === 200){
      return suppliers.data.data?.suppliers;
    }else{
      return null
    }
  }catch{
    return null
  }
}

export const setSupplierArchived = (ids) => {
  
  return async (dispatch) => {
    await dispatch ({type: constant.RESET_SUPPLIER_LISTS, payload: true});
    dispatch({
      type: constant.SET_SUPPLIER_ARCHIVED,
    })
    try {
      const res = await apiV2.post(`supplier/archive`,{supplier_list_ids: [...ids]})
      if(res.status){
        dispatch({
          type: constant.SET_SUPPLIER_ARCHIVED_SUCCESS,
        })
        showToast(res.data.message, 'Success', true)
        return res.data
      }
      else{
        dispatch({
          type: constant.SET_SUPPLIER_ARCHIVED_FAILED,
        });
        showToast('Failed to archive supplier list.', 'Error')
        return res.data
      }
      
    }
    catch (error){
      dispatch({
        type: constant.SET_SUPPLIER_ARCHIVED_FAILED,
        payload: error.response?.data || error.message,
      });
      showToast('Failed to archive supplier list.', 'Error')
    }
  }
}

export const setSupplierUnArchived = (ids) => {
  
  return async (dispatch) => {
    await dispatch ({type: constant.RESET_SUPPLIER_LISTS, payload: true});
    dispatch({
      type: constant.SET_SUPPLIER_ARCHIVED,
    })
    try {
      const res = await apiV2.post(`supplier/unarchive`,{supplier_list_ids: [...ids]})
      if(res.status){
        dispatch({
          type: constant.SET_SUPPLIER_ARCHIVED_SUCCESS,
        })
        showToast(res.data.message, 'Success', true)
        return res.data
      }
      else{
        dispatch({
          type: constant.SET_SUPPLIER_ARCHIVED_FAILED,
        });
        showToast(res.data.message || 'Failed to unarchive supplier list.', 'Error')
        return res.data
      }
      
    }
    catch (error){
      dispatch({
        type: constant.SET_SUPPLIER_ARCHIVED_FAILED,
        payload: error.response?.data || error.message,
      });
      showToast('Failed to unarchive supplier list.', 'Error')
    }
  }
}

export const createSupplierList = (data,listId) => {
  const formData = toFormData({...data,supplier_list_id:listId,isUpdate: listId ? 1 : 0,end_date: moment(data?.end_date).format('YYYY-MM-DD'),start_date: moment(data?.start_date).format('YYYY-MM-DD')})
  return async (dispatch) => {
    dispatch({
      type: constant.CREATE_SUPPLIER_LIST,
    });
    try {
      const res = await apiV2.post('supplier/save', formData);
      const slug = res?.data?.data?.name?.toLowerCase().replace(/ /g, "-");
      dispatch({
        type: constant.CREATE_SUPPLIER_LIST_SUCCESS,
        payload: res.data,
      });
      if(res.data.status){
        showToast(res?.data?.message, 'Success', true);
        listId ? navigate(`../${listId}/${slug}`) : navigate(`./${res?.data?.data?.id}/${slug}`)
      }else{
        showToast(res.data.message || 'Failed to create supplier list', 'Error');
        throw Error('Failed to create supplier list');
      }
    } catch (error) {
      dispatch({
        type: constant.CREATE_SUPPLIER_LIST_FAILED,
        payload: error.response?.data || error.message,
      });
      showToast(error.response?.data.message || error.message ||'Failed to create supplier list', 'Error');
      throw Error( 'Failed to create supplier list');
    }
  };
}

export const getSupplierListById = async (supplierId) => {
  try{
    const supplierList = await apiV2.get(`supplier/${supplierId}`);
    if(supplierList.status === 200){
      const supplierListData = supplierList.data;
      return supplierListData;
    }else{
      throw new Error(supplierList?.data?.message);
    }
  }catch{
    return null
  }
}

export const assignSupplier = async (listId,suppliersId) => {
  const formData = toFormData({supplier_id:suppliersId,},undefined,{indexes: true})
  try{
    const supplierList = await apiV2.post(`supplier/${listId}/assign-supplier`,formData);
    const supplierListData = supplierList;
    if(supplierListData.status){
      showToast(supplierListData.data.message, 'Success', true);
      return supplierListData;
    }else{
      showToast('Failed to assign supplier', 'Error');
      throw new Error('Failed to assign supplier');
    }
  }catch{
    showToast('Failed to assign supplier', 'Error');
    throw new Error('Failed to assign supplier');
  }
}

export const assignToProject = async (listId,projectId) => {
  const formData = toFormData({project_id:[...projectId]},undefined,{indexes: true})
  try{
    const supplierList = await apiV2.post(`/supplier/${listId}/assign-project`,formData);
    if(supplierList){
      showToast(supplierList?.data?.message, 'Success', true);
      return supplierList;
    }
  }catch{
    showToast('Failed to assign supplier to project', 'Error');
    throw new Error('Failed to assign supplier to project');
  }
}

export const getCompaniesPerPage = (perPage,page,search,sort,reset = false) => {
  return async (dispatch) => {
    dispatch({ type: constants.IS_SEARCHING, payload: true });

    (async () => {
      const companies = await apiV2.get(`supplier/suppliers-account?page_size=${perPage || 12}&page=${page}&search=${search || ''}&sort=${sort}`)
      if(reset){
        await dispatch({
          type: constants.GET_COMPANIES_SEARCH,
          payload: companies.data.data,
        });
      }else{
        await dispatch({
          type: constants.GET_COMPANIES_PER_PAGE,
          payload: companies.data.data,
        });
      }
      await dispatch({ type: constants.IS_SEARCHING, payload: false });
    })();
  };
};

export const removingSupplier = async (supplierListId,companyId) => {
  try{
    const data = toFormData({supplier_id:[companyId]})
    const response = await apiV2.post(`supplier/${supplierListId}/detach-supplier`,data)
    if(response.status === 200){
      showToast(response.data.message, 'Success', true)
      return response.data
    }else{
      showToast(response.data.message, 'Error')
      return null
    }
  }catch(err){
    showToast('Failed to remove supplier', 'Error')
    return null
  }
}

export const addSupplierContract = async(supplierListId,data) =>{
  try{
    
    const uploadContract = await apiV2.post(`supplier/${supplierListId}/update-contract`,data);
    if(uploadContract.status === 200){
      return uploadContract.data
    }else{
      return null
    }
  }catch(e){
    return null
  }
}

export const deleteSupplierContract = async(supplierListId,data) =>{
  try{
    
    const uploadContract = await apiV2.post(`supplier/${supplierListId}/remove-contract`,data);
    if(uploadContract.status === 200){
      return uploadContract.data
    }else{
      return null
    }
  }catch(e){
    return null
  }
}