import React from "react";

import { SidebarStyled } from "./styled";
import { CompanySidebar } from "../CompanySidebar";
import { GuestSidebar } from "../GuestSidebar";
import { SkeletonBase } from "components/Skeleton";

export const Sidebar = ({ guestUser, isLoading }) => {
  return (
    isLoading ? <div className="ps-8 fixed h-[100vh] w-[350px] py-16 flex flex-col gap-4">
    <SkeletonBase height="180" width="100" />
    <SkeletonBase height="70" width="100" />
    <SkeletonBase height="20" width="25" />
    <SkeletonBase height="30" width="100" />
    <SkeletonBase height="30" width="100" />
    
  </div>:
    <SidebarStyled>
      {guestUser.login ? (
        <GuestSidebar guestUser={guestUser} />
      ) : (
        <CompanySidebar />
      )}
    </SidebarStyled>
  );
};
