import { CustomModal } from 'components/RFQ/Modal/CustomModal';
import React, { useState } from 'react'
import { getProjects, setProjectArchived, setProjectUnArchived } from "actions/RFQ/projectsAction";
import { useDispatch } from 'react-redux';
import  { Button } from "components/RFQ/Button";

export default function ProjectArchiveModal({isArchived,projectData,isModalOpen, setIsModalOpen, onSuccessHandler}) {

    const dispatch = useDispatch();
    const [isLoading,setIsLoading] = useState(false)
    const dialogProperty = {
        icon: `${!isArchived ? "archive" : "blue-circle-check"}`,
        bgColor: "bg-brand-secondary",
        iconColor: "text-utility-brand-700",
        title: `${!isArchived ? "Archive" : "Retrieve"} ${projectData?.name} ?`,
        description: `Are you sure to ${!isArchived ? "Archive" : "Retrieve"} ${projectData?.name}?`
      };

      const archivedHandler = () => {
        setIsLoading(true)
        console.log('datatatat',isArchived);
        if (isArchived) {
            
           dispatch(setProjectUnArchived(projectData?.id))
            .then((data) => {
               
              if (data?.status) {
                dispatch(getProjects({ reset: true }))
                setIsLoading(false)
                setIsModalOpen(false)
               
                
                onSuccessHandler()
              }
            })
        } else {
           dispatch(setProjectArchived(projectData?.id))
            .then((data) => {
              if (data?.status) {
                dispatch(getProjects({ reset: true }))
                setIsLoading(false)
                setIsModalOpen(false)
                onSuccessHandler()
              }
            })
        }
      }
    
      const FooterComponent = () =>{
        return (
          <div className="flex justify-between gap-2 w-full">
            <Button
            btntype="plain-border"
            onClick={()=>setIsModalOpen(false)}
            className="!w-full"
            >
            Cancel
            </Button>
            <Button
            className="flex !w-full gap-3"
            onClick={archivedHandler}
            disabled={isLoading}
            >
            {isLoading? !isArchived ? "Archiving" : "Retrieving":'Yes'}
            </Button>
          </div>
          );
        }
  return (
    <CustomModal 
        header={dialogProperty}
        isOpen={isModalOpen}
        onClose={()=>setIsModalOpen(false)}
        footerComponent={<FooterComponent/>}
        />
  )
}
