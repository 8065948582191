import moment from "moment"

const getDateAndTime = (date,time='') =>{
    if(time){

        return moment(
            moment(date).format("YYYY-MM-DD") +
              " " +
              time +
              ":00"
          ).format("MMMM D YYYY, h:mm A")
    }
    
    return moment(date).format('MMMM D YYYY, h:mm A');

}

const previewProjectDetails = (rfqDetail,rfqTypes) => [
    {
        label: 'Request Title',
        value: rfqDetail?.name || ' - '
    },
    {
        label: 'RFx ID number',
        value: rfqDetail?.rfq_id_number || rfqDetail.rfqIdNumber || ' - '
    },
    {
        label: 'Type',
        value: rfqDetail?.type ? rfqTypes?.find((type) => type.value === rfqDetail?.type)?.label || rfqDetail.type : ' - '
    },
    {
        label: 'Address',
        value: rfqDetail?.address?.value || rfqDetail.location || "-"
    },
    {
        label: 'Lead Contact Name',
        value: rfqDetail?.lead_contact_name || rfqDetail.leadContactName || "-"
    },
    {
        label: 'Phone Number',
        value: rfqDetail?.phone_number || rfqDetail.phoneNumber || "-"
    },
    {
        label: 'Email',
        value: rfqDetail?.email || "-"
    },
    {
        label: 'Description',
        value: rfqDetail?.description || rfqDetail?.documentationDescription || "-"
    },
    {
        label: 'Client',
        value: rfqDetail?.client || "-"
    },
    {
        label: 'Deadline Date and Time',
        value: `${rfqDetail?.deadline_date?getDateAndTime(rfqDetail?.deadline_date,rfqDetail?.deadline_time): getDateAndTime(rfqDetail.submissionDeadline)} ${rfqDetail?.submission_deadline_timezone || rfqDetail.submissionTimezone
        }`|| "-",
    },
    {
        label: 'Scope of Service',
        value: rfqDetail?.escope_of_service || rfqDetail.escopeOfService || "-"
    }
]

export const mapFromRFQFormToRFQData = (formValue,rfqDetail,rfqTypes) =>{

    return {
        "projectDetails":previewProjectDetails(formValue,rfqTypes),
        "documents":formValue.documents,
        "description":formValue.description,
        "documentDescription":formValue.documentation_description,
        'questionnaire':formValue.sections,
        'quote':formValue.quote,
        'quoteFile':formValue.quote_file,
        'quoteType':rfqDetail?.quote_type

    }

}

export const mapFromRFQDetailToRFQData = (rfqDetail,rfqTypes) =>{
    
    return {
        "projectDetails":previewProjectDetails(rfqDetail,rfqTypes),
        "documents":rfqDetail.documentationDocument,
        "documentDescription":rfqDetail.documentationDescription,
        "description":rfqDetail.description,
        'questionnaire':rfqDetail.questionnnaire,
        'quote':rfqDetail.quotes,
        'quoteFile':rfqDetail.quoteFilePath,
        'quoteType':rfqDetail.quoteType
    }

}