import { navigate } from '@reach/router'
import { Icon } from 'assets/icons/Icon'
import React from 'react'
import { cn, isEmptyObj } from 'utils/helpers'

export default function FormErrorComp({isReturnableError, isQuoteError, selectErrorTab}) {

  return (
     <div 
     style={{
        borderRadius:12,
        display:'flex',
        gap:16,
        padding:16,
        marginBottom:32,
        border: "1px solid  #D92D20",
        background: '#FFF',
        boxShadow: '0px 0px 0px 4px rgba(240, 68, 56, 0.24)'
     }}>
          <div
            className={cn(
              "rounded-full h-10 w-10 items-center content-center bg-utility-error-50 ",
            )}
            style={{ textAlign: "-webkit-center" }}
          >
            <Icon icon='alert-triangle' isDanger />
          </div>
          <div>
            <h3 style={{ fontFamily: 'roboto', fontWeight: '600', color:  '#D92D20' }}>There is an error with your RFx</h3>
            <p className="text-text-primary-900" style={{ fontSize: "16px" }}>
              <span>Please complete all mandatory fields on the </span>
              <button className='underline' onClick={()=>navigate('./returnable-schedules')}><strong>{(isReturnableError && !isEmptyObj(!isReturnableError)) && 'Returnable Schedules'} </strong> </button>
              
              {(isReturnableError && isQuoteError) && ' and '} 
              <button className='underline' onClick={()=>navigate('./quote')}><strong>{(isQuoteError && !isEmptyObj(!isReturnableError)) && ' Quote'}</strong></button> tab before submitting your RFx response.
            </p>
          </div>
        </div>
  )
}
