import React from "react";
import { Button } from "../Button";
import { cn } from "utils/helpers";
import { Flex } from "@chakra-ui/react";
import { Field, useField } from "formik";
import { Icon } from "assets/icons/Icon";

const tableField = [
  {
    field: "sort_order",
    placeholder: "",
  },
  {
    field: "name",
    placeholder: "",
  },
  {
    field: "unit_id",
    placeholder: "",
  },
  {
    field: "total",
    placeholder: "0",
  },
  {
    field: "rate",
    placeholder: "$0.00",
  },
  {
    field: "cost",
    placeholder: "$0.00",
  },
];

const EditableTable = ({
  data,
  handleChangeInput,
  placeholder,
  tableVariable,
  className,
  isPreview,
  type = "submission",
  children,
  index,
  unitMeasures,
  disable,
  removeRow,
  addRow,
  copyRow,
  ...props
}) => {
  const [newItem, setnewItem] = React.useState("");
  const fieldname = (number, name) => `quote[${index}]items[${number}]${name}`;
  const [, { value: itemsValue }] = useField(`quote[${index}]items`);
  const [showIndexRow, setShowIndexRow] = React.useState("");
  const isSubmission = type === "submission";

  const autoCalculate = (index) => {
    if (itemsValue[index]?.total && itemsValue[index]?.rate) {
      return (
        itemsValue[index]?.total * itemsValue[index]?.rate
      ).toLocaleString("en-US", { style: "currency", currency: "USD" });
    } else {
      return "$0.00";
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addRow(newItem);
      setnewItem("");
      setShowIndexRow("");
    }
  };

  return (
    <div
      className={cn("mx-auto mt-4 px-3", className)}
      {...props}
    >
      <table className="w-full border-collapse">
        <thead>
          <tr>
            {tableVariable?.map((item, index) => (
              <th
                key={index}
                className="text-left w-1/6 text-xs text-text-tertiary-600 py-3 font-medium px-4 border border-gray-200 bg-bg-gray-200"
                style={{
                  width:
                    index === 0
                      ? "4%"
                      : index === 1
                        ? "32%"
                        : index === 2
                          ? "18%"
                          : "10%",
                  textAlign: index === 0 && "center",
                }}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const inputCells = tableVariable?.map((_, columnIndex) => (
              <td
                className={cn(
                  "border border-gray-300",
                  columnIndex === 0 && "text-center w-[1%] bg-bg-gray-200",
                  (isSubmission ? columnIndex < 2 : columnIndex > 2) &&
                    "bg-bg-gray-200/70"
                )}
                key={columnIndex}
              >
                {columnIndex === 0 ? (
                  <Field
                    className={cn(
                      "py-2 focus:outline-none bg-transparent border-none px-3 w-full disabled:bg-gray-50 bg-white",
                      columnIndex === 0 && "text-center max-w-[50px]",
                      !isSubmission && "invalid:bg-red-50"
                    )}
                    value={index + 1}
                    disabled
                    type="text"
                  />
                ) : columnIndex === 5 && isSubmission ? (
                  <Field
                    className={cn(
                      "py-2 focus:outline-none bg-transparent border-none px-3 w-full disabled:bg-gray-50 bg-white",
                      columnIndex === 0 && "text-center max-w-[50px]",
                      !isSubmission && "invalid:bg-red-50"
                    )}
                    name={fieldname(index, [tableField[columnIndex].field])}
                    disabled
                    placeholder={tableField[columnIndex].placeholder}
                    value={autoCalculate(index)}
                  />
                ) : columnIndex===4?<>
                <Field
                    className={cn(
                      "py-2 focus:outline-none bg-transparent border-none px-3 w-full disabled:bg-gray-50 bg-white",
                      columnIndex === 0 && "text-center max-w-[50px]",
                      !isSubmission && "invalid:bg-red-50"
                    )}
                    name={fieldname(index, [tableField[columnIndex].field])}
                    disabled
                    placeholder={tableField[columnIndex].placeholder}
                    value={parseFloat(item?.rate || 0).toLocaleString("en-US", { style: "currency", currency: "USD" })}
                  />
                </>:columnIndex !== 2 ? (
                  <div className="flex items-center justify-between pe-5">
                    <Field
                    as={columnIndex===1 && !isPreview?"textarea":'input'}
                      className={cn(
                        "py-2 focus:outline-none bg-transparent border-none px-3 w-full disabled:bg-gray-50 bg-white resize-none scrollbar row-2",
                        columnIndex === 0 && "text-center max-w-[50px]",
                        !isSubmission && "invalid:bg-red-50"
                      )}
                      name={fieldname(index, [tableField[columnIndex].field])}
                      disabled={
                        (isSubmission ? disable : columnIndex > 2) ||
                        columnIndex === 0 ||
                        columnIndex === 5
                      }
                      placeholder={
                        columnIndex === 1
                          ? item?.placeholder
                          : tableField[columnIndex].placeholder
                      }
                      type={columnIndex > 2 ? "number" : "text"}
                      required
                      onKeyUp={(e)=>{
                        e.preventDefault(); 
                        if(e.key==="Enter"){
                          copyRow(item,columnIndex)
                        }
                      }}
                    />
                    {!isSubmission && columnIndex === 5 && (
                      <div className="grid grid-cols-2 gap-4">
                      <button
                      type="button"
                        onClick={() => {
                          copyRow(item, index);
                        }}
                      >
                        <Icon
                          icon="copy-icon"
                          style={{ cursor: "pointer", height: 15 }}
                        />
                      </button>
                      <button
                      type="button"
                        onClick={() => {
                          removeRow(item, index);
                        }}
                      >
                        <Icon
                          icon="trash-stroke"
                          style={{ cursor: "pointer", height: 15 }}
                        />
                      </button>
                    </div>
                    )}
                  </div>
                ) : (
                  <div className="relative">
                    <Field
                      as="select"
                      className={cn(
                        "focusable block appearance-none py-2 focus:outline-none bg-transparent border-none px-4 w-full invalid:bg-red-50 focus-within:bg-white mr-4",
                        columnIndex === 0 && "text-center max-w-[50px]",
                        disable && "disabled:bg-gray-50"
                      )}
                      disabled={disable ? disable : false}
                      name={fieldname(index, [tableField[columnIndex].field])}
                      placeholder={tableField[columnIndex].placeholder}
                      required
                    >
                      <option
                        className="!bg-white"
                        value=""
                        disabled
                      >
                        Select unit of measurement
                      </option>
                      {unitMeasures?.map((unit) => (
                        <option value={unit.value.toString()}>
                          {unit?.label}
                        </option>
                      ))}
                    </Field>
                    {!isPreview ? (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 top-1/2 transform -translate-y-1/2 mr-3">
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    ) : null}
                  </div>
                )}
              </td>
            ));
            if (!isSubmission && item?.isRemove === 1) {
              return null;
            } else {
              return <tr key={index}>{inputCells}</tr>;
            }
          })}
          {!isPreview && (
            <tr
              style={{
                marginLeft: showIndexRow !== index ? "unset" : "-0.5px",
                marginRight: showIndexRow !== index ? "unset" : "-0.5px",
              }}
            >
              <td
                colSpan={tableVariable?.length || 1}
                className={
                  showIndexRow !== index ? "border border-gray-300" : ""
                }
              >
                {showIndexRow !== index ? (
                  <Button
                    btntype={"plain"}
                    onClick={(event) => {
                      event.preventDefault();
                      setShowIndexRow(index);
                    }}
                    type="button"
                    style={{ paddingLeft: "8px" }}
                  >
                    <Flex
                      gap={1}
                      align={"center"}
                    >
                      <span className="text-royal-blue-500">+</span>
                      <span>Add row</span>
                    </Flex>
                  </Button>
                ) : (
                  <>
                    <input
                      placeholder="+ Add item"
                      className="outline-none font-roboto placeholder:font-roboto w-full p-2.5 focus:border focus:!border-royal-blue2 focus:outline-none placeholder:text-fg-senary-300 text-text-primary-900"
                      onKeyDown={(event) => handleKeyDown(event)}
                      value={newItem}
                      type="text"
                      autoFocus
                      onChange={($e) => {
                        setnewItem($e.target.value);
                      }}
                      name="newitem"
                    />
                  </>
                )}
              </td>
            </tr>
          )}
          {children}
        </tbody>
      </table>
    </div>
  );
};

export default EditableTable;