import React from 'react'
import BadgeComp from '../BadgeComp/BadgeComp'
import { Button } from 'components/Button'
import { Flex } from '@chakra-ui/core'
import { SkeletonBase } from "components/Skeleton";
import { SkeletonCircle } from "@chakra-ui/react";
import { Icon } from 'assets/icons/Icon';
const TabsComp = ({
    menus,
    activeTab,
    changeActiveTab,
    isLoading,
    enableValidation
}) => {
    const clickTab = (name, index,id) => {
        if (enableValidation) {
            let enableTab = true;
            for (let a = 0; a < index; a++) {
                if (!menus[a].isValid) {
                    enableTab = false
                }
            }
            if (enableTab) {
                changeActiveTab(name,id)
            }
        } else {
            
            changeActiveTab(name,id)
        }
    }

    return <>
        {isLoading ? <Flex style={{ justifyContent: 'space-between' }}>
            <Flex style={{ gap: '8px' }}>
                {menus && menus.map((_, index) => {
                    return <div key={index} className='flex'>
                        <div className='mr-2'>
                            <SkeletonBase width="100px" height="16px" />
                        </div>
                        <div className='mr-4'>
                            <SkeletonCircle width={5} height={5} startColor="lightgray" />
                        </div>
                    </div>
                })}
            </Flex>
        </Flex> : <Flex style={{ justifyContent: 'space-between' }}>
            <Flex style={{ gap: '8px' }}>
                {menus && menus.map((tbMenu, index) => {
                    return <div key={index}>
                        {!tbMenu?.disabled && <Button id={`tabs-${index}`} btntype='tab' active={activeTab === tbMenu.name ? true : false} onClick={() => clickTab(tbMenu.name, index,tbMenu.id)} >
                            <div style={{ fontFamily: 'roboto', fontWeight: '600', color: activeTab === tbMenu.name ? '#0031DD' : '#667085' }} >{tbMenu.name}</div>
                            {tbMenu.showBadge && <BadgeComp title={tbMenu.name} active={activeTab} total={tbMenu?.total}></BadgeComp>}
                            {enableValidation && <div className='ml-2'>
                                {tbMenu.isValid ? <Icon icon="tick" />:<Icon icon="error"/>}
                            </div>}
                        </Button>}
                    </div>
                })}
            </Flex>
        </Flex>}
    </>
}

export default TabsComp