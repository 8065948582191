import { Flex } from '@chakra-ui/core'
import { Button } from 'components/Button'
import { TabHeader } from 'components/TabHeader'
import React, {useEffect, useState } from 'react'
import FilterButton from 'components/FilterButton/FilterButton'
import ButtonViewGroup from 'components/ButtonViewGroup/ButtonViewGroup'
import { useDisclosure } from '@chakra-ui/core'
import { connect } from 'react-redux'
import tw, { styled } from 'twin.macro'
import RFQGridView from './Views/GridView/RFQGridView';
import { FilterRFQ } from 'components/Pages/RFQ/FilterRFQ';
import { RFQListView } from './Views/ListView';
import CreateRFQOverview from './CreateRFQ/Overview/CreateRFQOverview';
import { useSelector } from 'react-redux'
import SearchBar from 'components/RFQ/SearchBar/SearchBar'
import { getRfqPerPage } from 'actions/RFQ/rfqsAction'
import BadgeComp from 'components/RFQ/BadgeComp/BadgeComp'
import { useDispatch } from 'react-redux'
import { SET_RFQ_BADGES_COUNT } from 'actions/RFQ/actionTypes'
import SortComp from 'components/RFQ/SortComp/SortComp'



const RFQStyled = styled.div([
  tw`overflow-visible`,
]);

const sortedData = [
  {
    name: 'Sort Alphabetically (A to Z)',
    value: 'asc',
    sort: 'Sort (A to Z)'
  },
  {
    name: 'Sort Alphabetically (Z to A)',
    value: 'desc',
    sort: 'Sort (Z to A)'
  },
  {
    name: 'Sort by Last Activity',
    value: 'last_activity',
    sort: 'Sort Last Activity'
  },
  {
    name: 'Sort by Date Created',
    value: 'created',
    sort: 'Sort Date Created'
  }
];

const RFQ = ({ rfq, getRfqPerPage }) => {
  const [currentTab, setCurrentTab] = useState('current_rfq');
  const currentPage = useSelector((state) => state.rfq?.rfqs?.rfqs?.['current_rfq']?.meta?.current_page);
  const [view, setView] = useState('grid');
  const [sorted, setSorted] = useState(sortedData[2]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [modalType, setModalType] = useState('filter');
  const [search, setSearch] = useState('');
  const isLoading = useSelector((state) => state.rfq.rfqs?.loading);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const rfqsData = useSelector((state) => state.rfq.rfqs?.rfqs?.[currentTab]?.data);
  const [filters, setFilters] = useState(null);
  const [prevValues, setPrevValues] = useState(null);
  const { sidebarcollapse } = useSelector((state) => state.sidebar);

  const rfqBadgeCounts = useSelector(state => state.rfq?.rfqs?.rfqCount);

  const dispatch = useDispatch();

  const pageChecker = (rfq, pages = 'current_page') => {
    let page = 1;
    if (JSON.stringify(rfq) !== '{}') {
      page = rfq?.[currentTab]?.meta?.[pages];
    }
    return page;
  };

  const [currPage, setCurrPage] = useState(pageChecker(rfq));
  const [lastPage, setLastPage] = useState(pageChecker(rfq, 'last_page'));

  const onScroll = async () => {
    const { clientHeight, scrollHeight, scrollTop } = document.getElementById("element-scroll");
    if (!isLoading && !loadingPagination && scrollTop + clientHeight >= scrollHeight - 180) {
      if (currPage < lastPage) {
        setLoadingPagination(true);
        getRfqPerPage({ page: currPage + 1, search, sort: sorted.value, filters })
        setLoadingPagination(false);
      }
    }
  };

  const tabMenu = [
    {
      id: 'current_rfq',
      name: 'Current RFx',
      showBadge: true
    },
    {
      id: 'evaluation',
      name: 'Evaluation',
      showBadge: true
    },
    {
      id: 'draft',
      name: 'Drafts',
      showBadge: true,
    },
    {
      id: 'archived',
      name: 'Archived',
      showBadge: true,
    }
  ]

  useEffect(() => {
    const handleScroll = () => {
      onScroll();
    };

    const scrollableElement = document.getElementById("element-scroll");

    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);

      return () => {
        scrollableElement.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line
  }, [currPage, lastPage, rfqsData, isLoading, loadingPagination]);


  useEffect(() => {
    setLoadingPagination(false);
    // eslint-disable-next-line
  }, [currPage]);

  useEffect(() => {
    setCurrPage(1)
    getRfqPerPage({ page: 1, search, sort: sorted.value, filters, reset: true })
    //eslint-disable-next-line
  }, [search, sorted, filters]);

  useEffect(() => {
    setCurrPage(pageChecker(rfq));
    setLastPage(pageChecker(rfq, 'last_page'));
    //eslint-disable-next-line
  }, [rfq, currentPage, currentTab, isLoading]);

  useEffect(() => {
    const currentTotal = rfq?.[currentTab]?.meta?.total;
    if (currentTotal !== undefined && currentTotal !== null) {
      const updatedBadgeCounts = { ...rfqBadgeCounts, [currentTab]: currentTotal };
      dispatch({ type: SET_RFQ_BADGES_COUNT, payload: updatedBadgeCounts });
    }
    //eslint-disable-next-line
  }, [search, sorted, filters, currentTab]);


  const refreshData = () => {
    setCurrPage(1)
    getRfqPerPage({ page: 1, search, sort: sorted.value, filters, reset: true })
  }


  return (
    <RFQStyled>
      <TabHeader heading='RFx'>
        <Button style={{ height: '50px' }} onClick={() => { setModalType('createRFQ'); onOpen(); }}>
          Create RFx
        </Button>
      </TabHeader>
      <div id="layout-dashboard">
        <div className='grid lg:grid-cols-2 place-content-between md:grid-cols-1 gap-8 lg:gap-0 '> 
          <Flex className='lg:order-1 order-2 overflow-x-auto overflow-y-hidden scrollbar pb-3 lg:pb-0 gap-8'>
              {tabMenu.map((tbMenu, index) => {
                return <div key={index} className='group'>
                  <Button btntype='tab' active={currentTab === tbMenu.id ? true : false} onClick={() => setCurrentTab(tbMenu.id)}>
                    <div style={{ fontFamily: 'roboto', fontWeight: '500' }} className={`text-${currentTab === tbMenu.id ? 'to-utility-brand-700' : 'text-text-primary-500'}`} >{tbMenu.name}</div>
                    {tbMenu.showBadge && <BadgeComp title={tbMenu.id} active={currentTab} total={rfq?.[tbMenu.id]?.meta?.total || rfqBadgeCounts?.[tbMenu.id] || 0}/>}
                  </Button>
                </div>
              })}
          </Flex>
          <Flex className='lg:order-2 order-1 self-end gap-4'>
            <SearchBar setState={setSearch} />
            <FilterButton onClick={() => { setModalType('filter'); onOpen(); }} filters={filters} />
            <SortComp
              data={sortedData}
              sorted={sorted}
              onClickCB={(item) => {
                setOpenPopUp(false); 
                setSorted(item)
              }}
              openPopUpCB={openPopUp}
              setOpenPopUpCB={setOpenPopUp}>
            </SortComp>
            <ButtonViewGroup setView={setView} view={view} />
          </Flex>
        </div>
        <FilterRFQ isOpen={isOpen} onClose={onClose} />
        {view === 'grid' ? 
            <RFQGridView 
              currentTab={currentTab} 
              currPage={currPage} 
              refreshData={refreshData} 
              setModalType={setModalType} 
              isSidebarCollapsed={sidebarcollapse}
              onOpen={onOpen} 
            />
            : 
            <RFQListView 
              currentTab={currentTab} 
              currPage={currPage} 
              refreshData={refreshData}
              setModalType={setModalType} 
              onOpen={onOpen}   
            />
        }
        {modalType === 'createRFQ' && <CreateRFQOverview isOpen={isOpen} onClose={onClose} />}
        {modalType === 'filter' && <FilterRFQ prevValues={prevValues} setPrevValues={setPrevValues} setFilters={setFilters} isOpen={isOpen} onClose={onClose} currentTab={currentTab} />}


      </div>
    </RFQStyled>
  )
}

const mapStateToProps = (state) => {
  return {
    projects: state.rfq.projects.projects,
    companyId: state.auth.user.company_id,
    rfq: state.rfq.rfqs.rfqs,

  };
};

export default connect(mapStateToProps, { getRfqPerPage })(RFQ);
