import styled from "styled-components";

import { theme } from "../../theme";

export const SidebarStyled = styled.div`
  background-color: white;
  height: 100vh;
  flex-shrink: 0;
  max-width: 350px;
  position: fixed;
  overflow-y: auto;
  z-index:2;

  ${theme.mediaQueries.xxl} {
    max-width: 450px;
  }

  h3 {
    margin: 0;
  }
`;
