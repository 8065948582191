import { Box, Flex, Spinner } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Heading } from "components/Heading";
import { QuoteCard } from "components/Pages/CreateRFQ/QuoteCard";
import QuoteTotal from "components/Pages/CreateRFQ/QuoteCard/QuoteTotal";
import { defaultOptions } from "components/Pages/CreateRFQ/QuoteCard/tableDefaultOptions";
import { FileUpload } from "components/RFQ/FileUpload";
import { FieldForm } from "components/RFQ/Input";
import { useField } from "formik";
import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import tw, { styled } from "twin.macro";
import { reorderList } from "utils/reorderList";

const QuoteContainer = styled.div(() => [tw`flex flex-col w-full`]);

const ButtonAddContent = ({ onClickAddTable }) => {
  return (
    <Box className="z-40 card-shadow bg-white rounded-md p-2">
      <Flex
        onClick={onClickAddTable}
        className="relative gap-2 items-center hover:bg-utility-blue-light-50 cursor-pointer p-3 hover:rounded-md transition-all [transition-duration: 0.18s]"
      >
        <Icon
          icon="plus-circle"
          style={{ color: "#475467" }}
        />
        <p
          className="font-roboto font-medium text-text-secondary-700"
          style={{ fontSize: "14px", marginTop: "1px" }}
        >
          Add Table
        </p>
      </Flex>
    </Box>
  );
};
const QuestionerContainer = styled.div(({ isDisabled }) => [
  !isDisabled ? tw`opacity-100` : tw`opacity-50 pointer-events-none`,
]);

const Quote = ({ subTitlePage, titlePage, unitMeasures }) => {
  const isLoading = false;

  const [, meta, helper] = useField("quote");
  const [, { value: typeValue }] = useField("quote_type");

  const quotesTable = meta.value;
  const setQuotesTable = helper.setValue;

  const tableTypeOptions = [
    { label: "Create my own quote format", value: "form", icon: "text-input" },
    { label: "Upload Template", value: "file", icon: "template" },
  ];

  const tableVariable = [
    "#",
    "Description of Scope",
    "Unit of Measurement",
    "Quantity",
    "Rate",
    "Cost",
  ];

  const addTableHandler = () => {
    helper.setValue([
      ...quotesTable,
      {
        name: "",
        unit_id: "",
        items: defaultOptions(),
        sort_order: quotesTable.length + 1,
        required: 1,
        isRemove: 0,
      },
    ]);
  };

  const dragHandler = (result) => {
    let value = quotesTable;
    let valueSetter = (newValue) => (value = newValue);
    reorderList(value, result, valueSetter);
    const reordered = value.map((item, index) => ({
      ...item,
      sort_order: index + 1,
    }));
    setQuotesTable(reordered);
  };

  useEffect(() => {
    if (quotesTable.length === 0) {
      addTableHandler();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <QuoteContainer>
      <div
        id="subheader-page"
        className="py-8"
      >
        <div
          id="subheader-page-title"
          className="text-xl font-semibold text-text-primary-900"
        >
          <Heading
            fontSize={"36px"}
            color="#003CE9"
          >
            {" "}
            {titlePage}
          </Heading>
        </div>
        <div
          id="subheader-page-subtitle"
          className="font-roboto text-sm text-text-tertiary-600 mt-1 whitespace-pre-line leading-[1.5rem]"
        >
          {subTitlePage}
        </div>
      </div>
      <div>
        <div className="p-8 bg-[#EEF5FFB2] space-y-5 rounded-md">
          <p className="text-base text-text-tertiary-600">
            Select an option below to get started:
          </p>
          <FieldForm
            name="quote_type"
            type="select"
            isContainImg={true}
            options={tableTypeOptions}
            placeholder="Select"
            className="!max-w-[340px]"
          />
        </div>
      </div>
      <div
        id="project-detail-content"
        className="h-full relative min-h-[60vh]"
      >
        {!isLoading ? (
          typeValue !== "file" ? (
            <>
              <QuestionerContainer>
                <DragDropContext
                  onDragEnd={dragHandler}
                  className="my-10"
                >
                  <Droppable
                    droppableId="questioner-container"
                    direction="vertical"
                  >
                    {(provided) => (
                      <div
                        id="questioner-container"
                        className="mb-8"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {quotesTable?.map((tableData, index) =>
                          !tableData.isRemove ? (
                            <Draggable
                              key={`tableData-${index}`}
                              draggableId={`tableData-${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <QuoteCard
                                    dragHandle={provided.dragHandleProps}
                                    key={index}
                                    initialDataItem={tableData}
                                    tableVariable={tableVariable}
                                    index={index}
                                    quotesTable={quotesTable}
                                    setQuotesTable={setQuotesTable}
                                    unitMeasures={unitMeasures}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ) : null
                        )}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <Flex
                  gap={4}
                  className="my-6"
                >
                  <QuoteTotal />
                </Flex>
                <Flex className="my-6">
                  <ButtonAddContent onClickAddTable={() => addTableHandler()} />
                </Flex>
              </QuestionerContainer>
            </>
          ) : (
            <div className="mt-12">
              <FileUpload
                className="!min-h-[400px]"
                label="Upload template"
                name="quote_file"
                types={["xlsx", "csv"]}
                maxFileSize={10}
                singleFile
                required={typeValue === "file"}
                inputBoxClassName="!min-h-[300px]"
              />
            </div>
          )
        ) : (
          <Spinner
            className="absolute left-1/2 -translate-x-1/2 top-[30vh] "
            size="xl"
          />
        )}
      </div>
    </QuoteContainer>
  );
};

export default Quote;
