import { Flex } from "@chakra-ui/react";
import { Link } from "@reach/router";
import { Icon } from "assets/icons/Icon";
import { EditDropdown } from "components/Pages/RFQ/EditDropdown";
import { Avatar } from "components/RFQ/Avatar";
import GlobalAvatarGroup from "components/RFQ/AvatarGroup/AvatarGroup";
import { Button } from "components/RFQ/Button";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import { ProgressCircular } from "components/RFQ/ProgressIndicator";
import StatusBadge from "components/RFQ/StatusBadge/StatusBadge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/RFQ/Table";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import tw, { styled, css } from "twin.macro";
import { isRFQEditable, isRFQSubmissionDateExtendable, timezoneChecker } from "utils/helpers";
import ExtendSubmissionDeadlineModal from "../../RFQDetail/RFQUpdateSubmissionDeadline";
import { useDispatch } from "react-redux";
import * as constants from "actions/RFQ/actionTypes"

const ListViewStyled = styled.div(() => [
  tw`py-8`,
  css`
    .title {
      font-weight: 500;
      font-family: Roboto;
      font-size: 14px;
    }

    .text {
      font-weight: 400;
      font-family: Roboto;
      font-size: 14px;
    }
  `,
]);

const SkeletonCustom = styled.div(() => [
  tw`animate-pulse bg-gray-300 w-full h-6`,
]);

const SkeleteonRow = () => (
  <TableRow>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
  </TableRow>
);

const RFQListView = ({ currentTab, currPage, refreshData, onOpen, setModalType }) => {
  const rfqsData = useSelector(
    (state) => state.rfq.rfqs?.rfqs?.[currentTab]?.data
  );
  const isLoading = useSelector((state) => state.rfq.rfqs?.loading);
  const totalUser = (suppliers) => {
    const all = suppliers.all?.length || 0
    const accepted = suppliers.accepted?.length + suppliers.submitted?.length 
    const submitted = suppliers.submitted?.length
    return {all,accepted,submitted}
  }
  const [isExtendDeadlineModalOpen,setIsExtendDeadlineModalOpen] = useState(false);
  const [rfqData,setRfqData] = useState(null)
  const dispatch = useDispatch();

  const onSubmissionDateExtension = (rfqData) =>{
    dispatch({type:constants.UPDATE_RFQ_DATA,payload:{rfq:rfqData,currentTab:currentTab}})
  }
  const handleExtendDeadlineModal = (rfqData) => {
    setIsExtendDeadlineModalOpen(true)
    setRfqData(rfqData)
  }

  return (
    <ListViewStyled>
      <div className="card-shadow rounded-md">
        <Table
          containerClass={tw`w-full border rounded-md`}
        >
          <TableHeader>
            <TableRow>
              <TableHead firstCol={true}>
                <div
                  className="flex"
                  style={{ gap: "8px", alignItems: "center" }}
                >
                  Project
                </div>
              </TableHead>
              <TableHead className={tw`max-w-[280px]`}>
                Project Description
              </TableHead>
              <TableHead className={tw`max-w-[218px]`}>Status</TableHead>
              <TableHead className={tw`max-w-[218px]`}>
                Submission Deadline
              </TableHead>
              <TableHead className={tw`max-w-[250px]`}>Company</TableHead>
              <TableHead className={tw`max-w-[108px]`}>Accepted</TableHead>
              <TableHead className={tw`max-w-[108px]`}>Submitted</TableHead>
              <TableHead className={tw`max-w-[68px]`}>
                <div className="hidden">Icon</div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading && currPage === 1 && !rfqsData ? (
              [...Array(4)].map(() => <SkeleteonRow />)
            ) : rfqsData?.length > 0 ? (
              <>
                {rfqsData?.length > 0 &&
                  rfqsData.map((rfq) => {
                    const fullNameRFQWithSlug = rfq?.name
                    const slug = fullNameRFQWithSlug?.toLowerCase().replace(/ /g, "-") + "";
                    const {all,accepted,submitted} = totalUser(rfq?.supplierInvited)
                    const isEditable = isRFQEditable(rfq?.status);
                    const isSubmissionDateExtendable = isRFQSubmissionDateExtendable(rfq?.status);
                    return (
                      <TableRow className="hover:bg-utility-blue-light-50/30">
                        <Link to={`${rfq?.id}/${slug}`} className="text-text-primary-900">
                          <TableCell firstCol={true} className={tw`min-w-[380px] drop-shadow-none bg-transparent`}>
                            <div
                              className="flex gap-3"
                              style={{
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Avatar avatar={rfq?.project?.logoPath} />
                              <div className="grid">
                                <p className="title text-text-primary-900 font-medium font-roboto text-sm">{rfq?.name}</p>
                                <p className="text-sm text-text-primary-500 font-roboto font-normal ">
                                  {rfq?.supplierList?.name}
                                </p>
                              </div>
                            </div>
                          </TableCell>
                        </Link>
                        <TableCell className={tw`max-w-[280px]`}>
                          <p
                            className="text line-clamp-2"
                            dangerouslySetInnerHTML={{ __html: rfq?.description }}
                          />
                        </TableCell>
                        <TableCell className={tw`w-[120px]`}>
                          <p className="text">
                            <StatusBadge status={rfq?.status} />
                          </p>
                        </TableCell>
                        <TableCell className={tw`max-w-[218px]`}>
                          <p className="text font-semibold">
                            {rfq?.submissionDeadline
                              ? moment(rfq?.submissionDeadline)
                                .tz(timezoneChecker(rfq?.submissionTimezone || 'AWST'))
                                .format("dddd DD MMMM YYYY")
                                
                              : "-"}
                          </p>
                          <p className="text text-gray-400">
                            {rfq?.submissionDeadline
                              ? moment(rfq?.submissionDeadline)
                                .tz(timezoneChecker(rfq?.submissionTimezone || 'AWST'))
                                .format("HH:mm a ([GMT] Z)")
                              : "-"}
                          </p>
                        </TableCell>
                        <TableCell className={tw`max-w-[250px]`}>
                          <Flex gap={2} align={"center"}>
                            {rfq?.supplierInvited?.all?.length > 0 ? (
                              <>
                                <p className="font-normal text-sm text-gray-400 text">
                                  {rfq?.supplierInvited?.all?.length} users
                                </p>
                                <GlobalAvatarGroup
                                  maxAvatars={2}
                                  avatarSize="sm"
                                  avatars={
                                    rfq?.supplierInvited?.all?.length > 0
                                      ? rfq?.supplierInvited?.all?.map(
                                        (company) => ({
                                          avatar: company?.company?.logo_path,
                                        })
                                      )
                                      : []
                                  }
                                />
                              </>
                            ) : (
                              <div
                                style={{
                                  border: "solid 2px #F2F4F7",
                                  fontSize: "12px",
                                  textAlign: "center",
                                  fontWeight: 500,
                                  width: "28px",
                                  height: "28px",
                                  marginLeft: "8px",
                                }}
                                className="rounded-full pt-0.5"
                              >
                                {"-"}
                              </div>
                            )}
                          </Flex>
                        </TableCell>
                        <TableCell className={tw`max-w-[218px]`}>
                          <ProgressCircular
                            detail={
                              all > 0
                                ? `${accepted} / ${all}`
                                : "-"
                            }
                            progress={
                              (all > 0
                                ? (accepted /
                                  all) * 100 
                                : 0
                              )
                            }
                            className="-translate-y-1/4"
                            half
                            size="xs"
                          />
                        </TableCell>
                        <TableCell className={tw`max-w-[218px]`}>
                          <ProgressCircular
                            detail={
                              accepted > 0
                                ? `${submitted} / ${accepted}`
                                : "-"
                            }
                            progress={
                              submitted > 0 ? ((submitted / accepted) * 100) : 0 }
                            className="-translate-y-1/4"
                            half
                            size="xs"
                          />
                        </TableCell>
                        <TableCell className={tw`max-w-[68px]`}>
                          <EditDropdown 
                            rfqData={rfq} 
                            currentTab={currentTab} 
                            isEditable={isEditable} 
                            refreshData={refreshData}
                            isSubmissionDateExtendable={isSubmissionDateExtendable}
                            onExtendDateClick={handleExtendDeadlineModal}
                            navigateOnView={`${rfq?.id}/${slug}`}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {isLoading &&
                  currPage >= 1 &&
                  rfqsData &&
                  [...Array(2)].map(() => <SkeleteonRow />)}
              </>
            ) : (
              <TableRow className="col-span-5 h-[500px]">
                <div className="absolute -translate-x-2/4 left-2/4">
                <NotFoundDatas
                  text={`No RFxs found`}
                  description={`To get started add a New RFx.`}
                >
                   {currentTab!=="archived" && <Button style={{ maxHeight: "48px" }} onClick={() => { onOpen(); setModalType('createRFQ') }}>
                    <Flex className='items-center gap-[6px]'>
                      <div className='w-5 h-5 items-center justify-center flex'>
                        <Icon icon='plus' style={{ width: '12px', height: '12px', color: '#fff', fontWeight: 'bold' }} />
                      </div>
                      <div className='font-roboto capitalize font-semibold text-base'>
                        Create RFx
                      </div>
                    </Flex>
                  </Button>}
                </NotFoundDatas>
                </div>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <ExtendSubmissionDeadlineModal
          isModalOpen={isExtendDeadlineModalOpen} 
          rfqDetail={rfqData} 
          onSubmissionDateExtension={onSubmissionDateExtension}
          onClose={()=>setIsExtendDeadlineModalOpen(false)}
            />
    </ListViewStyled>
  );
};

export default RFQListView;
