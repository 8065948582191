import React, { useRef } from "react";
import TabsComp from "components/RFQ/TabsComp/TabsComp";
import { TabHeader } from "components/TabHeader";
import { CompaniesListing } from "components/CompaniesListing";
import { Documents } from "./Documents";
import {
  isAdmin,
  isBidder,
  isProcurer,
  isProcurerNBidder,
  isSuperAdmin,
} from "utils/helpers";
import { useSelector } from "react-redux";
import { Resources } from "./Resources";
import { ProjectListing } from "./ProjectListing";
import { CompanyLocationForm } from "components/CompanyLocationForm";
import { CompanyInformation } from "./CompanyInformation";
import { navigate } from "@reach/router";
import { LogsComponent } from "components/Logs";

const CompanyProfileV2 = ({ company,activeTab }) => {
  const { user } = useSelector((state) => state?.auth);

  const allowedOfficeLocations =
    isProcurerNBidder(company) ||
    isBidder(company) ||
    isProcurer(company) ||
    isSuperAdmin();
  const allowedDocument =
    isAdmin(user) || isSuperAdmin() || isProcurer(company);

  const { documents, resources, requestingResources, pastProjects } =
    useSelector((state) => state?.companyProfile);
  const COMPANYINFORMATION = "Company Information";
  const OFFICELOCATIONS = "Office Locations";
  const PROJECTEXPERIENCES = "Projects and Experience";
  const KEYPERSONNELEXPERTISE = "Key Personnel and Expertise";
  const CORPORATEDOCUMENTS = "Corporate Documents";
  const SHAREDPROFILES = "Shared Profiles";
  const LOGS = "Logs";
  const formikRef = useRef(null);
  const btnOfficeLocation = useRef(null);

  const tabMenu = [
    {
      id:'company-information',
      name: COMPANYINFORMATION,
      showBadge: false,
      total: 0,
      disabled: false,
    },
    {
      id:'company-locations',
      name: OFFICELOCATIONS,
      showBadge: false,
      total: 0,
      disabled: !allowedOfficeLocations,
    },
    {
      id:'projects',
      name: PROJECTEXPERIENCES,
      showBadge: false,
      total: 0,
      disabled: false,
    },
    {
      id:'resources',
      name: KEYPERSONNELEXPERTISE,
      showBadge: false,
      total: 0,
      disabled: false,
    },
    {
      id:'documents',
      name: CORPORATEDOCUMENTS,
      showBadge: false,
      total: 0,
      disabled: !allowedDocument,
    },
    {
      id:'shared-companies',
      name: SHAREDPROFILES,
      showBadge: false,
      total: 0,
      disabled: false,
    },
    {
      id:'logs',
      name: LOGS,
      showBadge: false,
      total: 0,
      disabled: false,
    },
  ];
  const currentTab = tabMenu.find((menu)=>menu.id===activeTab)?.name

  const changeActiveTab = (name,id) => {
    navigate(`./${id}`)
  };

  return (
    <div>
      <TabHeader
        heading="COMPANY PROFILE"
        description=""
      />
      <div className="mt-5 mb-5">
        <div className={"flex gap-3"}>
          <TabsComp
            menus={tabMenu}
            activeTab={currentTab}
            changeActiveTab={changeActiveTab}
            isLoading={false}
          ></TabsComp>
        </div>
      </div>
      <div className="border-b border-gray-200"></div>
      <div className="mt-12">
        {currentTab === COMPANYINFORMATION && (
          <CompanyInformation formikRef={formikRef} />
        )}
        {currentTab === OFFICELOCATIONS && (
          <>
            {allowedOfficeLocations && (
              <CompanyLocationForm
                company={company}
                btnOfficeLocation={btnOfficeLocation}
              />
            )}
          </>
        )}
        {currentTab === PROJECTEXPERIENCES && (
          <ProjectListing
            path="projects"
            projects={pastProjects}
            companyId={company?.id}
          />
        )}
        {currentTab === KEYPERSONNELEXPERTISE && (
          <Resources
            path="resources/*"
            resources={resources}
            isRequesting={requestingResources}
            companyId={company.id}
          />
        )}
        {currentTab === CORPORATEDOCUMENTS && (
          <>
            {allowedDocument && (
              <Documents
                path="documents"
                documents={documents}
                editable
              />
            )}
          </>
        )}
        {currentTab === SHAREDPROFILES && (
          <CompaniesListing company={company}></CompaniesListing>
        )}
        {currentTab === LOGS && (
          <LogsComponent company={company} />
        )}
      </div>
    </div>
  );
};
export default CompanyProfileV2;
