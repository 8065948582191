import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ContentContainer } from "../components/Layout";
import { ContextMenu } from "../components/ContextMenu";
import RfqOverview from "pages/GuestFlow/RfqOverviews/RfqOverview";
import { Breadcrumbs } from "components/Breadcrumb";
import RFQPreview from "pages/RFQSubmission/RFQPreview/RFQPreview";
import { NotFound } from "./NotFound";

const AccountPageWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  ${ContentContainer} {
    /* margin-left: 320px; */
  }
`;

export const GuestComponent = ({

  ...props
}) => {
  useEffect(() => {

  }, []);

  return (
    <>
      <AccountPageWrap>
        <ContextMenu name="sdfsf" logo="sdf" />
        <ContentContainer id='element-scroll'>
          <Breadcrumbs ignoreCompany={true}/>
          <Router primary={false}>
            <RfqOverview path="/request-for-quote" />
            {/* <RFQPreview  path="/request-for-quote/:id" isGuest /> */}
            <RFQPreview  path="/request-for-quote/:id/:currentTab" isGuest />
            <NotFound default />
          </Router>
        </ContentContainer>
      </AccountPageWrap>
    </>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

export const Guest = connect(mapStateToProps, {

})(GuestComponent);
