import { Box, Flex } from '@chakra-ui/react';
import { DateInput } from 'components/DateInput';
import { FormatedInput } from 'components/FormInputs/FormatedInput';
import { Location } from 'components/FormInputs/Location';
import { FileCard } from 'components/RFQ/FileCard';
import { FileUpload } from 'components/RFQ/FileUpload';
import { FieldForm, Input } from 'components/RFQ/Input';
import React from 'react';
import { cn } from 'utils/helpers';
import { CorporateDocument, KeyPersonnel, ProjectCard } from './PreviewSubmissionComponent';
import { CheckboxField } from 'components/RFQ/Checkbox';
import tw, { styled ,css} from 'twin.macro';
import { QUESTION_TYPES } from 'components/Pages/CreateRFQ/ReturnableSchedulesCard/QuestionTypeComponent';

const TextContainer = styled.div(() => [
  tw`w-full max-w-[1144px]`,
  css`
  *{
    word-wrap: break-word;
    max-width: '1144px';
    color : #5B6B88 !important;
   }
  `
]);

const SectionComponent = ({ className, sectionTitle,fieldValue,preview, ...props }) => {
  return (
    <div className={cn('min-h-14 border-b mt-6',preview && 'mt-10', className)} {...props}>
      <h1 className='text-xl font-bold text-text-secondary-700'>
        {sectionTitle}
      </h1>
    </div>
  )
};

const TextAreaQuestionnaire = ({ className,description, question, typeQuestion, required, fieldValue, disable, ...props }) => {
  return (
    <div className={cn('space-y-2',className)} {...props}>
      <div class="space-y-0">
        <h1 className='text-lg font-semibold mt-4 text-text-secondary-700'>
          {question}
        </h1>
        {description && !props?.preview && <p className='text-text-tertiary-600 text-sm'>{description}</p>}
      </div>
      <div>
        {props?.preview ? 
          <TextContainer dangerouslySetInnerHTML={{__html:props?.values}} className='break-words' />
            :
          <FormatedInput
            characterLimit={typeQuestion === 'short' ? 500 : 1500}
            rfq
            className="w-full my-4"
            required={required}
            placeholder="Enter a description..."
            disabled={disable ?? false}
            subTitle={
              typeQuestion === 'short' ? "Maximum of 500 characters." : "Maximum of 1500 characters."
            }
            {...props}
          />}
      </div>
    </div>
  )
};

const SingleChoiceQuestionnaire = ({ className,description, question, options, required, fieldValue, disable, ...props }) => {
  return (
    <div className={cn('space-y-2',className)} {...props}>
      <h1 className='text-lg font-semibold mt-4 text-text-secondary-700'>
        {question}
      </h1>
      {description && !props?.preview &&  <p className='text-text-secondary-600'>{description}</p>}
      <div className={`${!props?.preview && 'ml-2 pb-0.5'}`}>
          {props?.preview ?
            <p>{props?.values}</p>
            :
            <Input
                type='radio'
                className="w-full my-3"
                options={fieldValue.map((item,i) => ({label:item,value:i+1}))}
                disabled={disable ?? false}
                name={'radio'}
              />}
      </div>
    </div>
  )
}

const MultipleChoiceQuestionnaire = ({ className,description, question, required, options,fieldValue, disable, ...props }) => {
  return (
    <div className={cn('space-y-2',className)} >
      <h1 className='text-lg font-semibold mt-4 text-text-secondary-700'>
        {question}
      </h1>
      {description && !props?.preview && <p className='text-text-secondary-600'>{description}</p>}
      <div className={`${!props?.preview && 'py-3 ml-2'}`}>
        {props?.preview ? 
          <p>{props?.values}</p>
          :
          <CheckboxField
            type='checkbox'
            name='questionnaire'
            option={fieldValue?.map((item)=>({label:item,value:item}))}
            disabled={disable ?? false}
            className="w-full -mt-1 "
          />}
      </div>
    </div>
  )
}

const DropdownQuestionnaire = ({ className, question, options, required,fieldValue, disable, ...props }) => {
  return (
    <div className={cn('space-y-2',className)} >
      <h1 className='text-lg font-semibold mt-4 text-text-secondary-700'>
        {question}
      </h1>
      <div>
        {props?.preview ?
          <p>{props?.values}</p>
          :<FieldForm
            type='select'
            options={fieldValue.map((item,i) => ({label:item,value:item}))}
            className="w-full my-4"
            required={required}
            disabled={disable ?? false}
            name={props.name}
          />}
      </div>
    </div>
  )

}

const DateSelectionQuestionnaire = ({ className, question, required,fieldValue, disable, ...props }) => {
  return (
    <div className={cn('space-y-2',className)} >
      <h1 className='text-lg font-semibold mt-4 text-text-secondary-700'>
        {question}
      </h1>
      <div>
       {props?.preview ?
        <p>{props?.values}</p>
        :<DateInput
            icon={true}
            className="!font-regular !border-fg-disabled_subtle flex-3 my-4"
            required={required}
            disabled={disable ?? false}
            {...props}
          />}
      </div>
    </div>
  )

}

const AddressFinderQuestionnaire = ({ className, question, required, fieldValue, disable, ...props }) => {
  return (
    <div className={cn('space-y-2',className)} >
      <h1 className='text-lg font-semibold mt-4 text-text-secondary-700'>
        {question}
      </h1>
      <div>
        {props?.preview ? 
          <p>{props?.values}</p>
          :
          <Location
            errorBottom={"-3px"}
            rfq
            className="relative my-4"
            required={required}
            disabled={disable ?? false}
            {...props}
          />}
      </div>
    </div>
  )

}

const SmartFieldQuestionnaire = ({ className, question, values, required , fieldValue, smartField, disable, ...props }) => {

  const renderSmartField = (values) => {
    switch (smartField) {
      case 'Project Experience':
        return <ProjectCard values={values} isPreview />;
      case 'Key Personel':
      case 'Key Personnel':
        return <KeyPersonnel values={values} isPreview />
      case 'Corporate Documents':
        return <CorporateDocument values={values} />
      default:
        return null;
    }
  }

  return (
    <div className={cn('space-y-2',className)} {...props}>
      <h1 className='text-lg font-semibold mt-4 text-text-secondary-700'>
        {smartField}
      </h1>
      <div>
        {props?.preview ? 
          <Flex className='gap-8 overflow-x-auto'>
          {
            smartField === 'Corporate Documents'
              ?
                <CorporateDocument values={values} isPreview={true} />
              :
            values?.length > 0 && Array.isArray(values) &&
              values.map((value) => {
                return renderSmartField(value)
              })
          }
          </Flex>
          :
          <Input
            type='text'
            className="w-full my-4"
            disabled={true}
            placeholder={smartField}
          />
        }
      </div>
    </div>
  )
}

const FileInputQuestionnaire = ({ className, question, required, fieldValue, disable, ...props }) => {
  return (
    <div className={cn('space-y-2',className)} {...props}>
      <h1 className='text-lg font-semibold mt-4 text-text-secondary-700'>
        {question}
      </h1>
      <div>
        {props?.preview ? 
        Array.isArray(props?.values) && props?.values.length > 0 && 
        props?.values?.map((file) => {
          return (
            <FileCard 
              file={{name: file?.fileName || 'File', file_size: file?.fileSize || null, path: file?.filePath}} 
              isPreview
              className='!w-1/3'
          />)
        })
        :
        <FileUpload
          type='file'
          name='questionnaire'
          className="w-full my-4"
          types={["pdf", "docx", "xlsx", "jpeg", "jpg"]}
          defaultFileName="Document"
          disabled={disable}
        />}
      </div>
    </div>
  )
}

const fieldType = {
  [QUESTION_TYPES.SHORT_ANSWER] : {
    type: 'short',
    description: 'Maximum of 500 characters.'
  },
  [QUESTION_TYPES.PARAGRAPH]: {
    type: 'long',
    description: 'Maximum of 1500 characters.'
  },
  [QUESTION_TYPES.SINGLE_OPTION]: {
    type: 'singleChoice',
    description: null
  },
  [QUESTION_TYPES.MULTIPLE_CHOICE]: {
    type: 'multipleChoice',
    description: null
  },
  [QUESTION_TYPES.DROP_DOWN]: {
    type: 'dropdown',
    description: null
  },
  [QUESTION_TYPES.DATE_SELECTION]: {
    type: 'date',
    description: 'Single-date selection'
  },
  [QUESTION_TYPES.ADDRESS_FINDER]: {
    type: 'address',
    description: null
  },
  [QUESTION_TYPES.PROJECT_EXPERIENCE]: {
    type: 'projectexp',
    description: null
  },
  [QUESTION_TYPES.KEY_PERSONNEL]: {
    type: 'keypersonel',
    description: null
  },
  [QUESTION_TYPES.CORPORATE_DOCUMENTS]: {
    type: 'corporatedoc',
    description: null
  },
  [QUESTION_TYPES.FILE_UPLOAD]: {
    type: 'file',
    description: null
  },
}


const Questionnaire = ({ questionnaire, preview = false, disable = false, isGuest ,handleSmartFieldDetailView,...props }) => {

  const renderQuestion = (question, index) => {
    switch (question.type) {
      case QUESTION_TYPES.SHORT_ANSWER:
        return <TextAreaQuestionnaire key={index} {...question} preview={preview} disable={disable} />;
      case QUESTION_TYPES.PARAGRAPH:
        return <TextAreaQuestionnaire key={index} {...question} preview={preview} disable={disable} />;
      case QUESTION_TYPES.SINGLE_OPTION:
        return <SingleChoiceQuestionnaire key={index} {...question} preview={preview} disable={disable} />;
      case QUESTION_TYPES.MULTIPLE_CHOICE:
        return <MultipleChoiceQuestionnaire key={index} {...question} preview={preview} disable={disable} />;
      case QUESTION_TYPES.DROP_DOWN:
        return <DropdownQuestionnaire key={index} {...question} preview={preview} disable={disable} />;
      case QUESTION_TYPES.DATE_SELECTION:
        return <DateSelectionQuestionnaire key={index} {...question} preview={preview} disable={disable} />;
      case QUESTION_TYPES.ADDRESS_FINDER:
        return <AddressFinderQuestionnaire key={index} {...question} preview={preview} disable={disable} />;
      case QUESTION_TYPES.PROJECT_EXPERIENCE:
        return isGuest 
        ? <TextAreaQuestionnaire key={index} {...question} preview={preview} disable={disable} question='Project Experience' /> 
        : <SmartFieldQuestionnaire key={index}  smartField="Project Experience" {...question} preview={preview} />;
      case QUESTION_TYPES.KEY_PERSONNEL:
        return isGuest 
        ? <TextAreaQuestionnaire key={index} {...question} preview={preview} disable={disable} question='Key Personnel' /> 
        : <SmartFieldQuestionnaire key={index} smartField="Key Personnel" {...question} preview={preview} />;
      case QUESTION_TYPES.CORPORATE_DOCUMENTS:
        return isGuest 
        ? <FileInputQuestionnaire key={index} {...question} preview={preview} disable={disable} question='Corporate Document' /> 
        : <SmartFieldQuestionnaire key={index} smartField="Corporate Documents" {...question}  preview={preview} />;
      case QUESTION_TYPES.FILE_UPLOAD:
        return <FileInputQuestionnaire key={index} {...question} disable={disable} preview={preview} />;
      default:
        return null;
    }
  }

  return (
    <Box className='min-h-80 w-full border rounded-md shadow-xl p-10 pt-0' {...props}>
      {questionnaire?.map((section,sectionID)=>{
        const questions = section.question || section.questionnaire
        return !section.isRemove  ? (
          <div key={section.id}>
            <SectionComponent sectionTitle={section?.name || ' - '} preview={preview}/>
            {questions?.map((question, index) =>
            {
                const questionProps = {
                  type: question.rfq_question_type_id || question?.type,
                  name: `questionnaire[${sectionID}]question[${index}]` || question?.name,
                  question: question?.name,
                  typeQuestion: question?.type || fieldType[question.rfq_question_type_id].type,
                  fieldValue: question?.fields?.option || question?.fields || [],
                  // description: fieldType[question.rfq_question_type_id].description || "-",
                  values: question?.values || ' - ' ,
                  required:question?.required

                }
                return renderQuestion(questionProps, index)
            })}
          </div>
        ): null;
      })}
    </Box>
  )
}

export default Questionnaire
