import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  SearchBoxSubmit,
  SearchBoxInputContainer,
  SearchBoxInputStyled,
  SectorsStyled,
  FormStyled,
  SearchBoxButtons,
  SearchBoxContainer,
  Results,
  SearchBoxInputRFQContainer,
  SearchBoxInputRFQStyled,
  SearchIconContainer,
  CancelButton,
} from "./styled";
import { ReactComponent as SearchIcon } from "../../icons/search-2.svg";
import { ReactComponent as CancelIcon } from "../../icons/close.svg";
import { getSectors } from "../../actions/sectorActions";
import tw from "twin.macro";
import { Icon } from "assets/icons/Icon";

const SearchBoxComponent = ({
  handleFormSubmit,
  getSectors,
  sectors,
  searchInput,
  sectorFilter,
  keywordFilter,
  searchProject,
  showPorojectsCount,
  multiSector,
  className,
  whiteInput,
  resultsCount,
  companyId,
  showSubmitButton = false,
  isCompanyList,
  ...props
}) => {
  const initialValues = props.initialValues;

  useEffect(() => {
    getSectors(companyId);
  }, [getSectors, companyId]);

  const selectStyles = () => ({
    control: (styles, {isDisabled}) => ({
      ...styles,
      height: '48px',
      maxHeight: '48px',
      padding : '0 8px',
      borderRadius: '6px',
      ...(props?.touched && props?.error ? tw`focus-within:ring-4 focus-within:!border-utility-error-300 !ring-ring-red-primary` 
        :   tw`focus-within:ring-4 focus-within:!border-utility-brand-300 !ring-ring-blue-primary border border-[#D0D5DD] shadow-sm`)  
    })
  })
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        handleFormSubmit(values);
      }}
    >
      {(props) => {
        const { setFieldValue, values, handleSubmit } = props;

        //seems like the only way to re-submit form with altered values
        // const cancelClick = () => {
        //   setFieldValue("search", "", false);
        //   handleFormSubmit({ ...values, search: "" });
        // };

        const removeKeyword = () => {
          setFieldValue("keyword", "", false);
          handleFormSubmit({ ...values, keyword: "" });
        };

        return (
          <SearchBoxContainer className={className}>
            {resultsCount > 0 && (
              <Results>
                {resultsCount} project{resultsCount > 1 && "s"} found
              </Results>
            )}

            <FormStyled>
              {searchInput && (
                <SearchBoxInputContainer>
                  <SearchBoxInputStyled
                    type="text"
                    name="search"
                    placeholder="Search by name"
                    white={whiteInput}
                  />
                  <Icon icon='search' style={{width:'20px', height:'20px'}} className='absolute top-1/2 -translate-y-1/2 left-3' />
                  <SearchBoxButtons>
                    <SearchBoxSubmit type="submit" name="submit" />
                  </SearchBoxButtons>
                </SearchBoxInputContainer>
              )}
              {keywordFilter && (
                <SearchBoxInputContainer>
                  <SearchBoxInputStyled
                    type="text"
                    name="keyword"
                    placeholder="Search by keyword"
                    white={whiteInput}
                    isNoPadding ={isCompanyList}
                  />
                 { !isCompanyList && <Icon icon='search' style={{width:'20px', height:'20px'}} className='absolute top-1/2 -translate-y-1/2 left-3' />}
                  <SearchBoxButtons>
                  {isCompanyList
                    ? <>
                        {values?.keyword &&<CancelButton type="button" onClick={removeKeyword}>
                          <CancelIcon/>
                        </CancelButton>}
                        <SearchBoxSubmit  disabled={!values?.keyword} type="submit" name="submit">
                          <SearchIcon/>
                        </SearchBoxSubmit>
                    </>
                    :<SearchBoxSubmit  disabled={!values?.keyword} type="submit" name="submit"/>
                    }
                  </SearchBoxButtons>
                </SearchBoxInputContainer>
              )}
              {searchProject && (
                <SearchBoxInputRFQContainer>
                  <SearchBoxInputRFQStyled
                    type="text"
                    name="keyword"
                    placeholder="Search"
                  >
                  </SearchBoxInputRFQStyled>
                  <SearchIconContainer>
                    <SearchIcon />
                  </SearchIconContainer>
                </SearchBoxInputRFQContainer>
              )}
              {sectorFilter && (
                <SectorsStyled
                  isSearchBox
                  styles={selectStyles()}
                  noLabel
                  name="sector"
                  setFieldValue={setFieldValue}
                  defaultValue={initialValues.sector}
                  values={values}
                  singleValue={!multiSector}
                  placeholder={`Filter by sector${multiSector ? "s" : ""}`}
                  clearable
                  showPorojectsCount={showPorojectsCount}
                  onChange={(option) => {
                    if (multiSector) {
                      const valuesArray = option
                        ? option.map((option) => option.value)
                        : [];
                      setFieldValue("sector", valuesArray);
                    } else {
                      setFieldValue("sector", option && option.value);
                    }
                    handleSubmit();
                  }}
                />
              )}
            </FormStyled>
          </SearchBoxContainer>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  const sectorData = state.sectors.sectorData;

  return {
    sectors: sectorData,
    initialValues: {
      search: "",
      sector: "",
      keyword: "",
    },
  };
};

export const SearchBox = connect(mapStateToProps, { getSectors })(
  SearchBoxComponent
);
