import * as Yup from "yup";
import {isArray,isFile,isSmartField} from "utils/validation"

export const allValidationSchema = (
    returnable,
    quote,
    isGuest,
    hasDocuments,
    hasPasProjects,
    hasResources,
    isQuoteTypeFile,
    ) => {

    const quoteValidation = isQuoteTypeFile?
    {quote_file: Yup.mixed().when('savedQuoteFile',{
      is: (value) =>  !value,
      then:Yup.mixed().required().test('len','This field is required',(val)=>val && val.length>0)
    })}:
    {quote_answer: Yup.object().shape(quotesSchema(quote))}

    const validationSchema = Yup.object().shape({
        question_answer: Yup.object().shape(returnableSchema(
        returnable,
        isGuest,
        hasDocuments,
        hasPasProjects,
        hasResources
        )),
        ...quoteValidation
    })  
    return validationSchema
};

export const returnableSchema = (
    returnableList,
    isGuest,
    hasDocuments,
    hasPasProjects,
    hasResources
  ) => {
    let returnableSchema = {};
  
    const validateTextAndTextArea = (required,maxLimit) =>{
  
      if(required){
        return Yup.string()
        .required('Required')
        .nullable()
        .test('len','Input has exceeded the max character',(val)=> val?val?.replace(/<\/?[^>]+>/gi, '').length <= maxLimit:false)
      }
  
      return Yup.string()
      .nullable()
      .test('len','Input has exceeded the max character',(val)=> val ? val?.replace(/<\/?[^>]+>/gi, '').length <= maxLimit:true)
    }
  
    const schemaCheck = (
      type,
      required,
      hasDocuments,
      hasPasProjects,
      hasResources
    ) => {
      if (isArray(type)) {
        return required
          ? Yup.array()
              .of(Yup.string("Required").required("Required"))
              .min(1, "Required")
          : Yup.array().of(Yup.string());
      } else if (isSmartField(type)) {
        if (isGuest) {
          if (type === "corporate_documents") {
            return required
              ? Yup.array()
                  .of(Yup.mixed("Required").required("Required"))
                  .test("empty", "Required", (value) => value?.length > 0)
              : Yup.array().of(Yup.mixed());
          } else {
            return required ? Yup.mixed().required("Required") : Yup.mixed();
          }
        } else {
          /**
           * if supplier but no documents show upload field
           */
          if (type === "corporate_documents" && !hasDocuments) {
            return required
              ? Yup.mixed().required("Required")
              : Yup.array().of(Yup.mixed());
          } else if (
            (type === "project_experience" && !hasPasProjects) ||
            (type === "key_personnel" && !hasResources)
          ) {
            /**
             * if supplier but no projects or resources show textarea
             */
            return required ? Yup.mixed().required("Required") : Yup.mixed();
          }
          else {
            return required
              ? Yup.array()
              .nullable()
              .test('is-non-empty-array','Please select at least one',(val)=>{
                if (val === null) {
                  return false;
                }
                return Array.isArray(val) && val.length >0
              })
              : Yup.array().of(Yup.number());
          }
        }
      } else if (isFile(type)) {
        return required
          ? Yup.array()
              .of(Yup.mixed("Required").required("Required"))
              .test("empty", "Required", (value) => value?.length > 0)
          : Yup.array().of(Yup.mixed());
      } else if(type === "text"){
        return validateTextAndTextArea(required,500)
      }else if (type === "textarea"){
        return validateTextAndTextArea(required,1500)
      }
      else {
        return required ? Yup.mixed().required("Required") : Yup.mixed();
      }
    };
  
    returnableList?.forEach((section) => {
      section.question?.forEach((question) => {
        const questionType = question?.fields?.type;
        if (
          isSmartField(questionType) &&
          ((questionType === 'corporate_documents' && !hasDocuments) 
          ||(questionType === 'project_experience' && !hasPasProjects) 
          ||(questionType === 'key_personnel' && !hasResources) ) &&
          !isGuest
        ) {
          returnableSchema[`question-${question.id}`] = Yup.object().shape({
            additional: schemaCheck(
              questionType,
              question.required,
              hasDocuments,
              hasPasProjects,
              hasResources
            ),
          });
        } else {
          returnableSchema[`question-${question.id}`] = schemaCheck(
            questionType,
            question.required,
            hasDocuments,
            hasPasProjects,
            hasResources
          );
        }
      });
    });
  
    return returnableSchema;
  };

  export const quotesSchema = (quoteList) => {
let quotesSchema = {};

quoteList.forEach((quote) => {
    return quote.items.forEach((item) => {
    return quotesSchema[`quote-${item.id}`] = Yup.object().shape({
        quantity: Yup.number("Required")
                .required("Required")
                .test("is-positive", "Required", (value) => parseFloat(value) > 0),
        rate: Yup.number("Required")
            .required("Required")
            .test("is-positive", "Required", (value) => parseFloat(value) > 0),
        unit: Yup.number("Required")
            .required("Required")
            .test("is-positive", "Required", (value) => parseInt(value) > 0),
    });
    });
});
return quotesSchema;
};
  