import { TabHeader } from "components/TabHeader";
import React, { useEffect, useMemo, useState } from "react";
import { Flex, useDisclosure } from "@chakra-ui/react";
import { UtilityButton } from "components/RFQ/UtilityButton";
import { EditRFQDetailButton } from "components/Pages/RFQDetail/EditRFQDetail";
import { InviteSupplierButton } from "components/Pages/RFQDetail/InviteSupplierButton";
import { HeaderCard } from "components/Pages/RFQDetail/HeaderCard";
import * as constant from "../../../../utils/constants";
import { RFQDetailButtonTab } from "components/Pages/RFQDetail/RFQDetailButtonTab";
import RFQListSubmission from "./RFQListSubmission/RFQListSubmission";
import { AddendumClient } from "components/Pages/RFQDetail/AddendumClient";
import { getRFQId } from "actions/RFQ/rfqsAction";
import { getAddendum } from "actions/RFQ/addendumAction";
import AwardSupplierModal from "components/Pages/RFQDetail/Modals/AwardSupplierModal";
import { InviteSupplierDrawer } from "components/Pages/RFQDetail/InviteSupplierDrawer";
import InviteGuestDrawer from "components/Pages/RFQDetail/InviteGuestDrawer/InviteGuestDrawer";
import { BackButton } from "components/RFQ/BackButton";
import { navigate } from "@reach/router";
import { NotFound } from "routes/NotFound";
import { Button } from "components/RFQ/Button";
import { isRFQEditable, isRFQReadyForAward, isRFQSubmissionDateExtendable } from "utils/helpers";
import { useDispatch } from "react-redux";
import apiV2 from "api-v2";
import { removeAllToListDirect } from "actions/searchActions";
import ExtendSubmissionDeadlineModal from "./RFQUpdateSubmissionDeadline";

const RFQDetail = ({ rfqId, location }) => {
  const dispatch = useDispatch()
  const [guestName,setGuestName] = useState()
  const [guestEmail,setGuestEmail] = useState()
  const [rfqDetail, setRfqDetail] = useState(location?.state?.rfqData || null);
 
  const [addendumData, setAddendumData] = useState([]);
  const [totalAddendum, setTotalAddendum] = useState(0);
  const [currentTab, setCurrentTab] = useState(constant.ALL_INVITED_SUPPLIER);
  const [loadRFQData, setLoadRFQData] = useState(false);
  const [checkedRow, setCheckedRow] = useState([]);
  const [rfqName, setRfQName] = useState('')
  const [isNotFound,setIsNotFound] = useState(false)
  const isSubmissionDateExtendable = isRFQSubmissionDateExtendable(rfqDetail?.status);
  const [isExtendDateModelOpen,setIsExtendDateModelOpen] = useState(false);

  const unCheckedList = useMemo(() => {
    const listAllSuppliers = rfqDetail?.supplierList?.suppliers
    const listAllInvitedSuppliers = rfqDetail?.supplierInvited?.all?.filter((invited) => invited?.company ? true : false)
    let listAllInvitedSuppliersId = []
    let tempAllUnInvitedSuppliers = []
    if(rfqDetail){
      for (let a = 0; a < listAllInvitedSuppliers.length; a++) {
        listAllInvitedSuppliersId.push(listAllInvitedSuppliers[a]?.company?.id)
      }
      if (listAllSuppliers.length > listAllInvitedSuppliersId.length) {
        for (let a = 0; a < listAllSuppliers.length; a++) {
          if (!listAllInvitedSuppliersId.includes(listAllSuppliers[a].id)) {
            tempAllUnInvitedSuppliers.push({
              value: listAllSuppliers[a]?.id,
              label: listAllSuppliers[a]?.name
            })
          }
        }}
    }
    return tempAllUnInvitedSuppliers
  }, [rfqDetail]);

  const inviteSupplierHandler = (allInvited) => {
    setRfqDetail(prev => {
      return {...prev, supplierInvited :  allInvited}
  })}

  const refreshData = () => {
    try {
      if (rfqId) {
        setLoadRFQData(true);
        getRFQId(rfqId).then((res) => {
          if (res) {
            let projectName = res?.project?.name
            let supplierName = res?.supplierList?.name
            let currentRFQName = projectName + " | " + supplierName
            setRfQName(currentRFQName)
            setRfqDetail(res);
            getAddendum(res?.id).then((res) => {
              if (res) {
                setAddendumData(res?.data);
                setTotalAddendum(res?.additional?.addendumCounter)
                setLoadRFQData(false);
              } else {
                setLoadRFQData(false);
              }
            })
          }else{
            setIsNotFound(true)
          }
        });
      }
    } catch {
      setIsNotFound(true)
      setLoadRFQData(false);
    }
  }

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line
  }, []);


  const typeGuestName = (gdata) => {
    if(gdata?.guestname !== undefined && gdata?.guestname !==""){
      setGuestName(gdata?.guestname)
    }
    if(gdata?.email !== undefined && gdata?.email !==""){
      setGuestEmail(gdata?.email)
    }
  }

  const [typeModal, setTypeModal] = useState({
    type: "",
    companyId: null,
    projectId: null,

  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const feedBackModalType = [
    "award",
    "archive",
    "decline",
    "pause",
    "cancel",
  ]

  const isSubmitted = (status) => {
    switch(status){
      case constant.STATUS_SUBMITTED :
      case constant.STATUS_AWARDED :
      case constant.STATUS_UNSUCCESSFUL :
        return true
      default :
        return false
    }  
  }

  const projectId = rfqDetail?.project?.id;
  const handleEditRFQ = () => {
    isSubmissionDateExtendable?navigate(`../view-rfx/${rfqId}`):navigate(`../edit-rfx/${rfqId}`);
  }

  const navigateCompareSelected = async (page) => {
    const companyId = rfqDetail?.companyId
    const supplierListId = rfqDetail?.supplierList?.id
    const supplierListName = rfqDetail?.supplierList?.name
    const checkedIds = checkedRow.map(company => company?.company?.id)

    if (page === "shortlist") {
      let payload = {
        companyId: companyId,
        supplierIds: checkedIds,
      };
      let response = await apiV2.post(
        `/supplier/${supplierListId}/shortlist`,
        payload,
        {
          responseType: "blob",
        }
      );
      if (response) {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
    
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    } else {

      await dispatch(removeAllToListDirect());
      setTimeout(() => {
        window.open(
          `/search/direct/compare?supplier=${supplierListId}&supplierlist=${supplierListName}&directcompare=true&suppliers=${checkedIds.join(",")}`,
        );
      }, 100);
    }
  };

  const onSubmissionDateExtension = (rfqData) =>{
    setRfqDetail(rfqData)
  }


  return (
    isNotFound 
    ? <NotFound />
    :<div id="rfq-detail-container" className="overflow-hidden">
      <div id="rfq-detail-wrapper">
        <div id="rfq-detail-header">
          <TabHeader icon={<BackButton handleBackButtonClick={() => navigate("../")} />}>
            <Flex gap={4} align={"center"}>
              <div id="button-project-rfq-edit">
                <EditRFQDetailButton
                  onOpen={onOpen}
                  setTypeModal={setTypeModal}
                  projectId={projectId}
                  rfqId={rfqId}
                />
              </div>
              {isRFQReadyForAward(rfqDetail?.status)&&<UtilityButton className='!h-12 !max-h-12' text={"Award Supplier"} onClick={() => { setTypeModal({ type: "award", companyId: "" }); onOpen() }} />}
              {isSubmissionDateExtendable&&<Button btntype="secondary" onClick={()=>setIsExtendDateModelOpen(true)} >Extend Deadline</Button>}
              {(isRFQEditable(rfqDetail?.status) || isSubmissionDateExtendable) && <Button btntype='secondary' onClick={handleEditRFQ} >{isSubmissionDateExtendable?"View RFx Details":"Edit RFx"}</Button>}
              {isSubmissionDateExtendable && <InviteSupplierButton
                setTypeModal={setTypeModal}
                onOpen={onOpen}
              />}
            </Flex>
          </TabHeader>
        </div>
        <div id="rfq-detail-content" className="px-1">
            <HeaderCard rfqDetail={rfqDetail} isLoading={loadRFQData} />
          <div className="my-9">
            <RFQDetailButtonTab currentTab={currentTab} setCurrentTab={setCurrentTab} rfqDetail={rfqDetail} totalAddendum={totalAddendum} />
          </div>
          {currentTab === "addendum" ? <div>
            <AddendumClient rfqDetail={rfqDetail} addendumData={addendumData} setAddendumData={setAddendumData} />
          </div>
          :<div id="rfq-detail-content-table">
          <RFQListSubmission 
            onOpen={onOpen}
            setTypeModal={setTypeModal} 
            checkedRow={checkedRow} 
            setCheckedRow={setCheckedRow} 
            rfqDetail={rfqDetail} 
            isLoading={loadRFQData} 
            currentTab={currentTab} 
            typeGuestName={typeGuestName}
            isSubmitted={isSubmitted}
            navigateCompareSelected={navigateCompareSelected}
            setGuestEmail={setGuestEmail}
            setGuestName={setGuestName}
          />
        </div>}
          
          
        </div>
      </div>

      {typeModal === "add_supplier" &&
        <InviteSupplierDrawer rfqName={rfqName} 
          onClose={onClose} 
          isOpen={isOpen} 
          rfqId={rfqId} 
          inviteSupplierHandler={inviteSupplierHandler} 
          rfqDetail={rfqDetail}
          unCheckedList={unCheckedList}
        />
      }
      {typeModal === "invite_guest" &&
        <InviteGuestDrawer rfqName={rfqName} rfqId={rfqId} onClose={onClose} isOpen={isOpen} inviteSupplierHandler={inviteSupplierHandler}  />
      }
      {feedBackModalType.includes(typeModal.type) && (
        <AwardSupplierModal
          isOpen={isOpen}
          guestName={guestName}
          guestEmail={guestEmail}
          onClose={onClose}
          rfq={rfqDetail}
          typeModal={typeModal}
          setLoadRFQData={setLoadRFQData}
          refreshData={refreshData}
        />

      )}
      {
        isSubmissionDateExtendable && 
          <ExtendSubmissionDeadlineModal 
            isModalOpen={isExtendDateModelOpen} 
            rfqDetail={rfqDetail} 
            onSubmissionDateExtension={onSubmissionDateExtension}
            onClose={()=>setIsExtendDateModelOpen(false)}
            />
      }
    </div>
  );
};

export default RFQDetail;
