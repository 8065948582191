import { Card, Flex, CircularProgress } from '@chakra-ui/react';
import { Icon } from 'assets/icons/Icon';
import React from 'react';
import { formatBytes } from 'utils/formatBytes';
import { cn, filenameSpliter } from 'utils/helpers';
import { Input } from '../Input/Input';

export const FileCardSelect = ({ file,index,setFiles,deleteHandler,uploadProgress,selectOptions,fileSize,selectValue,draggableProps,className }) => {
    const filename = typeof file === "string" ? file : file?.path || file?.name || file?.fileName || file?.file_name || file?.title || file?.document || '-';
    const filesize = fileSize ? formatBytes(fileSize) : formatBytes(file?.size) || null;
    const ext = filenameSpliter(filename).ext;
    // Calculate the background color based on the upload progress
    let bgColor;
    if (uploadProgress < 50) {
        bgColor = 'red.50';
    } else if (uploadProgress < 75) {
        bgColor = 'yellow.50';
    } else {
        bgColor = 'green.50';
    }

    const changeHandler = (e) => {
        setFiles((prev) => {
            let newArr = [...prev]
            newArr[index].category_id = e.value
            return newArr
        });
    }

    return (
        <Card className={cn(`border border-gray-outline py-3 px-4 my-4 group !bg-white hover:bg-blue-50 hover:border-blue-50 hover:text-purple group ${bgColor} ${className}`)} radius="sm" >
            <Flex className="relative items-center gap-4 justify-between">
                <Flex className="relative items-center gap-4 justify-between" >
                    <div {...draggableProps} >
                    <Icon icon={'sixDots'} size="md" />
                    </div>
                    <Icon icon={ext} size="md" />
                    <div>
                        <p className="font-semibold group-hover:underline text-sm">{filename}</p>
                        {filesize && <p className="font-thin text-sm">{filesize}</p>}
                    </div>
                </Flex>
                <div className='flex gap-4 items-center'>
                    <Input 
                        type='select' 
                        className="w-64" 
                        options={selectOptions} 
                        onChange={changeHandler} 
                        value={selectValue} 
                        fullIndicator
                        size='sm'
                        checkIcon
                    />
                    <div>
                    <div className='flex group-hover:hidden'>
                        <Icon icon="check" />
                    </div>
                    <div className='hidden group-hover:flex bg-gray-300 p-2 rounded-full hover:bg-red-200 cursor-pointer' onClick={deleteHandler}>
                        <Icon icon="trash" style={{width:'16px', height:'16px'}}  />
                    </div>
                    {uploadProgress !== undefined && (
                        <CircularProgress value={uploadProgress} color="green.400" />
                    )}
                </div>
                </div>
            </Flex>
        </Card>
    );
};