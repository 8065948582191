import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { connect } from "react-redux";

import { LoginWrapper, LoginSection, BottomTag, BottomLinks } from "./styled";
import { Heading } from "../Heading";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { guestSignup, guestLogin } from "../../actions/authActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Checkbox2 } from "../FormInputs/Checkbox2";
import { Link } from "@reach/router";
import { FlashError } from "../FormInputs/FlashError";
import { navigate } from "@reach/router";
import { LogoWrapper } from "../LogoWrapper/LogoWrapper";
import { showToast } from "utils/helpers";

const CustomLabel = () => {
  return (
    <p style={{ margin: "0px" }}>
      I agree to the{" "}
      <a href="/terms-and-conditions" target="_blank">
        Terms & Conditions
      </a>{" "}
      and{" "}
      <a href="/privacy-policy" target="_blank">
        Privacy Policy
      </a>
    </p>
  );
};

export const GuestAuthFormComponent = ({ rfqId,withRfqId,isSignUp,...props }) => {


  const successMessage = (
    <>
      <h2>Login Successful</h2>
      <p>Welcome to Procuracon</p>
    </>
  );
  const handleSubmit = async (values, setErrors, setSubmitting, setStatus) => {
    setSubmitting(true);
    setStatus(null);
    if (isSignUp) {
      try {
        await props.guestSignup(values, setSubmitting, setStatus);
      } catch (e) {

        const error = e.response.data;
        console.log(error);
        
        setStatus(true);
        error.errors !== undefined && setErrors(error);
      }
    } else {

      try {
        await props.guestLogin(values,)
        setSubmitting(false)
        showToast(successMessage, "Success", true);
        navigate(`/account/request-for-quote`);
      } catch (e) {
        setSubmitting(false)
        const error = await e.response.json();
        setStatus(error.message);
        error.errors !== undefined && setErrors(error.message);
      }
    }
  };
  return (
    <LoginWrapper>
      <LogoWrapper />

      <LoginSection>
        <Heading marginBottom="40px">
          <span>{isSignUp ? "Guest Sign up" : "Guest Sign in"}</span>
        </Heading>

        <Formik
          enableReinitialize
          initialValues={{ email: "", password: "", rfq_code: rfqId }}
          validationSchema={Yup.object({
            email: Yup.string().email().required(),
            password: Yup.string().required(),
            agreed:
              isSignUp &&
              Yup.boolean()
                .oneOf([true], "Please accept before continuing")
                .required("Please accept before continuing"),
          })}
          onSubmit={(values, { setErrors, setSubmitting, setStatus }) => {
            setStatus(false);
            handleSubmit(values, setErrors, setSubmitting, setStatus);
          }}
        >
          {(props) => {
            const { setFieldValue, isSubmitting, status, setStatus, errors } =
              props;
            const onSubmitValidationError = () => {
              setStatus("required");
            };

            return (
              <Form onChange={() => setStatus(false)}>
                <div className="row">
                  <TextInput
                    label="Enter your email"
                    placeholder="Email"
                    name="email"
                    type="email"
                    className="col xl-12"
                    setFieldValue={setFieldValue}
                  />

                  <TextInput
                    label="Enter your password"
                    placeholder="Password"
                    name="password"
                    type="password"
                    className="col xl-12"
                    setFieldValue={setFieldValue}
                    icon
                  />
                  {!isSignUp ? (
                    <BottomLinks className="col xl-12">
                      <Checkbox2 label="Remember me" name="remember me" />
                      <Link to="/guest/reset-password">Forgot my password</Link>
                    </BottomLinks>
                  ) : (
                    <>
                      <BottomLinks className="col xl-12">
                        <Checkbox2
                          name="agreed"
                          labelComponent={<CustomLabel />}
                          noWarning
                        />
                      </BottomLinks>
                    </>
                  )}
                  <div className="col xl-12">
                    {status && (
                      <FlashError
                        heading={errorMessages[status]?.heading || errors.title || errorMessages.invalid.heading}
                        text={
                          errors.agreed ||
                          errorMessages[status]?.description ||
                          errors?.errors?.email[0] || errorMessages.invalid.description
                        }
                        margin="20px 0px 0px 0px"
                      />
                    )}
                  </div>
                  <div className="col xl-12">
                    <Submit
                      type="submit"
                      widthExpand
                      marginTop={isSignUp && !status ? "0px" : "24px"}
                      isSubmitting={isSubmitting}
                      text={isSignUp ? "sign up" : "sign in"}
                      submittingText={
                        isSignUp ? "Signing up..." : "Logging in..."
                      }
                    />
                  </div>
                </div>
                <OnSubmitValidationError callback={onSubmitValidationError} />
              </Form>
            );
          }}
        </Formik>
        <BottomTag>
          {isSignUp ? (
            <>
              Already have an account?
              <br />
              <div className="flex gap-8 justify-between">
                <Link
                  to={
                    rfqId === null || rfqId === "null"
                      ? `/guest/login`
                      : `/guest/login/${rfqId}`
                  }
                >
                Sign In as a Guest
                </Link>
                <Link to={`/login/=${rfqId}`}>Sign In as a Subscriber</Link>
              </div>
            </>
          ) : (
            <>
              Not Signed Up yet?
              <div className="flex gap-8 justify-between pt">
                <Link to={`/guest/signup/${rfqId}`}>Sign Up as Guest</Link>
                <Link to="/signup">Sign Up as a Subscriber</Link>
              </div>
             
            </>
          )}
        </BottomTag>
      </LoginSection>
    </LoginWrapper>
  );
};

const errorMessages = {
  required: {
    heading: "* Required Field",
    description: "There are incomplete required fields, Please complete them.",
  },
  invalid: {
    heading: "Incorrect Login Details",
    description:
      "Your email address or password do not match our records. Please check your details and try again.",
  },
  suspended: {
    heading: "Incorrect Login Details",
    description:
      "Your password or email address do not match our records. Please check your details and try again.",
  },
  invalid_link: {
    heading: "Invalid Invitation Link",
    description: "Link does not match the invite.",
  },
  connection: {
    heading: "Connection not established",
    description: "Unable to process your request",
  },

  email: {
    heading: "Connection not established",
    description: "Unable to process your request",
  },
};

export const GuestAuthForm = connect(null, {
  guestLogin,
  guestSignup,
})(GuestAuthFormComponent);
