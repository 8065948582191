import { DateInput } from "components/DateInput";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { Location } from "components/FormInputs/Location";
import { Heading } from "components/Heading";
import { FileUpload } from "components/RFQ/FileUpload";
import { FieldForm } from "components/RFQ/Input";
import { cn } from "utils/helpers";
import { MultipleCheckbox } from "../MultipleCheckbox";
import { Flex } from "@chakra-ui/react";
import {
  CorporateDocument,
  KeyPersonnel,
  KeyPersonnelSkeleton,
  ProjectCard,
  ProjectCardSkeleton,
} from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire/PreviewSubmissionComponent";
import { useEffect, useState } from "react";
import { getListCompanyData } from "actions/RFQ/Supplier/rfqActions";
import SearchBar from "../SearchBar/SearchBar";
import FilterDrawer from "../CustomFilterDrawer/FilterDrawer";
import FilterButton from "components/FilterButton/FilterButton";
import { useField } from "formik";
import { useSelector } from "react-redux";

export const SectionComponent = ({
  className,
  sectionTitle,
  description,
  fieldValue,
  rfqSubmission = false,
  ...props
}) => {
  return (
    <>
      {rfqSubmission ? (
        <div className="space-y-2 py-6 border-b border-fg-senary-300">
          <Heading className="text-4xl !text-royal-blue">
            {sectionTitle}
          </Heading>
          <p className="text-lg text-text-tertiary-600">{description}</p>
        </div>
      ) : (
        <div className={cn("min-h-14 border-b mt-6", className)} {...props}>
          <h1 className="text-xl font-bold">{sectionTitle}</h1>
          <p>{description}</p>
        </div>
      )}
    </>
  );
};
export const TextAreaQuestionnaire = ({
  className,
  description,
  question,
  typeQuestion = "short",
  required,
  fieldValue,
  ...props
}) => {
  const [, { error }] = useField(props);

  return (
    <div className={cn(className)} {...props}>
      <h1 className="text-lg font-semibold text-text-secondary-700">
        {question}
        <span
          className={cn(
            "font-normal text-text-tertiary-600",
            error && props?.touchedField && "font-semibold text-red-600"
          )}
        >
          {required && <span className="text-red-600">{`*`}</span>}
          {required ? " (Required)" : " (Optional)"}
        </span>
      </h1>
      {description && (
        <p className="text-text-tertiary-600 text-sm">{description}</p>
      )}
      <div>
        <FormatedInput
          characterLimit={typeQuestion === "short" ? 500 : 1500}
          rfq
          className="w-full my-4"
          required={required}
          placeholder="Enter a description..."
          subTitle={
            typeQuestion === "short"
              ? "Maximum of 500 characters."
              : "Maximum of 1500 characters."
          }
          // hideError
          {...props}
        />
      </div>
    </div>
  );
};
export const SingleChoiceQuestionnaire = ({
  className,
  description,
  question,
  options,
  required,
  fieldValue,
  ...props
}) => {
  const [, { error }] = useField(props);

  return (
    <div className={cn(className)} {...props}>
      <h1 className="text-lg font-semibold text-text-secondary-700">
        {question}
        <span
          className={cn(
            "font-normal text-text-tertiary-600",
            error && props?.touchedField && "font-semibold text-red-600"
          )}
        >
          {required && <span className="text-red-600">{`*`}</span>}
          {required ? " (Required)" : " (Optional)"}
        </span>
      </h1>
      {props?.rfqSubmission && (
        <p className="text-text-tertiary-600 text-sm">
          (Select one option only)
        </p>
      )}
      <div>
        <FieldForm
          type="radio"
          className="w-full my-4"
          options={fieldValue.map((item, i) => ({
            label: item,
            value: i.toString(),
          }))}
          name={props?.name || "questionnaire"}
          border
        />
      </div>
    </div>
  );
};
export const MultipleChoiceQuestionnaire = ({
  className,
  description,
  question,
  required,
  options,
  fieldValue,
  ...props
}) => {
  const [, { error }] = useField(props);

  return (
    <div className={cn(!props?.rfqSubmission && "space-y-2", className)}>
      <h1 className="text-lg font-semibold text-text-secondary-700">
        {question}
        <span
          className={cn(
            "font-normal text-text-tertiary-600",
            error && props?.touchedField && "font-semibold text-red-600"
          )}
        >
          {required && <span className="text-red-600">{`*`}</span>}
          {required ? " (Required)" : " (Optional)"}
        </span>
      </h1>
      {props?.rfqSubmission && (
        <p className="text-text-tertiary-600 text-sm">
          (Select one or more option)
        </p>
      )}
      <div>
        <MultipleCheckbox
          name={props?.name || "questionnaire"}
          checkboxes={fieldValue.map((item, i) => ({
            label: item,
            value: i.toString(),
          }))}
          className="w-full my-4"
          noAttribute
          border
          {...props}
        />
      </div>
    </div>
  );
};
export const DropdownQuestionnaire = ({
  className,
  question,
  options,
  required,
  fieldValue,
  ...props
}) => {
  const [, { error }] = useField(props);

  return (
    <div className={cn(className)}>
      <h1 className="text-lg font-semibold text-text-secondary-700">
        {question}
        <span
          className={cn(
            "font-normal text-text-tertiary-600",
            error && props?.touchedField && "font-semibold text-red-600"
          )}
        >
          {required && <span className="text-red-600">{`*`}</span>}
          {required ? " (Required)" : " (Optional)"}
        </span>
      </h1>
      {props?.rfqSubmission && (
        <p className="text-text-tertiary-600 text-sm">
          Select one option from dropdown
        </p>
      )}
      <div>
        <FieldForm
          type="select"
          options={fieldValue.map((item, i) => ({
            label: item,
            value: i.toString(),
          }))}
          className="w-full my-4"
          required={required}
          name={props.name}
        />
      </div>
    </div>
  );
};
export const DateSelectionQuestionnaire = ({
  className,
  question,
  required,
  fieldValue,
  ...props
}) => {
  const [, { error }] = useField(props);

  return (
    <div className={cn(className)}>
      <h1 className="text-lg font-semibold text-text-secondary-700">
        {question}
        <span
          className={cn(
            "font-normal text-text-tertiary-600",
            error && props?.touchedField && "font-semibold text-red-600"
          )}
        >
          {required && <span className="text-red-600">{`*`}</span>}
          {required ? " (Required)" : " (Optional)"}
        </span>
      </h1>
      {props?.rfqSubmission && (
        <p className="text-text-tertiary-600 text-sm">Single-date selection</p>
      )}
      <div>
        <DateInput
          icon={true}
          className="!font-regular !border-fg-disabled_subtle flex-3 my-4"
          required={required}
          applyFormated={"YYYY-MM-DD"}
          {...props}
        />
      </div>
    </div>
  );
};
export const AddressFinderQuestionnaire = ({
  className,
  question,
  required,
  fieldValue,
  ...props
}) => {
  const [, { error }] = useField(props);

  return (
    <div className={cn(className)}>
      <h1 className="text-lg font-semibold text-text-secondary-700">
        {question}
        <span
          className={cn(
            "font-normal text-text-tertiary-600",
            error && props?.touchedField && "font-semibold text-red-600"
          )}
        >
          {required && <span className="text-red-600">{`*`}</span>}
          {required ? " (Required)" : " (Optional)"}
        </span>
      </h1>
      {props?.rfqSubmission && (
        <p className="text-text-tertiary-600 text-sm">Address selection</p>
      )}
      <div>
        <Location
          errorBottom={"-3px"}
          rfq
          className="relative my-4"
          required={required}
          rfqIcon="address-finder-icon"
          applyValue
          isErrorValue={error && props?.touchedField}
          {...props}
        />
      </div>
    </div>
  );
};
const SmartFieldQuestionnaire = ({
  className,
  question,
  values,
  required,
  fieldValue,
  smartField,
  ...props
}) => {
  const isCoporateDocument = smartField === "Corporate Documents";
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteringList, setFilteringList] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [,meta,helper] = useField(props);
  const {value,error,touched} = meta
  const {setValue,setTouched} = helper
  const companyId = useSelector((state) => state?.search?.activeCompany?.id);

  const isSelected = (listValue) =>
    Array.isArray(value) && value.length > 0
      ? value?.includes(listValue)
      : false;
 
  const selectHandler = (listValue) => {
    setTouched(true)

    isSelected(listValue)
    ? setValue(value.filter((val) => val !== listValue))
    : setValue([...value,listValue]);
  };
  
  const [openDrawer, setOpenDrawer] = useState(false);

  const smartFieldInfo = () => {
    switch (smartField) {
      case "Project Experience":
        return {
          description: "Please choose the projects you would like to showcase.",
          placeholder: "Search project or client",
        };
      case "Key Personnel":
        return {
          description:
            "Please choose key personnel that might work on this project.",
          placeholder: "Search name, position or project",
        };
      case "Corporate Documents":
        return {
          description:
            "Please choose the corporate documents that you would like to share.",
          placeholder: "Search documents",
        };
      default:
        return null;
    }
  };

  const renderSmartField = (values) => {
    switch (smartField) {
      case "Project Experience":
        return (
          <ProjectCard
            companyId={companyId}
            values={values}
            rfqSubmission={true}
            selected={isSelected(values?.id)}
            selectHandler={selectHandler}
          />
        );
      case "Key Personnel":
        return (
          <KeyPersonnel
            companyId={companyId}
            values={values}
            rfqSubmission={true}
            selected={isSelected(values?.id)}
            selectHandler={selectHandler}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const params = {
      search: search,
      filters: selectedFilter,
    };
    switch (smartField) {
      case "Project Experience":
        setIsLoading(true);
        getListCompanyData("project-experience", params).then((res) => {
          setListData(res?.project_experience);
          setFilteringList(res?.filters);
          setIsLoading(false);
        });
        break;
      case "Key Personnel":
        setIsLoading(true);
        getListCompanyData("key-personnel", params).then((res) => {
          setListData(res?.key_personnels);
          setFilteringList(res?.filters);
          setIsLoading(false);
        });
        break;
      case "Corporate Documents":
        setIsLoading(true);
        getListCompanyData("corporate-document", params).then((res) => {
          setListData(res?.corporate_document);
          setIsLoading(false);
        });
        break;
      default:
        return null;
    }
    //eslint-disable-next-line
  }, [search, selectedFilter]);

  return (
    <div className={cn(`${(error && touched) && 'shadow-md ring-1 ring-red-500 ring-offset-2 p-2 rounded'} ${className}`)} {...props}>
      <Flex className="justify-between items-center">
        <div>
          <h1 className="text-lg font-semibold text-text-secondary-700">
            {smartField} 
            <span>
              {required && <span className="text-red-600">{`*`}</span>}
              {required ? " (Required)" : " (Optional)"}
            </span>
          </h1>
          <p className="text-text-tertiary-600 text-sm">
            {smartFieldInfo().description}
          </p>
          {(error && touched) && <span className="text-red-600">{error}</span>}
        </div>
        <Flex className="items-center gap-2">
          <SearchBar
            setState={setSearch}
            placeholder={smartFieldInfo().placeholder}
            className="min-w-[360px]"
          />
          {!isCoporateDocument && (
            <FilterButton
              onClick={() => setOpenDrawer(true)}
              filters={selectedFilter}
            />
          )}
        </Flex>
      </Flex>
      <div>
        <Flex className="overflow-x-auto py-6 pl-1">
          {isCoporateDocument ? (
            <CorporateDocument
              values={listData}
              selectHandler={selectHandler}
              selected={isSelected}
              isLoading={isLoading}
              companyId={companyId}
            />
          ) : isLoading ? (
            [...Array(3)].map((_, index) =>
              smartField === "Project Experience" ? (
                <ProjectCardSkeleton key={index} />
              ) : (
                <KeyPersonnelSkeleton key={index} />
              )
            )
          ) : (
            listData?.length > 0 &&
            Array.isArray(listData) &&
            listData.map((list) => {
              return renderSmartField(list);
            })
          )}
          {!isCoporateDocument && (
            <FilterDrawer
              isOpen={openDrawer}
              onClose={() => setOpenDrawer(false)}
              filterOptions={filteringList}
              filters={selectedFilter}
              setFilters={setSelectedFilter}
            />
          )}
        </Flex>
      </div>
    </div>
  );
};

export const FileInputQuestionnaire = ({
  className,
  question,
  required,
  fieldValue,
  singleFile,
  ...props
}) => {
  const [, { error }] = useField(props);

  return (
    <div className={cn(className)} {...props}>
      <h1 className="text-lg font-semibold text-text-secondary-700">
        {question}
        <span
          className={cn(
            "font-normal text-text-tertiary-600",
            error && props?.touchedField && "font-semibold text-red-600"
          )}
        >
          {required && <span className="text-red-600">{`*`}</span>}
          {required ? " (Required)" : " (Optional)"}
        </span>
      </h1>
      <div>
        <FileUpload
          type="file"
          className="w-full my-4"
          types={["pdf", "docx", "xlsx", "jpeg", "jpg"]}
          defaultFileName="Document"
          isDisabled={true}
          maxFileSize={10}
          singleFile={singleFile}
          {...props}
        />
      </div>
    </div>
  );
};
export const renderQuestion = (
  questionProps,
  index,
  hasDocuments,
  hasResources,
  hasPasProjects
) => {
  const { ...question } = questionProps;
  const { question: val, description, ...questionGuest } = questionProps;
  switch (question.type) {
    case "short":
      return <TextAreaQuestionnaire key={index} {...question} />;
    case "long":
      return <TextAreaQuestionnaire key={index} {...question} />;
    case "singleChoice":
      return <SingleChoiceQuestionnaire key={index} {...question} />;
    case "multipleChoice":
      return <MultipleChoiceQuestionnaire key={index} {...question} />;
    case "dropdown":
      return <DropdownQuestionnaire key={index} {...question} />;
    case "date":
      return <DateSelectionQuestionnaire key={index} {...question} />;
    case "address":
      return <AddressFinderQuestionnaire key={index} {...question} />;
    case "projectexp":
      return question?.isGuest || !hasPasProjects ? (
        <TextAreaQuestionnaire
          description="Maximum of 1500 characters."
          question="Project Experience"
          {...questionGuest}
        />
      ) : (
        <SmartFieldQuestionnaire
          key={index}
          smartField="Project Experience"
          {...question}
        />
      );
    case "keypersonel":
      return question?.isGuest || !hasResources ? (
        <TextAreaQuestionnaire
          description="Maximum of 1500 characters."
          question="Key Personel"
          {...questionGuest}
        />
      ) : (
        <SmartFieldQuestionnaire
          key={index}
          smartField="Key Personnel"
          {...question}
        />
      );
    case "corporatedoc":
      return question?.isGuest || !hasDocuments ? (
        <FileInputQuestionnaire
          question="Corporate Document"
          {...questionGuest}
          singleFile={!question?.isGuest}
        />
      ) : (
        <SmartFieldQuestionnaire
          key={index}
          smartField="Corporate Documents"
          {...question}
        />
      );
    case "file":
      return (
        <FileInputQuestionnaire key={index} {...question} singleFile={false} />
      );
    case "section":
      return <SectionComponent key={index} {...question} />;
    default:
      return null;
  }
};
