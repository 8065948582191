import { FileCard } from 'components/RFQ/FileCard'
import { FileUpload } from 'components/RFQ/FileUpload'
import LabelForm from 'components/RFQ/LabelForm/LabelForm'

import React from 'react'

export default function QuoteFile({quoteFilepath,savedQuoteFile,companyName}) {
    
    const fileData = {
        name: "Quote template.xlsx",
        path: quoteFilepath,
        description:`${companyName} has submitted a quote template. Please download it and re-upload below once it has been completed`
    }

  return (
    <>
      <LabelForm label="Download quotation template"/>
      <FileCard file={fileData} isPreview/>
     
      <FileUpload 
        type="file"
        label="Attach quote response"
        name="quote_file"
        defaultFileName="QuoteFile"
        className="w-full my-4"
        types={["xlsx", "xls","csv"]}
        required
        multiple={false}
        maxFileSize={10}/>

    {(savedQuoteFile && savedQuoteFile?.file_path) && 
      <>
       <FileCard isPreview
        file={{
          name:`${savedQuoteFile?.file_name}.${savedQuoteFile?.file_type}`,
          path:savedQuoteFile?.file_path}}/>
      </>
     }
    </>
    
  )
}
